var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"is-clipped right-border",attrs:{"rowspan":"2","colspan":"2"}},[_c('div',{staticClass:"field"},[_c('label',{class:[
            'label custom-font-weight',
            { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v(_vm._s(_vm.$t("columns.state")))]),_c('p',{staticClass:"test"})])]),_c('th',{staticClass:"is-clipped right-border",attrs:{"rowspan":"2","colspan":"2"}},[_c('div',{staticClass:"field"},[_c('label',{class:[
            'label custom-font-weight',
            { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v(_vm._s(_vm.$t("columns.measurementDate")))])])]),_c('th',{class:[
        'is-clipped right-border remove-border-bottom',
        { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ],attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.$t("columns.respiratoryFunction"))+" (cmH"),_c('sub',[_vm._v("2")]),_vm._v("0/(L/s)) ")]),_c('th',{class:[
        'is-clipped right-border remove-border-bottom',
        { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ],attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("columns.respiratoryPattern"))+" ")]),_c('th',{staticClass:"is-clipped right-border",attrs:{"rowspan":"2"}},[_c('div',{staticClass:"field"},[_c('label',{class:[
            'label custom-font-weight',
            { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v("SpO"),_c('sub',[_vm._v("2")]),_vm._v(" (%)")])])]),_c('th',{staticClass:"is-clipped right-border",attrs:{"rowspan":"2"}},[_c('div',{staticClass:"field"},[_c('label',{class:[
            'label custom-font-weight',
            { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v("T (°C)")])])]),_c('th',{staticClass:"is-clipped",attrs:{"colspan":"3","rowspan":"2"}},[_c('div',{staticClass:"field"},[_c('label',{class:[
            'label custom-font-weight',
            { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v(_vm._s(_vm.$t("columns.symptoms")))])])])]),_c('tr',{staticClass:"right-border"},[_c('td',{class:{ 'is-size-7': _vm.isCurrentRouteForPrintHistory }},[_vm._v("Rrs")]),_c('td',{class:{ 'is-size-7': _vm.isCurrentRouteForPrintHistory }},[_vm._v("Xrs")]),_c('td',{class:{ 'is-size-7': _vm.isCurrentRouteForPrintHistory }},[_vm._v("Δxrs")]),_c('td',{class:{ 'is-size-7': _vm.isCurrentRouteForPrintHistory }},[_vm._v("RR (bpm)")]),_c('td',{class:{ 'is-size-7': _vm.isCurrentRouteForPrintHistory }},[_vm._v("Vt (L)")])])])}
var staticRenderFns = []

export { render, staticRenderFns }