import { differenceInDays } from "date-fns";

export default {
  data() {
    return {
      complianceWarningThresholdInDays: 1,
      complianceErrorThresholdInDays: 2,
      pianoforteErrorThresholdInDays: 4,
      activationErrorThresholdInDays: 10,
      statusList: [
        {
          statusName: "to-manage",
          text: "states.toManage" /* NB: 'text' is a key in the i18next dictionary */,
          canBeSetByHuman: false,
        },
        {
          statusName: "action-plan-review",
          text: "states.actionPlanReview",
          canBeSetByHuman: false,
        },
        {
          statusName: "call-a-doctor",
          text: "states.toManage",
          canBeSetByHuman: false,
        },
        {
          statusName: "monitoring",
          text: "states.monitoring",
          canBeSetByHuman: false,
        },
        {
          statusName: "under-surveillance",
          text: "states.underSurveillance",
          canBeSetByHuman: false,
        },
        {
          statusName: "managed",
          text: "states.managed",
          canBeSetByHuman: true,
        },
        {
          statusName: "reported",
          text: "states.reported",
          canBeSetByHuman: true,
        },
        {
          statusName: "hospitalized",
          text: "states.hospitalized",
          canBeSetByHuman: true,
        },
        {
          statusName: "paused",
          text: "states.paused",
          canBeSetByHuman: true,
        },
        {
          statusName: "deactivated",
          text: "states.deactivated",
          canBeSetByHuman: true,
        },
        {
          statusName: "no-measurements",
          text: "states.noMeasurements",
          canBeSetByHuman: false,
        },
      ],
    };
  },
  methods: {
    colorByStatus(status) {
      return status; // in global.scss, we define td CSS classes with the same name of the status
    },
    diff_date(d) {
      return differenceInDays(new Date(), d);
    },
    above_date_warning_threshold(measurementTimestamp) {
      // live check for the COMPLIANCE monitoring protocol → under surveillance
      return this.diff_date(new Date(measurementTimestamp)) > this.complianceWarningThresholdInDays;
    },
    above_date_error_threshold(measurementTimestamp, monitoringProtocol) {
      if (monitoringProtocol?.toLowerCase() === "pianoforte") {
        // live check for the PIANOFORTE monitoring protocol → to manage
        return this.diff_date(new Date(measurementTimestamp)) > this.pianoforteErrorThresholdInDays;
      } else {
        // live check for the COMPLIANCE monitoring protocol → to manage
        return this.diff_date(new Date(measurementTimestamp)) > this.complianceErrorThresholdInDays;
      }
    },
    above_activation_error_threshold(measurementTimestamp) {
      // live check for the ACTIVATION of a patient who already has a device → to manage
      return this.diff_date(new Date(measurementTimestamp)) >= this.activationErrorThresholdInDays;
    },
    below_any_threshold(measurementTimestamp) {
      // this measurement must be considered RECEIVED
      return (
        measurementTimestamp &&
        !this.above_date_warning_threshold(measurementTimestamp) &&
        !this.above_date_error_threshold(measurementTimestamp) &&
        !this.above_activation_error_threshold(measurementTimestamp)
      );
    },
    wordByStatus(status) {
      let description = "N/A";
      this.statusList.forEach((element) => {
        if (element.statusName == status) {
          description = this.$t(element.text);
        }
      });
      return description;
    },
    getStatusListWithoutCurrent(thisStatus) {
      return this.statusListCanBeSetByHuman.filter((s) => s.statusName != thisStatus);
    },
    statusIsInactive(status) {
      return this.patientHasMeasurements(status) && !this.statusIsActive(status);
    },
    statusIsActive(status) {
      return [
        "call-a-doctor",
        "action-plan-review",
        "to-manage",
        "under-surveillance",
        "monitoring",
        "managed",
        "reported",
      ].includes(status);
    },
    statusHasBeenSetByHuman(status) {
      return ["managed", "reported"].includes(status);
    },
    patientHasMeasurements(status) {
      return status != "no-measurements";
    },
    statusChangedToday(changedAt) {
      const today = new Date().toDateString();
      const dayOfStatusChange = new Date(changedAt).toDateString();
      return dayOfStatusChange === today;
    },
    verifyNewPatients(m) {
      if (m.status === "no-measurements") {
        const activationDate = m.activationDate;
        if (activationDate && this.above_activation_error_threshold(activationDate)) {
          m.status = "to-manage";
        }
      }
      return m;
    },
    hideCallADoctor(m) {
      if (m.status === "call-a-doctor") m.status = "to-manage";
      return m;
    },
    verifyForPatientCompliance(m, patientMonitoringProtocol) {
      // if a measurement is not to manage, but it fails the compliance check, mark it as to manage
      const mTimestamp = m.lastMeasurementTimestamp || m.date;
      const logOverwriting = (m) =>
        console.log(
          `Overwriting status ${m.status} for ${mTimestamp} (${patientMonitoringProtocol}) due to compliance check failed`
        );
      if (this.statusHasBeenSetByHuman(m.status)) {
        // decide when overwriting managed status
        if (!this.statusChangedToday(m.statusChangedAt)) {
          if (this.above_date_error_threshold(mTimestamp, patientMonitoringProtocol)) {
            logOverwriting(m);
            m.status = "to-manage";
          } else if (this.above_date_warning_threshold(mTimestamp)) {
            logOverwriting(m);
            m.status = "under-surveillance";
          }
        }
      } else if (this.statusIsActive(m.status) && m.status != "to-manage" && m.status != "action-plan-review") {
        // decide when overwriting server-set status (for active patients only)
        if (this.above_date_error_threshold(mTimestamp, patientMonitoringProtocol)) {
          logOverwriting(m);
          m.status = "to-manage";
        } else if (m.status != "under-surveillance" && this.above_date_warning_threshold(mTimestamp)) {
          logOverwriting(m);
          m.status = "under-surveillance";
        }
      }
      return m;
    },
    protocolConsidersSaturation(monitoringProtocol) {
      return monitoringProtocol === "COPD+S";
    },
    protocolConsidersTemperature(monitoringProtocol) {
      return monitoringProtocol === "COPD+S";
    },
    protocolSupportsSymptoms(monitoringProtocol) {
      return monitoringProtocol === "COPD+S";
    },
  },
  computed: {
    statusListCanBeSetByHuman() {
      return this.statusList.filter((s) => s.canBeSetByHuman);
    },
  },
};
