<template>
  <div
    :class="[
      'columns',
      isLastMeasure || isCurrentRouteForPrintHistory ? '' : 'mb-5',
    ]"
  >
    <errorModal :errorMessage="errorMessage" />
    <div
      class="column"
      v-show="!isCurrentRouteForPrintHistory || (notes && notes.length > 0)"
    >
      <div v-if="notes && notes.length > 0">
        <div v-for="(note, idx) in notes" :key="idx" class="columns">
          <div
            :class="[
              'column pl-4 has-text-left is-normal ',
              getNoteBorderClass(idx),
              { 'is-three-quarters': showNoteInputField },
              { 'is-size-6	 pt-0 pb-1': isCurrentRouteForPrintHistory },
            ]"
          >
            <p
              :class="{ 'is-size-7 pt-0 pb-1': isCurrentRouteForPrintHistory }"
            >
              {{ note.account }} ({{
                note.createdAt | formatTimestampAsDateTime
              }}): {{ note.text }}
            </p>
          </div>

          <div
            v-if="idx == notes.length - 1"
            class="column has-text-right is-one-fifth"
          >
            <div class="has-text-centered pl-4 pr-4">
              <button
                class="button is-success is-small fixed-width"
                @click="showNoteInputField = !showNoteInputField"
                v-show="!showNoteInputField && !isCurrentRouteForPrintHistory"
              >
                {{ $t("buttons.addNote") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!isCurrentRouteForPrintHistory">
        <div class="columns">
          <div
            :class="[
              'column p-0 pl-4 has-text-left is-normal ',
              showNoteInputField ? 'is-three-quarters top-note-border' : '',
            ]"
          ></div>
          <div class="column has-text-right is-one-fifth">
            <div class="has-text-centered pl-4 pr-4">
              <button
                class="button is-success is-small fixed-width"
                @click="showNoteInputField = !showNoteInputField"
                v-show="!showNoteInputField"
              >
                {{ $t("buttons.addNote") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="columns" v-if="showNoteInputField">
        <div class="column is-three-quarters input-note-border">
          <div>
            <input
              class="input"
              type="text"
              v-model.trim="inputNote"
              placeholder="Text input"
            />
          </div>
        </div>
      </div>
      <div class="columns" v-if="showNoteInputField">
        <div class="column is-three-quarters last-note-border">
          <div class="columns">
            <div class="column has-text-left">
              <div>
                <button
                  :class="[
                    awaitingResponse ? 'is-loading' : '',
                    'button is-small',
                    inputNote ? ' is-success' : 'is-light',
                  ]"
                  :disabled="!inputNote"
                  @click="sendNewNoteToServer"
                >
                  <span>{{ $t("buttons.save") }}</span>
                </button>
              </div>
            </div>
            <div class="column has-text-right">
              <div>
                <button
                  class="button is-success is-small"
                  @click="showNoteInputField = !showNoteInputField"
                >
                  <span>{{ $t("buttons.cancel") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorModal from "../Error/errorModal";
import api from "../API/api";

export default {
  emits: ["handleNewNote"],
  props: [
    "notes",
    "isLastMeasure",
    "measurementId",
    "isCurrentRouteForPrintHistory",
  ],
  components: { errorModal },
  data() {
    return {
      showNoteInputField: false,
      inputNote: "",
      awaitingResponse: false,
      errorMessage: "",
    };
  },
  methods: {
    async sendNewNoteToServer() {
      if (this.inputNote) {
        this.awaitingResponse = true;
        try {
          let res = await api.createNote(this.measurementId, {
            measurementID: this.measurementId,
            text: this.inputNote,
          });

          if (!res.newNote)
            throw new Error("Note created but no details have been sent back");

          // console.log(`New note sent. Response: ${JSON.stringify(res)}`);

          this.$emit("handleNewNote", {
            note: res.newNote,
            measurementId: this.measurementId,
          });
          this.inputNote = "";
          this.showNoteInputField = false;
        } catch (E) {
          this.error.message =
            new Date().toISOString() + "Error uploading new note: " + E;
        } finally {
          this.awaitingResponse = false;
        }
      }
    },
    getNoteBorderClass(idx) {
      if (!this.showNoteInputField) return "";
      else if (idx == 0) return "top-note-border";
      else return "center-note-border";
    },
  },
};
</script>

<style lang="scss" scoped>
.note-border {
  border: 1px solid;
}
.top-note-border {
  border-radius: 5px 5px 0 0;
  border: 1px solid;
  border-bottom: 1px solid #dbdbdb;
}
.center-note-border {
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid #dbdbdb;
}
.input-note-border {
  border-left: 1px solid;
  border-right: 1px solid;
}
.last-note-border {
  border-radius: 0px 0px 5px 5px;
  border: 1px solid;
  border-top: 0px;
}
</style>