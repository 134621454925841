<template>
  <div class="has-background-grey-light p-5">
    <Loader v-if="loading" />
    <errorModal
      :errorMessage="error.message"
      :logoutOnClose="error.forceLogout"
    />
    <div
      v-if="this.noContent && !this.loading"
      class="m-6 has-text-weight-bold"
    >
      Non ci sono medici da visualizzare
    </div>
  </div>
</template>

<script>
import api from "../API/api";
import errorMixin from "../Error/errorMixin";
import errorModal from "../Error/errorModal";
import Loader from "@/shared/Loader.vue";
export default {
  data() {
    return {
      loading: false,
      physicians: [],
    };
  },
  components: { errorModal, Loader },
  mixins: [errorMixin],
  computed: {
    noContent() {
      return this.physicians.length <= 0;
    },
  },
  async created() {
    try {
      this.loading = true;
      this.physicians = await api.getPhysicians();
    } catch (e) {
      this.error.message = `[${new Date().toISOString()}] ` + e;
      this.error.forceLogout = true;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>