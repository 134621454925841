<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="columns" v-if="!isLoading && isPatientValid">
      <div class="column has-text-left">
        <p class="is-size-5 has-text-weight-bold">
          {{ patientFullName }}
        </p>
        <p class="is-size-7" v-if="!patientIsAnonymous">
          {{ patient.personalData.sex }},
          {{ patient.personalData.birthdate | formatTimestampAsDate }}
          ({{ this.getPatientAge() }}{{ ` ${$t("yearsOld")}` }})
        </p>
        <p class="is-size-7">
          {{ patient.personalData.PatientCode }}
        </p>
        <p class="is-size-7">{{ patientAddress }}</p>
        <p class="is-size-7">{{ patient.personalData.phone }}</p>
      </div>
      <div class="column has-text-left">
        <p class="is-size-5 has-text-weight-bold">Caregiver</p>
        <div
          v-if="
            (patientIsAnonymous && !patient.caregiverData) ||
            (patient.caregiverData && patient.caregiverData.disabled)
          "
        >
          <p class="is-size-7">{{ $t("warnings.noCaregiverDataInsert") }}</p>
        </div>
        <div v-else-if="patient && patient.caregiverData">
          <p class="is-size-7">
            {{ patient.caregiverData.name ? patient.caregiverData.name : "" }}
            {{
              patient.caregiverData.surname ? patient.caregiverData.surname : ""
            }}
          </p>
          <p class="is-size-7">
            {{ patient.caregiverData.phone ? patient.caregiverData.phone : "" }}
          </p>
          <p class="is-size-7">
            {{
              patient.caregiverData.relation
                ? patient.caregiverData.relation
                : ""
            }}
          </p>
          <p class="is-size-7">
            {{ patient.caregiverData.email }}
          </p>
        </div>
        <div v-else>
          <p class="is-size-7">{{ $t("warnings.noCaregiverData") }}</p>
        </div>
      </div>
      <div class="column has-text-left">
        <p class="is-size-5 has-text-weight-bold">
          {{ $t("fieldsDescription.contactPhysician") }}
        </p>
        <div v-if="!patientIsAnonymous && patient.doctorData">
          <p class="is-size-7">
            {{ patient.doctorData.name }}
            {{ patient.doctorData.surname }}
          </p>
          <p class="is-size-7">
            {{ patient.doctorData.email }}
          </p>
          <p class="is-size-7">
            {{ patient.doctorData.phone }}
          </p>
        </div>
        <div v-else>
          <p class="is-size-7">{{ $t("warnings.noPhysicianData") }}</p>
        </div>
      </div>
      <div
        class="column"
        v-if="!patientIsAnonymous && !isCurrentRouteForPrintHistory"
      >
        <PatientScheduledCall
          :scheduledCall="patient.scheduledCall"
          :patientID="patient.PatientID"
          :patientStatus="
            measurements && measurements[0] && measurements[0].status
          "
          @handleScheduledCallChange="
            $emit('handleScheduledCallChange', $event)
          "
        />
      </div>
      <div v-else-if="patientIsAnonymous" class="column"></div>
      <div class="column" v-if="!isCurrentRouteForPrintHistory">
        <button
          class="button is-success is-small m-1 fixed-width"
          @click="
            $router.push({
              path: '/patient-form',
              query: { patient: patient.PatientID, currentStep: 5 },
            })
          "
        >
          <i class="fas fa-check m-2"></i>
          <span class="pr-4">{{ $t("buttons.seeInfo") }}</span>
          <span class="icon is-small"> </span>
        </button>
        <button
          v-if="measurements && measurements.length > 0"
          class="button is-success is-small m-1 fixed-width"
          @click="
            $router.push({
              path: `/measurements/${patient.PatientID}/print`,
              query: {
                patient: patient,
                measurementsPointsToPlot: measurementsPoints,
                patientID: patient.PatientID,
                measurements: measurements,
                customTimeSeriesRange: customTimeSeriesRange,
                customImpedanceRange: customImpedanceRange,
              },
            })
          "
        >
          <i class="fas fa-file-download m-2"></i>
          <span>{{ $t("buttons.exportReport") }}</span>
          <span class="icon is-small"> </span>
        </button>
        <button v-if="false" class="button is-success is-small m-1 fixed-width">
          <i class="fas fa-download m-2"></i>
          <span>{{ $t("buttons.downloadData") }}</span>
          <span class="icon is-small"> </span>
        </button>
      </div>
      <div v-else class="column has-text-left">
        <p class="is-size-5 has-text-weight-bold">
          {{ $t("reportInformation") }}
        </p>
        <p class="is-size-7">
          {{ $t("printedOn") }} {{ this.getCurrentDate() }}
        </p>
        <p class="is-size-7">{{ $t("from") }}: {{ getCompleteName }}</p>
      </div>
    </div>
    <div
      class="columns"
      v-if="isCurrentRouteForPrintHistory && patient.clinicalData"
    >
      <div class="column has-text-left">
        <p class="is-size-5 has-text-weight-bold">{{ $t("clinicalData") }}</p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.height") }}: </span
          >{{ patient.clinicalData.visit.height }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.weight") }}:
          </span>
          {{ patient.clinicalData.visit.weight }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.bmi") }}:
          </span>
          {{ patient.clinicalData.visit.bmi }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.smoker") }}:
          </span>
          {{
            patient.clinicalData.visit.smoker ? this.$t("yes") : this.$t("no")
          }}
        </p>
        <p class="is-size-7 p-1" v-if="patient.clinicalData.visit.smoker">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.packagesPerYear") }}:
          </span>
          {{ patient.clinicalData.visit.packagesPerYear }}
        </p>
      </div>
      <div class="column has-text-left">
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.lastSpirometryTestDate") }}:</span
          >
          {{ spirometricTestDate | formatTimestampAsDate }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.fev1") }}:</span
          >
          {{ patient.clinicalData.spirometricTest.fev1 }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.fev1PercPred") }}: </span
          >{{ patient.clinicalData.spirometricTest.fev1Perc }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.fvc") }}:</span
          >
          {{ patient.clinicalData.spirometricTest.fvc }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.fvcPercPred") }}:</span
          >
          {{ patient.clinicalData.spirometricTest.fvcPerc }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.fev1Fvc") }}:</span
          >
          {{ patient.clinicalData.spirometricTest.fev1Fvc }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.fev1fvcPredPerc") }}:</span
          >
          {{ patient.clinicalData.spirometricTest.fev1FvcPercPred }}
        </p>
      </div>
      <div class="column has-text-left">
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold"
            >{{ $t("fieldsDescription.hospitalizationsInTheLastYear") }}:</span
          >
          {{ patient.clinicalData.hospitalizations.totalLastYear }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold">
            {{ $t("fieldsDescription.hospitalizationsForCOPDexacerbation") }}:
          </span>
          {{ patient.clinicalData.hospitalizations.forBPCO }}
        </p>
        <p class="is-size-7 p-1">
          <span class="has-text-weight-semibold">
            {{ $t("fieldsDescription.dateOfDischargeCOPD") }}:
          </span>
          {{ dateOfDischarge | formatTimestampAsDate }}
        </p>
      </div>
      <div class="column"></div>
    </div>
    <div class="columns" v-if="isCurrentRouteForPrintHistory">
      <div
        class="column has-text-left"
        v-if="this.dataHaveComorbidities(patient)"
      >
        <p class="is-size-7 has-text-weight-semibold p-1">
          {{ $t("fieldsDescription.comorbidity") }}:
        </p>
        <div
          v-for="comorbidity in getAllComorbidities(patient)"
          :key="comorbidity"
          class="is-size-7 p-2"
          style="display: inline-block"
        >
          • {{ $t(getComordityDictionaryString(comorbidity)) }}
        </div>
      </div>
    </div>
    <div v-if="!isLoading && !isPatientValid">
      <p class="is-size-7">{{ $t("warnings.noPatient") }}</p>
    </div>
    <div
      v-if="
        (!measurements || measurements.length == 0) && patient.monitoringData
      "
    >
      <p>
        {{ $t("warnings.patientActivation") }}
        {{ patient.monitoringData.activatedAt | formatTimestampAsDate }} ({{
          patient.monitoringData.activatedAt | formatTimestampAsDistance
        }}):
        {{ patient.monitoringData.device }}
      </p>
    </div>
  </div>
</template>

<script>
import { format, formatDistanceToNowStrict } from "date-fns";
import { it } from "date-fns/esm/locale";
import Loader from "@/shared/Loader.vue";
import PatientScheduledCall from "./PatientScheduledCall.vue";
import { mapGetters } from "vuex";
import patientShowDataMixin from "@/shared/patientShowDataMixin";
export default {
  props: [
    "patient",
    "measurementsPoints",
    "measurements",
    "isCurrentRouteForPrintHistory",
    "customTimeSeriesRange",
    "customImpedanceRange",
  ],
  mixins: [patientShowDataMixin],
  emits: ["handleScheduledCallChange"],
  components: {
    Loader,
    PatientScheduledCall,
  },
  methods: {
    getPatientAge() {
      if (this.patient.personalData && this.patient.personalData.birthdate) {
        const formattedPatientBirthdate = format(
          new Date(this.patient.personalData.birthdate),
          "yyyy-MM-dd hh:mm",
          { locale: it }
        );
        const localeDate = formatDistanceToNowStrict(
          new Date(formattedPatientBirthdate),
          {
            addSuffix: false,
            locale: it,
          }
        );
        const ageInNumber = localeDate.substring(0, localeDate.indexOf(" "));
        return ageInNumber;
      }
    },
    getCurrentDate() {
      return new Intl.DateTimeFormat("it-IT").format(new Date());
    },
  },
  computed: {
    ...mapGetters(["getCompleteName"]),
    patientIsAnonymous() {
      return !(
        this.patient.personalData && this.patient.personalData.birthdate
      );
    },
    spirometricTestDate() {
      return this.patient?.clinicalData?.spirometricTest?.date;
    },
    dateOfDischarge() {
      return this.patient.clinicalData.hospitalizations.dateOfDischarge;
    },
    patientFullName() {
      if (this.patientIsAnonymous) {
        return `${
          this.patient.monitoringData && this.patient.monitoringData.device
        }`;
      } else {
        return `${this.patient.personalData.name} ${this.patient.personalData.surname}`;
      }
    },
    isLoading() {
      return !this.patient;
    },
    isPatientValid() {
      return this.patient?.personalData?.PatientCode;
    },
    patientAddress() {
      return (
        this.patient?.personalData?.address ||
        this.patient?.personalData?.domicile ||
        "N/A"
      );
    },
  },
};
</script>