import api from "../API/api.js";

export default {
  data() {
    return {
      awaitingResponse: false,
    };
  },
  methods: {
    async modifyPatient(patientId, patientData) {
      try {
        if (!patientId) throw new Error("Patient is undefined");
        this.awaitingResponse = true;
        await api.modifyPatient(patientId, patientData);
        this.$emit("updateClickHandler", patientData);
      } catch (E) {
        this.$emit("errorOnSaveHandler", this.parseError(E));
      } finally {
        this.awaitingResponse = false;
      }
    },
    parseError(E) {
      return typeof E == "string" ? E : E.errorMessage || E.toString() || JSON.stringify(E);
    },
  },
};
