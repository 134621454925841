<template>
  <div>
    <form
      @submit.prevent="modifyPatient(patientId, caregiverDataToSend)"
      :class="this.errors ? 'errors' : false"
    >
      <div class="columns">
        <div class="column">
          <div class="field">
            <input type="checkbox" v-model="caregiverDataCopy.disabled" />
            <label for="switchRoundedDefault">{{
              $t("buttons.disableCaregiver")
            }}</label>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.caregiverName")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="text"
                v-model.trim="caregiverDataCopy.name"
                :placeholder="$t('fieldsDescription.name')"
                :disabled="caregiverDataCopy.disabled"
                :required="caregiverDataCopy.disabled"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.caregiverContactPhone")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="text"
                v-model.trim="caregiverDataCopy.phone"
                :placeholder="$t('fieldsDescription.number')"
                :disabled="caregiverDataCopy.disabled"
                :required="caregiverDataCopy.disabled"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.relationshipWithPatient")
            }}</label>
            <div class="select is-fullwidth">
              <select
                v-model.trim="caregiverDataCopy.relation"
                @invalid="invalidateForm"
                :disabled="caregiverDataCopy.disabled"
                :required="caregiverDataCopy.disabled"
              >
                <option value="spouseCohabitant">
                  {{ $t("fieldsDescription.spouseCohabitant") }}
                </option>
                <option value="sonDaughter">
                  {{ $t("fieldsDescription.sonDaughter") }}
                </option>
                <option value="other">
                  {{ $t("fieldsDescription.other") }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.caregiverSurname")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="text"
                v-model.trim="caregiverDataCopy.surname"
                :placeholder="$t('fieldsDescription.surname')"
                :disabled="caregiverDataCopy.disabled"
                :required="caregiverDataCopy.disabled"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.caregiverEmail")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="email"
                v-model.trim="caregiverDataCopy.email"
                :placeholder="$t('fieldsDescription.email')"
                :disabled="caregiverDataCopy.disabled"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <PatientSaveButton
        :awaitingResponse="awaitingResponse"
        :haveAllFieldsBeenFilledIn="
          this.haveAllFieldsBeenFilledIn() || caregiverDataCopy.disabled
        "
        :isDataEqualToTheSavedOnSubmit="this.isDataEqualToTheSavedOnSubmit()"
      />
    </form>
  </div>
</template>
<script>
import savePatientMixin from "./savePatientMixin.js";
import cloneDeep from "lodash/cloneDeep";
import PatientSaveButton from "./PatientSaveButton";

const emptycaregiverData = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  relation: "",
};

export default {
  props: ["patientToModify"],
  mixins: [savePatientMixin],
  components: {
    PatientSaveButton,
  },
  data() {
    return {
      patientId: this.patientToModify.PatientID,
      awaitingResponse: false,
      isDataSaved: false,
      errors: false,
      caregiverDataCopy: this.patientToModify.caregiverData
        ? cloneDeep(this.patientToModify.caregiverData)
        : emptycaregiverData,
    };
  },
  computed: {
    caregiverDataToSend() {
      return { caregiverData: this.caregiverDataCopy };
    },
  },
  methods: {
    invalidateForm() {
      this.errors = true;
    },

    isDataEqualToTheSavedOnSubmit() {
      return (
        JSON.stringify(this.caregiverDataCopy) ===
        JSON.stringify(this.patientToModify.caregiverData)
      );
    },
    haveAllFieldsBeenFilledIn() {
      return (
        this.caregiverDataCopy.name &&
        this.caregiverDataCopy.surname &&
        this.caregiverDataCopy.phone &&
        this.caregiverDataCopy.relation
      );
    },
    setAllFieldsAsEmpty() {
      this.caregiverDataCopy.name = "";
      this.caregiverDataCopy.surname = "";
      this.caregiverDataCopy.email = "";
      this.caregiverDataCopy.phone = "";
      this.caregiverDataCopy.relation = "";
    },
  },
  watch: {
    caregiverDataCopy() {
      if (this.caregiverDataCopy.disabled) this.setAllFieldsAsEmpty();
    },
  },
};
</script>