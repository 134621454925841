<template>
  <div class="columns">
    <div class="column">
      <div class="has-text-centered">
        <button
          :class="[
            'button',
            isPatientStepSaved(0) ? 'is-success' : 'is-to-complete',
          ]"
          @click="$emit('handleModifyDataClick', 0)"
        >
          <i class="fas fa fa-pencil-alt m-2"></i>
          <span class="pr-4"> {{ $t("stepsName.personalData") }}</span>
        </button>
      </div>
      <br />
      <br />
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.patientName")
            }}</label>
            <div class="control">
              <div>
                {{
                  this.data.personalData && this.data.personalData.name
                    ? this.data.personalData.name
                    : "-"
                }}
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.birthplace")
            }}</label>
            <div class="control">
              <div>
                {{
                  this.data.personalData && this.data.personalData.birthplace
                    ? this.data.personalData.birthplace
                    : "-"
                }}
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.residentialAddress")
            }}</label>
            <div class="control">
              <div>
                {{
                  this.data.personalData && this.data.personalData.address
                    ? this.data.personalData.address
                    : "-"
                }}
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.telephoneContact")
            }}</label>
            <div class="control">
              <div>
                {{
                  this.data.personalData && this.data.personalData.phone
                    ? this.data.personalData.phone
                    : "-"
                }}
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.patientSex")
            }}</label>
            <div class="control">
              <div>
                {{
                  this.data.personalData && this.data.personalData.sex
                    ? this.data.personalData.sex
                    : "-"
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.patientSurname")
            }}</label>
            <div class="control">
              <div>
                {{
                  this.data.personalData && this.data.personalData.surname
                    ? this.data.personalData.surname
                    : "-"
                }}
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.birthdate")
            }}</label>
            <div class="control">
              <div v-if="patientBirthdate">
                {{ patientBirthdate | formatTimestampAsDate }}
              </div>
              <div v-else>-</div>
            </div>
          </div>
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.homeAddress")
            }}</label>
            <div class="control">
              <div>
                {{
                  this.data.personalData && this.data.personalData.domicile
                    ? this.data.personalData.domicile
                    : "-"
                }}
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.patientCode")
            }}</label>
            <div class="control">
              <div>
                {{
                  this.data.personalData && this.data.personalData.PatientCode
                    ? this.data.personalData.PatientCode
                    : "-"
                }}
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label has-text-center">{{
              $t("fieldsDescription.email")
            }}</label>
            <div class="control">
              <div>
                {{
                  this.data.personalData && this.data.personalData.email
                    ? this.data.personalData.email
                    : "-"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <div class="has-text-centered">
            <button
              :class="[
                'button',
                isPatientStepSaved(1) ? 'is-success' : 'is-to-complete',
              ]"
              @click="$emit('handleModifyDataClick', 1)"
            >
              <i class="fas fa fa-pencil-alt m-2"></i>
              <span class="pr-4"> {{ $t("stepsName.caregiverData") }}</span>
            </button>
          </div>
          <br />
          <br />
          <div
            v-if="this.data.caregiverData && this.data.caregiverData.disabled"
          >
            <p>{{ $t("warnings.noCaregiverDataDisabled") }}</p>
          </div>
          <div class="columns" v-else>
            <div class="column">
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.caregiverName")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.caregiverData && this.data.caregiverData.name
                        ? this.data.caregiverData.name
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.caregiverContactPhone")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.caregiverData && this.data.caregiverData.phone
                        ? this.data.caregiverData.phone
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.relationshipWithPatient")
                }}</label>
                <div class="control">
                  <div v-if="isCaregiverDefined()">
                    {{
                      $t(
                        "fieldsDescription." + this.data.caregiverData.relation
                      )
                    }}
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.caregiverSurname")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.caregiverData && this.data.caregiverData.surname
                        ? this.data.caregiverData.surname
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.caregiverEmail")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.caregiverData && this.data.caregiverData.email
                        ? this.data.caregiverData.email
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="has-text-centered">
            <button
              :class="[
                'button',
                isPatientStepSaved(2) ? 'is-success' : 'is-to-complete',
              ]"
              @click="$emit('handleModifyDataClick', 2)"
            >
              <i class="fas fa fa-pencil-alt m-2"></i>
              <span class="pr-4"> {{ $t("stepsName.physicianData") }}</span>
            </button>
          </div>
          <br />
          <br />
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.physicianName")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.doctorData && this.data.doctorData.name
                        ? this.data.doctorData.name
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.physicianPhone")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.doctorData && this.data.doctorData.phone
                        ? this.data.doctorData.phone
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.physicianSurname")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.doctorData && this.data.doctorData.surname
                        ? this.data.doctorData.surname
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.physicianEmail")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.doctorData && this.data.doctorData.email
                        ? this.data.doctorData.email
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <button
            :class="[
              'button',
              isPatientStepSaved(3) ? 'is-success' : 'is-to-complete',
            ]"
            @click="$emit('handleModifyDataClick', 3)"
          >
            <i class="fas fa fa-pencil-alt m-2"></i>
            <span class="pr-4"> {{ $t("stepsName.clinicalData") }}</span>
          </button>
          <br />
          <br />
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.height")
                }}</label>
                <div class="control">
                  <div class="control">
                    <div>
                      {{
                        this.data.clinicalData &&
                        this.data.clinicalData.visit &&
                        this.data.clinicalData.visit.height
                          ? this.data.clinicalData.visit.height
                          : "-"
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.bmi")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.visit &&
                      this.data.clinicalData.visit.bmi
                        ? this.data.clinicalData.visit.bmi
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.smoker")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData && this.data.clinicalData.visit
                        ? this.data.clinicalData.visit.smoker
                          ? $t("yes")
                          : $t("no")
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.weight")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.visit &&
                      this.data.clinicalData.visit.weight
                        ? this.data.clinicalData.visit.weight
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field is-invisible">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.weight")
                }}</label>
                <div class="control">-</div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.packagesPerYear")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.visit &&
                      this.data.clinicalData.visit.packagesPerYear
                        ? this.data.clinicalData.visit.packagesPerYear
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.lastSpirometryTestDate")
                }}</label>
                <div class="control">
                  <div v-if="spirometricTestDate">
                    {{ spirometricTestDate | formatTimestampAsDate }}
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.fev1")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.spirometricTest &&
                      this.data.clinicalData.spirometricTest.fev1
                        ? this.data.clinicalData.spirometricTest.fev1
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.fvc")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.spirometricTest &&
                      this.data.clinicalData.spirometricTest.fvc
                        ? this.data.clinicalData.spirometricTest.fvc
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.fev1Fvc")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.spirometricTest &&
                      this.data.clinicalData.spirometricTest.fev1Fvc
                        ? this.data.clinicalData.spirometricTest.fev1Fvc
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.hospitalizationsInTheLastYear")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.hospitalizations &&
                      this.data.clinicalData.hospitalizations.totalLastYear
                        ? this.data.clinicalData.hospitalizations.totalLastYear
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.dateOfDischargeCOPD")
                }}</label>
                <div class="control">
                  <div v-if="hospitalizationsDate">
                    {{ hospitalizationsDate | formatTimestampAsDate }}
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.fev1PercPred")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.spirometricTest &&
                      this.data.clinicalData.spirometricTest.fev1Perc
                        ? this.data.clinicalData.spirometricTest.fev1Perc
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.fvcPercPred")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.spirometricTest &&
                      this.data.clinicalData.spirometricTest.fvcPerc
                        ? this.data.clinicalData.spirometricTest.fvcPerc
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.fev1fvcPredPerc")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.spirometricTest &&
                      this.data.clinicalData.spirometricTest.fev1FvcPercPred
                        ? this.data.clinicalData.spirometricTest.fev1FvcPercPred
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label has-text-center">{{
                  $t("fieldsDescription.hospitalizationsForCOPDexacerbation")
                }}</label>
                <div class="control">
                  <div>
                    {{
                      this.data.clinicalData &&
                      this.data.clinicalData.hospitalizations &&
                      this.data.clinicalData.hospitalizations.forBPCO
                        ? this.data.clinicalData.hospitalizations.forBPCO
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label has-text-center-end">{{
                $t("fieldsDescription.comorbidity")
              }}</label>
              <div
                class="column has-text-left"
                v-if="
                  this.dataHaveComorbidities(data) ||
                  this.dataHaveCustomComorbidities(data)
                "
              >
                <div
                  v-for="comorbidity in getAllComorbidities(data)"
                  :key="comorbidity"
                  class="p-2"
                  style="display: inline-block"
                >
                  • {{ $t(getComordityDictionaryString(comorbidity)) }}
                </div>
              </div>
              <div v-else>-</div>
            </div>
          </div>
          <hr />
          <div class="columns">
            <div class="column">
              <button
                :class="[
                  'button',
                  isPatientStepSaved(4) ? 'is-success' : 'is-to-complete',
                ]"
                @click="$emit('handleModifyDataClick', 4)"
              >
                <i class="fas fa fa-pencil-alt m-2"></i>
                <span class="pr-4"> {{ $t("stepsName.monitoringData") }}</span>
              </button>
              <br />
              <br />
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label has-text-center">{{
                      $t("fieldsDescription.pairedDevice")
                    }}</label>
                    <div>
                      {{
                        this.data.monitoringData &&
                        this.data.monitoringData.device
                          ? this.data.monitoringData.device
                          : "-"
                      }}
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label has-text-center">{{
                      $t("fieldsDescription.monitoringCenter")
                    }}</label>
                    <div>
                      {{
                        this.data.monitoringData &&
                        this.data.monitoringData.patientGroup
                          ? this.data.monitoringData.patientGroup
                          : "-"
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label has-text-center">{{
                      $t("fieldsDescription.monitoringProtocol")
                    }}</label>
                    <div>
                      {{
                        this.data.monitoringData &&
                        this.data.monitoringData.monitoringProtocol
                          ? this.data.monitoringData.monitoringProtocol
                          : "-"
                      }}
                    </div>
                  </div>
                </div>
                <div class="column"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import patientShowDataMixin from "@/shared/patientShowDataMixin";
export default {
  emits: ["handleModifyDataClick"],
  mixins: [patientShowDataMixin],
  props: {
    data: Object,
  },
  methods: {
    //TODO: cambiare il controllo dei campi nel caso in
    //      non siano più tutti impostato come required
    isPatientStepSaved(idx) {
      switch (idx) {
        case 0:
          return this.arePersonalDataFilled();
        case 1:
          return this.areCaregiverDataFilled();
        case 2:
          return this.areDoctorDataFilled();
        case 3:
          return this.areClinicalDataFilled();
        case 4:
          return this.areMonitoringDataFilled();
        case 5:
          return (
            this.arePersonalDataFilled() &&
            this.areCaregiverDataFilled() &&
            this.areDoctorDataFilled() &&
            this.areClinicalDataFilled() &&
            this.areMonitoringDataFilled()
          );
      }
    },
    arePersonalDataFilled() {
      return this.data.personalData && this.data.personalData.name;
    },
    areCaregiverDataFilled() {
      return (
        this.data.caregiverData &&
        (this.data.caregiverData.name || this.data.caregiverData.disabled)
      );
    },
    areDoctorDataFilled() {
      return this.data.doctorData && this.data.doctorData.name;
    },
    areClinicalDataFilled() {
      return (
        this.data.clinicalData &&
        this.data.clinicalData.visit &&
        this.data.clinicalData.visit.height > 0
      );
    },
    areMonitoringDataFilled() {
      return this.data.monitoringData && this.data.monitoringData.device;
    },
    isCaregiverDefined() {
      return this.data?.caregiverData?.relation;
    },
  },
  computed: {
    patientBirthdate() {
      return this.data.personalData && this.data.personalData.birthdate;
    },
    spirometricTestDate() {
      return (
        this.data.clinicalData && this.data.clinicalData.spirometricTest.date
      );
    },
    hospitalizationsDate() {
      return (
        this.data.clinicalData &&
        this.data.clinicalData.hospitalizations.dateOfDischarge
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.button.is-to-complete {
  background-color: $restech-orange;
}
</style>