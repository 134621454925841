<template>
  <footer class="footer">
    <a :href="restechUrl" target="_blank">restech.it</a>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      restechUrl: "https://www.restech.it",
      restechLogo: "@/assets/logorestech.png"
    };
  }
};
</script>
<style lang="scss" scoped>

</style>