<template>
  <div>
    <div class="modal" :class="{ 'is-active': errorMsg.length > 0 && !closed }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-danger">{{ $t("error") }}</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">{{ errorMsg }}</section>
        <footer class="modal-card-foot">
          <button @click="close" class="button is-danger">
            {{ $t("buttons.close") }}
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
    logoutOnClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closed: false,
      errorMsg: "",
    };
  },
  mounted() {
    this.errorMsg = this.errorMessage.repeat(1);
  },
  methods: {
    close() {
      this.closed = true;
      this.errorMsg = "";
      if (this.logoutOnClose) this.$router.push("/");
    },
  },
  watch: {
    errorMessage: function () {
      this.errorMsg = this.errorMessage.repeat(1);

      if (this.errorMessage) this.closed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>