<template>
  <div class="has-background-grey-light p-5">
    <div class="about">
      <h1>{{ $t("softwareVersion") }}: {{ swVersion }}</h1>
      <h1 v-if="false">UID: {{ uid }}</h1>
      <a href="/15_FT003_Instructions_for_Use_Resolve_ENG_rev2.pdf">
        <button class="m-5 button is-primary">
          <span class="icon">
            <i class="fas fa-file"></i>
          </span>
          <span>{{ $t("downloadUserManual") }}</span>
        </button>
      </a>
      <h1>
        <img src="../assets/about.png" />
      </h1>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swVersion:
        process.env.VUE_APP_SOFTWARE_VERSION ||
        "development-build-" + new Date().toISOString(),
      uid: "0.0.1",
    };
  },
  // created(){
  // api.getSoftwareVersion(){

  // }
  // }
};
</script>

<style>
</style>