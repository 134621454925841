<template>
  <div>
    <form
      @submit.prevent="modifyPatient(patientId, clinicalDataToSend)"
      :class="this.errors ? 'errors' : false"
    >
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.heightPatient")
            }}</label>
            <div class="control">
              <input
                :class="[{ 'is-danger': isHeightInvalid }, 'input']"
                type="number"
                v-model="clinicalDataCopy.visit.height"
                :placeholder="$t('fieldsDescription.height')"
                @input="calculateAndSetBMI"
                @invalid="invalidateForm"
                min="60"
                max="280"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.bmiPatient")
            }}</label>
            <div class="control">
              <input
                class="input"
                type="number"
                step="0.01"
                v-model="clinicalDataCopy.visit.bmi"
                :placeholder="$t('fieldsDescription.bmi')"
                @invalid="invalidateForm"
                required
                disabled="disabled"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.smoker")
            }}</label>
            <div class="select is-fullwidth">
              <select v-model="clinicalDataCopy.visit.smoker">
                <option :value="true">{{ $t("yes") }}</option>
                <option :value="false">{{ $t("no") }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.weightPatient")
            }}</label>
            <div class="control">
              <input
                :class="[{ 'is-danger': isWeightInvalid }, 'input']"
                type="number"
                step="0.01"
                v-model="clinicalDataCopy.visit.weight"
                :placeholder="$t('fieldsDescription.weight')"
                @invalid="invalidateForm"
                @input="calculateAndSetBMI"
                min="10"
                max="650"
                required
              />
            </div>
          </div>
          <div class="field" style="visibility: hidden">
            <label class="label has-text-left">{{
              $t("buttons.hidden")
            }}</label>
            <div class="control">
              <input class="input" />
            </div>
          </div>
          <div
            class="field"
            :style="clinicalDataCopy.visit.smoker ? '' : 'visibility: hidden'"
          >
            <label class="label has-text-left">{{
              $t("fieldsDescription.packagesPerYear")
            }}</label>
            <div class="control">
              <input
                type="number"
                v-model="clinicalDataCopy.visit.packagesPerYear"
                :placeholder="$t('fieldsDescription.packagesPerYear')"
                :class="[
                  clinicalDataCopy.visit.smoker ? '' : 'visibility: hidden',
                  { 'is-danger': isPackYearNegative },
                  'input',
                ]"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.lastSpirometryTestDate")
            }}</label>
            <div class="control">
              <input
                class="input"
                type="date"
                v-model="spirometricTestDateFormatted"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.fev1")
            }}</label>
            <div class="control">
              <input
                :class="[{ 'is-danger': isFev1Negative }, 'input']"
                type="number"
                step="0.01"
                v-model="clinicalDataCopy.spirometricTest.fev1"
                placeholder="Fev1"
                @input="calculateAndSetFev1Fvc"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.fvc")
            }}</label>
            <div class="control">
              <input
                :class="[{ 'is-danger': isFvcNegative }, 'input']"
                type="number"
                step="0.01"
                v-model="clinicalDataCopy.spirometricTest.fvc"
                placeholder="FVC"
                @input="calculateAndSetFev1Fvc"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.fev1Fvc")
            }}</label>
            <div class="control">
              <input
                class="input"
                type="number"
                step="0.01"
                v-model="clinicalDataCopy.spirometricTest.fev1Fvc"
                placeholder="FEV1/FVC"
                disabled="disabled"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.hospitalizationsInTheLastYear")
            }}</label>
            <div class="control">
              <input
                :class="[
                  { 'is-danger': isTotalHospitalizationsNegative },
                  'input',
                ]"
                type="number"
                v-model="clinicalDataCopy.hospitalizations.totalLastYear"
                @input="setHospitalizations"
                :placeholder="$t('fieldsDescription.number')"
              />
            </div>
          </div>
          <div
            class="field"
            v-show="
              !isHospitalizationsForBPCOInvalid &&
              this.clinicalDataCopy.hospitalizations.forBPCO
            "
          >
            <label class="label has-text-left">{{
              $t("fieldsDescription.dateOfDischargeCOPD")
            }}</label>
            <div class="control">
              <input
                class="input"
                v-model="hospitalizationsDateOfDischargeFormatted"
                type="date"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.fev1PercPred")
            }}</label>
            <div class="control">
              <input
                :class="[{ 'is-danger': isFev1PercNegative }, 'input']"
                type="number"
                step="0.01"
                v-model="clinicalDataCopy.spirometricTest.fev1Perc"
                placeholder="Fev1%"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.fvcPercPred")
            }}</label>
            <div class="control">
              <input
                :class="[{ 'is-danger': isFvcPercNegative }, 'input']"
                type="number"
                step="0.01"
                v-model="clinicalDataCopy.spirometricTest.fvcPerc"
                placeholder="FVC%"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.fev1fvcPredPerc")
            }}</label>
            <div class="control">
              <input
                :class="[{ 'is-danger': isFev1FvcPercNegative }, 'input']"
                type="number"
                step="0.01"
                v-model="clinicalDataCopy.spirometricTest.fev1FvcPercPred"
                placeholder="FEV1/FVC %"
              />
            </div>
          </div>
          <div
            class="field"
            v-show="clinicalDataCopy.hospitalizations.totalLastYear > 0"
          >
            <label class="label has-text-left">{{
              $t("fieldsDescription.hospitalizationsForCOPDexacerbation")
            }}</label>
            <div class="control">
              <input
                :class="[
                  { 'is-danger': isHospitalizationsForBPCOInvalid },
                  'input',
                ]"
                type="number"
                v-model.number="clinicalDataCopy.hospitalizations.forBPCO"
                :placeholder="$t('fieldsDescription.number')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label has-text-left-end">{{
            $t("fieldsDescription.comorbidity")
          }}</label>
          <div class="columns">
            <div
              v-for="(comorbiditiesInColumn, idxColumn) in comorbiditiesToShow"
              :key="`element-${idxColumn}`"
              class="column"
            >
              <div
                v-for="(comorbidity, idxField) in comorbiditiesInColumn"
                :key="`checkbox-${idxField}`"
                class="field has-text-left"
              >
                <label class="checkbox has-text-left">
                  <input
                    type="checkbox"
                    v-model="clinicalDataCopy.spirometricTest.comorbidities"
                    :value="comorbiditiesToBeSendToDB[idxColumn * 3 + idxField]"
                  />
                  {{ $t(comorbidity) }}
                </label>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label
                v-if="showCustomComorbidities"
                class="label has-text-left-end"
                >{{ $t("fieldsDescription.otherComorbidities") }}</label
              >
              <div class="columns">
                <div
                  v-for="(column, idxColumn) in 4"
                  :key="idxColumn"
                  class="column"
                >
                  <div
                    v-for="idx in getArrayOfCustomComorbiditiesIdxToShowInColumn(
                      idxColumn
                    )"
                    :key="idx"
                    class="field is-horizontal"
                  >
                    <label
                      v-if="
                        clinicalDataCopy.spirometricTest.customComorbidities[
                          idx
                        ]
                      "
                      class="checkbox has-text-left"
                    >
                      <input type="checkbox" :checked="true" disabled />
                      {{
                        clinicalDataCopy.spirometricTest.customComorbidities[
                          idx
                        ]
                      }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <span
                    @click="addFieldInCustomComorbidities"
                    class="icon-text is-align-items-center is-flex add-custom-comorbidity"
                  >
                    <span class="icon is-large">
                      <i class="fas fa-2x fa-plus"></i>
                    </span>
                    <span>{{ $t("buttons.addComorbidity") }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="column">
              <div v-if="showCustomComorbidities">
                <div
                  v-for="(element, index) in this.clinicalDataCopy
                    .spirometricTest.customComorbidities"
                  :key="index"
                  class="columns"
                >
                  <div class="column">
                    <label class="has-text-left"></label>
                    <div class="control">
                      <input
                        v-model="
                          clinicalDataCopy.spirometricTest.customComorbidities[
                            index
                          ]
                        "
                        class="input"
                        type="text"
                        @invalid="invalidateForm"
                        required
                      />
                    </div>
                  </div>
                  <div class="column">
                    <span
                      class="icon-text is-align-items-center is-flex remove-custom-comorbidity"
                      @click="removeFieldInCustomComorbidities(index)"
                    >
                      <span class="icon is-large">
                        <i class="fas fa-2x fa-minus"></i>
                      </span>
                      <span>{{ $t("buttons.removeComorbidity") }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PatientSaveButton
        :awaitingResponse="awaitingResponse"
        :haveAllFieldsBeenFilledIn="this.haveAllFieldsBeenFilledIn()"
        :isDataEqualToTheSavedOnSubmit="this.isDataEqualToTheSavedOnSubmit()"
      />
    </form>
  </div>
</template>
<script>
import savePatientMixin from "./savePatientMixin.js";
import cloneDeep from "lodash/cloneDeep";
import PatientSaveButton from "./PatientSaveButton";
const emptyClinicalData = {
  spirometricTest: {
    date: "",
    fev1: "",
    fev1Perc: "",
    fvc: "",
    fvcPerc: "",
    fev1Fvc: "",
    fev1FvcPercPred: "",
    comorbidities: [],
    customComorbidities: [],
  },
  hospitalizations: {
    totalLastYear: "",
    forBPCO: "",
    dateOfDischarge: "",
  },
  visit: {
    height: "",
    weight: "",
    bmi: "",
    smoker: "",
    packagesPerYear: "",
  },
};
export default {
  props: ["patientToModify"],
  mixins: [savePatientMixin],
  components: {
    PatientSaveButton,
  },
  data() {
    return {
      comorbidity: [],
      patientId: this.patientToModify.PatientID,
      clinicalDataCopy: this.patientToModify.clinicalData
        ? cloneDeep(this.patientToModify.clinicalData)
        : emptyClinicalData,
      awaitingResponse: false,
      errors: false,
      comorbiditiesToShow: [
        [
          "comorbidities.heartFailure",
          "comorbidities.ischemicHeartDisease",
          "comorbidities.atrialFibrillation",
        ],
        [
          "comorbidities.highBloodPressure",
          "comorbidities.dementia",
          "comorbidities.COPD",
        ],
        [
          "comorbidities.chronicAsthma",
          "comorbidities.allergicAsthma",
          "comorbidities.sleepRelatedDisorder",
        ],
        [
          "comorbidities.osteoporosis",
          "comorbidities.hyperlipidemia",
          "comorbidities.diabetes",
        ],
      ],
      comorbiditiesToBeSendToDB: [
        "heart failure",
        "ischemic heart disease",
        "atrial fibrillation",
        "high blood pressure",
        "dementia",
        "COPD",
        "chronic asthma",
        "allergic asthma",
        "sleep-related disorder",
        "osteoporosis",
        "hyperlipidemia",
        "diabetes",
      ],
      spirometricTestDateFormatted: this.patientToModify?.clinicalData
        ?.spirometricTest
        ? this.ISOdate(this.patientToModify.clinicalData.spirometricTest.date)
        : "",
      hospitalizationsDateOfDischargeFormatted: this.patientToModify
        .clinicalData?.hospitalizations?.dateOfDischarge
        ? this.ISOdate(
            this.patientToModify.clinicalData.hospitalizations.dateOfDischarge
          )
        : "",
      showCustomComorbidities: this.areCustomComorbiditiesToShow(),
    };
  },
  methods: {
    areCustomComorbiditiesToShow() {
      return (
        this.clinicalDataCopy &&
        this.clinicalDataCopy.spirometricTest &&
        this.clinicalDataCopy.spirometricTest.customComorbidities.length > 0
      );
    },
    ISOdate(date) {
      if (!date || date.includes("Invalid Date")) return "";
      else {
        return new Date(date).toISOString().substring(0, 10);
      }
    },
    sortParameters() {
      this.clinicalDataCopy.spirometricTest.comorbidities.sort((a, b) => a - b);
    },
    invalidateForm() {
      this.errors = true;
    },
    calculateAndSetBMI() {
      const height = this.clinicalDataCopy.visit.height / 100;
      const weight = this.clinicalDataCopy.visit.weight;
      if (height > 0 && weight > 0)
        this.clinicalDataCopy.visit.bmi = (
          weight / Math.pow(height, 2)
        ).toFixed(2);
    },
    calculateAndSetFev1Fvc() {
      const fev1 = this.clinicalDataCopy.spirometricTest.fev1;
      const fvc = this.clinicalDataCopy.spirometricTest.fvc;
      if (fev1 > 0 && fvc > 0)
        this.clinicalDataCopy.spirometricTest.fev1Fvc = (fev1 / fvc).toFixed(2);
      else this.clinicalDataCopy.spirometricTest.fev1Fvc = null;
    },
    addFieldInCustomComorbidities() {
      if (!this.showCustomComorbidities) this.showCustomComorbidities = true;
      this.clinicalDataCopy.spirometricTest.customComorbidities.push("");
    },
    removeFieldInCustomComorbidities(idxToRemove) {
      const customComorbidities = this.clinicalDataCopy.spirometricTest
        .customComorbidities;

      customComorbidities.splice(idxToRemove, 1);
      if (customComorbidities.length === 0)
        this.showCustomComorbidities = false;
    },
    isDataEqualToTheSavedOnSubmit() {
      return (
        JSON.stringify(this.patientToModify.clinicalData) ===
        JSON.stringify(this.clinicalDataCopy)
      );
    },
    haveAllFieldsBeenFilledIn() {
      return (
        !this.isHeightInvalid &&
        !this.isWeightInvalid &&
        !this.isHospitalizationsForBPCOInvalid &&
        !this.isFev1Negative &&
        !this.isFev1PercNegative &&
        !this.isFvcNegative &&
        !this.isFvcPercNegative &&
        !this.isFev1FvcPercNegative &&
        !this.isTotalHospitalizationsNegative &&
        !this.isPackYearNegative
      );
    },
    getArrayOfCustomComorbiditiesIdxToShowInColumn(idxColumn) {
      const totOfCustomComorbidities = this.clinicalDataCopy.spirometricTest
        .customComorbidities.length;

      const listOfidxCustomComorbidities = [];

      while (totOfCustomComorbidities > idxColumn) {
        listOfidxCustomComorbidities.push(idxColumn);
        idxColumn += 4;
      }
      return listOfidxCustomComorbidities;
    },
    setHospitalizations() {
      if (!this.clinicalDataCopy.hospitalizations.totalLastYear) {
        this.clinicalDataCopy.hospitalizations.forBPCO = null;
        this.hospitalizationsDateOfDischargeFormatted = null;
      }
    },
  },
  computed: {
    clinicalDataToSend() {
      return { clinicalData: this.clinicalDataCopy };
    },
    isHeightInvalid() {
      return (
        60 >= this.clinicalDataCopy.visit.height ||
        this.clinicalDataCopy.visit.height >= 280
      );
    },
    isWeightInvalid() {
      return (
        10 >= this.clinicalDataCopy.visit.weight ||
        this.clinicalDataCopy.visit.weight >= 650
      );
    },
    isHospitalizationsForBPCOInvalid() {
      return (
        this.clinicalDataCopy.hospitalizations.totalLastYear <
          this.clinicalDataCopy.hospitalizations.forBPCO ||
        this.clinicalDataCopy.hospitalizations.forBPCO < 0
      );
    },
    isFev1Negative() {
      return this.clinicalDataCopy.spirometricTest.fev1 < 0;
    },
    isFvcNegative() {
      return this.clinicalDataCopy.spirometricTest.fvc < 0;
    },
    isFev1PercNegative() {
      return this.clinicalDataCopy.spirometricTest.fev1Perc < 0;
    },
    isFvcPercNegative() {
      return this.clinicalDataCopy.spirometricTest.fvcPerc < 0;
    },
    isFev1FvcPercNegative() {
      return this.clinicalDataCopy.spirometricTest.fev1FvcPercPred < 0;
    },
    isTotalHospitalizationsNegative() {
      return this.clinicalDataCopy.hospitalizations.totalLastYear < 0;
    },
    isPackYearNegative() {
      return this.clinicalDataCopy.visit.packagesPerYear < 0;
    },
  },
  watch: {
    spirometricTestDateFormatted() {
      this.clinicalDataCopy.spirometricTest.date = this.spirometricTestDateFormatted;
    },
    hospitalizationsDateOfDischargeFormatted() {
      this.clinicalDataCopy.hospitalizations.dateOfDischarge = this.hospitalizationsDateOfDischargeFormatted;
    },
  },
};
</script>
<style lang="scss" scoped>
input:invalid {
  border-color: red;
}
.add-custom-comorbidity:hover {
  color: $restech-green;
}
.remove-custom-comorbidity:hover {
  color: red;
}
</style>