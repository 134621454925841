var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table is-fullwidth is-fixed"},[(_vm.isLastMeasure)?_c('PatientMeasurementsTableHeading',{attrs:{"isCurrentRouteForPrintHistory":_vm.isCurrentRouteForPrintHistory}}):_vm._e(),_c('tbody',[_c('tr',{staticClass:"remove-border-bottom has-border-top"},[_c('td',{class:[
          'is-capitalized has-text-weight-semibold right-border',
          { 'p-0': _vm.isCurrentRouteForPrintHistory } ],attrs:{"colspan":"2"}},[_c('p',{class:[
            _vm.colorByStatus(_vm.measure.status),
            { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v(" "+_vm._s(this.wordByStatus(_vm.measure.status))+_vm._s(new Date(_vm.measure.date).toDateString() !== new Date(_vm.measure.receivedAt).toDateString() && _vm.isCurrentRouteForPrintHistory ? "*" : "")+" ")]),(
            _vm.statusHasBeenSetByHuman(_vm.measure.status) &&
            !_vm.isCurrentRouteForPrintHistory
          )?_c('p',{staticClass:"has-text-weight-normal is-size-7"},[(!_vm.statusChangedToday(_vm.measure.statusChangedAt))?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsDateTime")(_vm.measure.statusChangedAt))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("formatTimestampAsTime")(_vm.measure.statusChangedAt)))])]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
            new Date(_vm.measure.date).toDateString() !==
              new Date(_vm.measure.receivedAt).toDateString() &&
            !_vm.isCurrentRouteForPrintHistory
          ),expression:"\n            new Date(measure.date).toDateString() !==\n              new Date(measure.receivedAt).toDateString() &&\n            !isCurrentRouteForPrintHistory\n          "}],staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm.$t("received"))+": "+_vm._s(_vm._f("formatTimestampAsDate")(_vm.measure.receivedAt))+" ")]),(_vm.showScheduledCall && _vm.isLastMeasure)?_c('p',{class:[
            'has-text-weight-normal',
            _vm.isCurrentRouteForPrintHistory ? 'is-size-7' : 'is-size-6',
            [_vm.colorByStatus(_vm.measure.status)] ],attrs:{"id":"scheduled_call_icon_and_time"}},[_c('i',{class:[
              'fas fa-phone-volume lg pt-2',
              { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ]}),_vm._v(" "+_vm._s(_vm._f("formatTimestampAsTime")(this.scheduledCall))+" ")]):_vm._e()]),_c('td',{class:['right-border', { 'p-0': _vm.isCurrentRouteForPrintHistory }],attrs:{"colspan":"2"}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCurrentRouteForPrintHistory),expression:"!isCurrentRouteForPrintHistory"}],class:[
            {
              'has-anomaly':
                _vm.isLastMeasure &&
                _vm.above_date_error_threshold(
                  _vm.measure.date,
                  this.monitoringProtocol
                ),
              'orange-symptom':
                _vm.isLastMeasure &&
                !_vm.above_date_error_threshold(
                  _vm.measure.date,
                  this.monitoringProtocol
                ) &&
                _vm.above_date_warning_threshold(_vm.measure.date),
            },
            { 'is-size-7': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsDistance")(_vm.measure.date))+" ")]),_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsDateTime")(_vm.measure.localTimestamp))+" "+_vm._s(_vm.measure.deviceTimezone)+" ")])]),_c('td',{class:[
          _vm.getStyleForWorseningParam('Rrs'),
          { 'is-size-8 p-0': _vm.isCurrentRouteForPrintHistory } ],attrs:{"id":"RrsCell"}},[_c('span',{attrs:{"title":_vm.measure.respiratoryFunction.outputDescription}},[_vm._v(" "+_vm._s(this.getRrs)),_c('br'),_vm._v(" "+_vm._s(this.trendSymbol("Rrs")))])]),_c('td',{class:[
          _vm.getStyleForWorseningParam('Xrs'),
          { 'is-size-8 p-0': _vm.isCurrentRouteForPrintHistory } ],attrs:{"id":"XrsCell"}},[_c('span',{attrs:{"title":_vm.measure.respiratoryFunction.outputDescription}},[_vm._v(" "+_vm._s(this.getXrs)),_c('br'),_vm._v(" "+_vm._s(this.trendSymbol("Xrs")))])]),_c('td',{class:[
          _vm.getStyleForWorseningParam('efl'),
          { 'is-size-8 p-0': _vm.isCurrentRouteForPrintHistory },
          'right-border' ],attrs:{"id":"EflCell"}},[_c('span',{attrs:{"title":_vm.measure.respiratoryFunction.outputDescription}},[_vm._v(" "+_vm._s(this.getEfl)+" "),_c('br'),_vm._v(" "+_vm._s(this.trendSymbol("EFL")))])]),_c('td',{class:[
          { 'has-anomaly': this.isRrAbnormal },
          { 'is-size-8 p-0': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v(" "+_vm._s(this.getRr)+" ")]),_c('td',{class:[
          { 'has-anomaly': this.isVtAbnormal },
          'right-border',
          { 'is-size-8 p-0': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v(" "+_vm._s(this.getVt)+" ")]),_c('td',{class:[
          {
            'has-anomaly':
              this.isSpO2Abnormal &&
              _vm.protocolConsidersSaturation(this.monitoringProtocol),
          },
          'right-border',
          { 'is-size-8 p-0': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v(" "+_vm._s(this.getSpO2 || "-")+" ")]),_c('td',{class:[
          {
            'orange-symptom':
              this.isTemperatureAbnormal &&
              _vm.protocolConsidersTemperature(this.monitoringProtocol),
          },
          'right-border',
          { 'is-size-8 p-0': _vm.isCurrentRouteForPrintHistory } ]},[_vm._v(" "+_vm._s(this.getTemperature || "-")+" ")]),_c('td',{attrs:{"colspan":"3"}},[(_vm.measure.symptoms && _vm.measure.symptoms.length > 0)?_c('ul',{staticClass:"is-clipped has-text-left set-new-text-line squared"},_vm._l((_vm.measure.symptoms.filter(function (s) { return _vm.symptom_is_not_temperature_or_saturation(s); }
            )),function(symptom,idx){return _c('li',{key:idx,staticClass:"is-size-7 p-1",class:_vm.colorBySeverity(symptom.severity, _vm.monitoringProtocol)},[_vm._v(" "+_vm._s(!isNaN(symptom.name) ? _vm.$t("symptoms.Puffs of blue reliever") + ": " + symptom.name : _vm.$t("symptoms." + symptom.name))+" ")])}),0):(_vm.measure.symptoms)?_c('p',[_vm._v(_vm._s(_vm.$t("none")))]):_c('p',[_vm._v("-")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }