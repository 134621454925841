<template>
  <div class="has-background-grey-light p-1">
    <Loader v-if="loading" />
    <errorModal
      :errorMessage="error.message"
      :logoutOnClose="error.forceLogout"
    />
    <div v-if="!loading" class="box m-2 p-5">
      <div class="columns">
        <div class="column"></div>
        <div class="column is-three-quarters">
          <ProgressBar
            :currentStep="activeStep"
            :patient="patient"
            @handleStepCircleClick="goToStep($event)"
          />
        </div>
        <div class="column">
          <button
            v-if="patient.PatientID"
            class="button is-success is-small m-1 fixed-width"
            @click="$router.push(`/measurements/${patient.PatientID}`)"
          >
            <i class="fas fa-chart-line m-2" style="cursor: pointer"></i>
            <span class="pr-4">{{ $t("patientHistory") }}</span>
          </button>
        </div>
      </div>
      <div class="columns">
        <div class="column"></div>
        <div class="column is-three-quarters">
          <PatientPersonalDataForm
            v-if="activeStep == 0 && patient.personalData"
            :patientToModify="patient"
            @errorOnSaveHandler="displayError($event)"
            @saveClickHandler="saveCreatedPatient($event)"
            @updateClickHandler="
              updatePatientWithDataOnDb($event, 'personalData')
            "
          />
          <PatientCaregiverDataForm
            v-if="activeStep == 1"
            :patientToModify="patient"
            @errorOnSaveHandler="displayError($event)"
            @updateClickHandler="
              updatePatientWithDataOnDb($event, 'caregiverData')
            "
          />
          <PatientDoctorDataForm
            v-if="activeStep == 2"
            :patientToModify="patient"
            @errorOnSaveHandler="displayError($event)"
            @updateClickHandler="
              updatePatientWithDataOnDb($event, 'doctorData')
            "
          />
          <PatientClinicalDataForm
            v-if="activeStep == 3"
            :patientToModify="patient"
            @errorOnSaveHandler="displayError($event)"
            @updateClickHandler="
              updatePatientWithDataOnDb($event, 'clinicalData')
            "
          />
          <PatientMonitoringDataForm
            v-if="activeStep == 4"
            :patientToModify="patient"
            @errorOnSaveHandler="displayError($event)"
            @updateClickHandler="
              updatePatientWithDataOnDb($event, 'monitoringData')
            "
          />
          <PatientSummary
            v-if="activeStep == 5 && patient.personalData"
            @errorOnSaveHandler="displayError($event)"
            @handleModifyDataClick="goToStep($event)"
            :data="patient"
          />
        </div>
        <div class="column"></div>
      </div>
    </div>
  </div>
</template>
<script>
import PatientPersonalDataForm from "./PatientPersonalDataForm.vue";
import PatientCaregiverDataForm from "./PatientCaregiverDataForm.vue";
import PatientDoctorDataForm from "./PatientDoctorDataForm.vue";
import PatientClinicalDataForm from "./PatientClinicalDataForm.vue";
import PatientMonitoringDataForm from "./PatientMonitoringDataForm.vue";
import ProgressBar from "./ProgressBar.vue";
import PatientSummary from "./PatientSummary.vue";
import api from "@/API/api.js";
import cloneDeep from "lodash/cloneDeep";
import Loader from "@/shared/Loader.vue";
import errorMixin from "../Error/errorMixin";
import errorModal from "../Error/errorModal";

export default {
  name: "PatientForm",
  props: ["patientID", "currentStep"],
  components: {
    PatientPersonalDataForm,
    PatientCaregiverDataForm,
    PatientDoctorDataForm,
    PatientClinicalDataForm,
    PatientMonitoringDataForm,
    ProgressBar,
    PatientSummary,
    Loader,
    errorModal,
  },
  data() {
    return {
      patient: null,
      loading: true,
      activeStep: 0,
    };
  },
  mixins: [errorMixin],
  computed: {
    firstStepWithNoData() {
      if (!this.patient.personalData || !this.patient.personalData.name)
        return 0;
      else if (!this.patient.caregiverData) return 1;
      else if (!this.patient.doctorData) return 2;
      else if (!this.patient.clinicalData) return 3;
      else if (!this.patient.monitoringData) return 4;
      else return 5; // a complete profile will go to the summary page
    },
    emptyPatient() {
      return {
        patientID: "",
        personalData: {
          name: "",
          surname: "",
          email: "",
          sex: "",
          birthplace: "",
          birthdate: "",
          address: "",
          domicile: "",
          PatientCode: "",
          phone: "",
        },
        caregiverData: {
          disabled: false,
          name: "",
          surname: "",
          email: "",
          phone: "",
          relation: "",
        },
        clinicalData: {
          spirometricTest: {
            date: "",
            fev1: "",
            fev1Perc: "",
            fvc: "",
            fvcPerc: "",
            fev1FvcPercPred: "",
            comorbidities: [],
            customComorbidities: [],
          },
          hospitalizations: {
            totalLastYear: "",
            forBPCO: "",
            dateOfDischarge: "",
          },
          visit: {
            height: "",
            weight: "",
            bmi: "",
            smoker: "",
            packagesPerYear: "",
          },
        },
        doctorData: {
          id: "",
          name: "",
          surname: "",
          email: "",
          phone: "",
        },
        monitoringData: {
          monitoringProtocol: "",
          patientGroup: "",
          device: "",
        },
      };
    },
  },
  methods: {
    saveCreatedPatient(data) {
      this.patient.PatientID = data.id;
      this.patient.personalData = cloneDeep(data.personalData);
      this.patient = cloneDeep(this.patient);
    },
    updatePatientWithDataOnDb(data, field) {
      this.patient[field] = cloneDeep(data[field]);
      //Viene copiato l'oggetto per aggiornare la progressBar
      this.patient = cloneDeep(this.patient);
    },
    goToStep(stepIdx) {
      this.activeStep = stepIdx;
    },
    displayError(errorMessage) {
      this.error.message = `[${new Date().toISOString()}] ` + errorMessage;
    },
  },
  async created() {
    try {
      this.loading = true;
      if (!this.patientID) this.patient = this.emptyPatient;
      else {
        this.patient = await api.getPatient(this.patientID);

        // console.log("Step (prop): " + this.currentStep);
        this.goToStep(this.currentStep || this.firstStepWithNoData);
      }
    } catch (e) {
      // TODO error management
      if (this.error) this.error.message = `[${new Date().toISOString()}] ` + e;
      else
        console.log(
          "this.error is undefined (fix your modal!) - error is: " +
            JSON.stringify(e)
        );
    } finally {
      this.loading = false;
    }
  },
};
</script>
<style>
</style>