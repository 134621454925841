<template>
  <div class="columns">
    <div class="column">
      <p class="is-size-5 has-text-left">
        {{ $t("dailyMeasurements") }}: {{ measurementsExpected.length }}
      </p>
    </div>
    <div class="column">
      <div style="max-width: 300px; max-height: 150px">
        <canvas id="measurements-chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import measurementStatusMixin from "../shared/measurementStatusMixin";
// import { differenceInDays } from "date-fns";
import Chart from "chart.js";
import { Settings } from "luxon";
Settings.defaultLocale = "it";

export default {
  mixins: [measurementStatusMixin],
  props: {
    todaysData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      chart: "",
    };
  },
  methods: {
    chartData() {
      return {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [
                this.measurementsReceived.length,
                this.missingMeasurements,
              ],
              backgroundColor: ["#a8b400", "red"],
            },
          ],

          labels: [this.received, this.toBeReceived],
        },
        options: {
          responsive: true,
          legend: {
            position: "left",
          },
        },
      };
    },
  },
  computed: {
    received() {
      return this.$t("charts.received");
    },
    toBeReceived() {
      return this.$t("charts.toBeReceived");
    },
    measurementsReceived() {
      return this.measurementsExpected.filter((m) =>
        // this.statusHasBeenSetByHuman(m.status) ||
        this.below_any_threshold(m.lastMeasurementTimestamp)
      );
    },
    measurementsExpected() {
      return this.todaysData.filter((p) => this.statusIsActive(p.status));
    },
    missingMeasurements() {
      return (
        this.measurementsExpected.length - this.measurementsReceived.length
      );
    },
  },
  watch: {
    /* update dictionary when changing language */
    received() {
      this.chart.data.labels[0] = this.received;
      this.chart.update();
    },
    toBeReceived() {
      this.chart.data.labels[1] = this.toBeReceived;
      this.chart.update();
    },
  },
  mounted() {
    const ctx = document.getElementById("measurements-chart").getContext("2d");
    this.chart = new Chart(ctx, this.chartData());
  },
};
</script>

<style lang="scss" scoped>
</style>