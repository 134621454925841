export default {
  data() {
    return {
      error: {
        message: "",
        forceLogout: false,
      },
    };
  },
  methods: {
    parseError(E) {
      const errString = typeof E == "string" ? E : E.errorMessage || E.toString() || JSON.stringify(E);
      return `[${new Date().toISOString()}] ${errString}`;
    },
  },
};
