<template>
  <thead>
    <tr>
      <th rowspan="2" colspan="2" class="is-clipped right-border">
        <div class="field">
          <label
            :class="[
              'label custom-font-weight',
              { 'is-size-7': isCurrentRouteForPrintHistory },
            ]"
            >{{ $t("columns.state") }}</label
          >
          <p class="test"></p>
        </div>
      </th>
      <th rowspan="2" colspan="2" class="is-clipped right-border">
        <div class="field">
          <label
            :class="[
              'label custom-font-weight',
              { 'is-size-7': isCurrentRouteForPrintHistory },
            ]"
            >{{ $t("columns.measurementDate") }}</label
          >
        </div>
      </th>
      <th
        colspan="3"
        :class="[
          'is-clipped right-border remove-border-bottom',
          { 'is-size-7': isCurrentRouteForPrintHistory },
        ]"
      >
        {{ $t("columns.respiratoryFunction") }} (cmH<sub>2</sub>0/(L/s))
      </th>
      <th
        colspan="2"
        :class="[
          'is-clipped right-border remove-border-bottom',
          { 'is-size-7': isCurrentRouteForPrintHistory },
        ]"
      >
        {{ $t("columns.respiratoryPattern") }}
      </th>
      <th rowspan="2" class="is-clipped right-border">
        <div class="field">
          <label
            :class="[
              'label custom-font-weight',
              { 'is-size-7': isCurrentRouteForPrintHistory },
            ]"
            >SpO<sub>2</sub> (%)</label
          >
        </div>
      </th>
      <th rowspan="2" class="is-clipped right-border">
        <div class="field">
          <label
            :class="[
              'label custom-font-weight',
              { 'is-size-7': isCurrentRouteForPrintHistory },
            ]"
            >T (°C)</label
          >
        </div>
      </th>
      <th colspan="3" rowspan="2" class="is-clipped">
        <div class="field">
          <label
            :class="[
              'label custom-font-weight',
              { 'is-size-7': isCurrentRouteForPrintHistory },
            ]"
            >{{ $t("columns.symptoms") }}</label
          >
        </div>
      </th>
    </tr>
    <tr class="right-border">
      <td :class="{ 'is-size-7': isCurrentRouteForPrintHistory }">Rrs</td>
      <td :class="{ 'is-size-7': isCurrentRouteForPrintHistory }">Xrs</td>
      <td :class="{ 'is-size-7': isCurrentRouteForPrintHistory }">Δxrs</td>
      <td :class="{ 'is-size-7': isCurrentRouteForPrintHistory }">RR (bpm)</td>
      <td :class="{ 'is-size-7': isCurrentRouteForPrintHistory }">Vt (L)</td>
    </tr>
  </thead>
</template>

<script>
export default {
  props: ["isCurrentRouteForPrintHistory"],
};
</script>

<style lang="scss" scoped>
.remove-border-bottom > td,
.remove-border-bottom {
  border-bottom: 0 !important;
}
.right-border > td,
.right-border {
  border-right: 1px solid #e5e5e5 !important;
}
</style>