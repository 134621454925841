import Vue from "vue";
import VueRouter from "vue-router";
import Devices from "@/Devices/ViewDevices.vue";
import PageNotFound from "@/Error/ViewPageNotFound.vue";
import Login from "@/Auth/ViewLogin.vue";
import ForceChangePassword from "@/Auth/ViewForceChangePassword.vue";
import Dashboard from "@/Dashboard/ViewDashboard.vue";
import Patients from "@/Patients/ViewPatients.vue";
import Physicians from "@/Physicians/ViewPhysicians.vue";
import PatientHistory from "@/Measurements/ViewPatientHistory.vue";
import PrintPatientHistory from "@/Measurements/ViewPrintPatientHistory.vue";
import ViewPatientForm from "@/Patients/ViewPatientForm.vue";
import About from "@/About/ViewAbout.vue";
import Accounts from "@/Accounts/ViewAccounts.vue";

Vue.use(VueRouter);

/* NB: "name" is a i18next dictionary's key */

const routes = [
  {
    path: "/",
    name: "root",
    component: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/set-password",
    name: "ForceChangePassword",
    component: ForceChangePassword,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/devices",
    name: "device_plural",
    component: Devices,
  },
  {
    path: "/patients",
    name: "patient_plural",
    component: Patients,
  },
  {
    path: "/patient-form",
    name: "patientData",
    component: ViewPatientForm,
    props: (route) => ({
      patientID: route.query.patient,
      currentStep: route.query.currentStep,
    }),
  },
  {
    path: "/physicians",
    name: "physician_plural",
    component: Physicians,
  },
  {
    path: "/accounts",
    name: "operator_plural",
    component: Accounts,
  },
  {
    path: "/measurements/:patient_id",
    name: "patientHistory",
    component: PatientHistory,
    props: true,
  },
  {
    path: "/measurements/:patient_id/print",
    name: "printPatientHistory",
    component: PrintPatientHistory,
    props: (route) => ({
      patient: route.query.patient,
      patientID: route.query.patientID,
      measurementsPointsToPlot: route.query.measurementsPointsToPlot,
      measurements: route.query.measurements,
      customTimeSeriesRange: route.query.customTimeSeriesRange,
      customImpedanceRange: route.query.customImpedanceRange,
    }),
  },
  {
    path: "/about/",
    name: "about",
    component: About,
    props: true,
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  props: true,
  base: process.env.BASE_URL,
  linkActiveClass: "is-active",
  routes,
});

export default router;
