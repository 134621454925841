<template>
  <div class="has-background-grey-light p-5">
    <Loader v-if="loading" />
    <errorModal
      :errorMessage="error.message"
      :logoutOnClose="error.forceLogout"
    />
    <div
      v-if="this.noContent && !this.loading"
      class="m-6 has-text-weight-bold"
    >
      {{ $t("warnings.noOperators") }}
    </div>

    <div id="audit-log-btn" v-if="this.isAdminOrManager && !this.noContent">
      <!-- 
        Al posto di @click è meglio usare direttamente un anchor a, 
        il browser lo interpreta meglio come download. Tuttavia, con il mock
        endpoing di postman non funziona perchè è necessario inviare l'api key nell'header.
        Quindi in testing lasciamo @click, in produzione (AWS) possiamo
        pensare di usare sempre direttamente a href=".../accounts/audit"
        <a
        href="https://d856f1f3-e72a-4691-a3ca-c6f576ef34c5.mock.pstmn.io/accounts/audit"
      -->
      <button
        @click="downloadAuditTrail"
        :class="[this.downloadingLog ? 'is-loading' : '']"
        class="m-3 button is-primary"
      >
        {{ $t("downloadAuditLog") }}
      </button>
      <!-- </a> -->
    </div>
    <div
      id="select-audit-timeframe"
      class="select"
      v-if="this.isManager && !this.noContent"
    >
      <select v-model="auditLogMonths">
        <option selected="selected" value="1">{{ "1 " + $t("month") }}</option>
        <option value="3">{{ "3 " + $t("month", { count: 3 }) }}</option>
        <option value="12">{{ "12 " + $t("month", { count: 12 }) }}</option>
      </select>
    </div>
    <div v-show="!this.noContent" class="box columns m-2 p-5">
      <AccountsTable :accounts="accounts" />
    </div>
  </div>
</template>

<script>
import api from "../API/api";
import errorMixin from "../Error/errorMixin";
import errorModal from "../Error/errorModal";
import Loader from "@/shared/Loader.vue";
import AccountsTable from "./AccountsTable.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      downloadingLog: false,
      auditLogMonths: 1,
      accounts: [],
    };
  },
  components: { errorModal, Loader, AccountsTable },
  mixins: [errorMixin],
  computed: {
    ...mapGetters(["hasRoleAdmin", "hasRoleMonitor", "hasRoleManager"]),
    noContent() {
      return this.accounts.length <= 0;
    },
    isAdminOrManager() {
      return this.hasRoleAdmin || this.hasRoleManager;
    },
    isManager() {
      return this.hasRoleManager;
    },
  },
  methods: {
    async downloadAuditTrail() {
      try {
        this.downloadingLog = true;
        const data = await api.getAuditTrail(this.auditLogMonths);
        // force the browser to display download window...
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([data]));
        link.setAttribute("download", "audit_trail.csv");
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        this.error.message = this.parseError(e);
        this.error.forceLogout = true;
      } finally {
        this.downloadingLog = false;
      }
    },
  },
  async created() {
    try {
      this.loading = true;
      this.accounts = await api.getAccounts();
    } catch (e) {
      this.error.message = this.parseError(e);
      this.error.forceLogout = true;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>