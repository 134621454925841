var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('errorModal',{attrs:{"errorMessage":_vm.error.message,"logoutOnClose":_vm.error.forceLogout}}),(!_vm.isLoading && _vm.areThereMeasurements)?_c('div',{class:['modal', { 'is-active': _vm.displayStatusList }]},[_c('div',{staticClass:"modal-background",on:{"click":function($event){_vm.displayStatusList = false}}}),_c('div',{staticClass:"modal-card small-width"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.$t("buttons.changeStatus")))])]),_c('section',{staticClass:"modal-card-body"},[(_vm.measurements[0])?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"}),_c('div',{staticClass:"column"},[_c('div',{staticClass:"control"},[_c('ul',_vm._l((_vm.getStatusListWithoutCurrent(
                    _vm.measurements[0].status
                  )),function(status,idx){return _c('li',{key:idx,class:[
                    'is-size-5',
                    {
                      'status-border-bottom':
                        idx !=
                        _vm.getStatusListWithoutCurrent(_vm.measurements[0].status)
                          .length -
                          1,
                    } ],on:{"click":function($event){return _vm.changeStatus(status.statusName)}}},[_vm._v(" "+_vm._s(_vm.$t(status.text))+" ")])}),0)])]),_c('div',{staticClass:"column"})]):_vm._e(),(_vm.patientHasBeenDeactivated)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-text-danger is-size-7"},[_vm._v(" "+_vm._s(_vm.$t("warnings.patientHasBeenDeactivated"))+" ")])])]):_vm._e()]),_c('footer',{staticClass:"modal-card-foot"})]),_c('button',{staticClass:"modal-close is-large",attrs:{"aria-label":"close"},on:{"click":function($event){_vm.displayStatusList = false}}})]):_vm._e(),(_vm.isLoading)?_c('Loader'):_vm._e(),(!_vm.isLoading && _vm.areThereMeasurements)?_c('div',[_vm._l((this.measurements),function(measure,idx){return _c('div',{key:idx},[(idx == 1)?_c('div',{class:[
          'has-text-left has-top-borders has-text-bold ',
          { 'is-size-7 ': _vm.isCurrentRouteForPrintHistory },
          !_vm.isCurrentRouteForPrintHistory ? 'mt-3 p-3' : 'p-1' ],style:({ 'height: 50px': _vm.isCurrentRouteForPrintHistory })},[_c('strong',[_vm._v(_vm._s(_vm.$t("statusHistory")))])]):_vm._e(),_c('PatientMeasurementTable',{attrs:{"measure":measure,"monitoringProtocol":_vm.monitoringProtocol,"isLastMeasure":idx == 0,"isCurrentRouteForPrintHistory":_vm.isCurrentRouteForPrintHistory,"showScheduledCall":_vm.showScheduledCall,"scheduledCall":_vm.scheduledCall}}),_c('PatientMeasurementNote',{attrs:{"notes":measure.notes,"isCurrentRouteForPrintHistory":_vm.isCurrentRouteForPrintHistory,"isLastMeasure":idx == 0,"measurementId":measure.measurementId},on:{"handleNewNote":function($event){return _vm.$emit('handleNewNote', $event)}}}),(
          idx == 0 && measure.date === _vm.globalMostRecentMeasurementDatetime
        )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCurrentRouteForPrintHistory),expression:"!isCurrentRouteForPrintHistory"}],staticClass:"columns",attrs:{"id":"changeStatusButton"}},[_c('div',{staticClass:"column"}),_c('div',{staticClass:"column is-one-fifth"},[_c('div',{staticClass:"has-text-centered pl-4 pr-4"},[_c('button',{class:[
                'button is-success is-small fixed-width',
                { 'is-loading': _vm.awaitingResponse } ],on:{"click":function($event){return _vm.displayAndSetStatus(measure)}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.changeStatus"))+" ")])])])]):_vm._e()],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        this.areAnyReceivedDataDifferentFromMeasurementData() &&
        _vm.isCurrentRouteForPrintHistory
      ),expression:"\n        this.areAnyReceivedDataDifferentFromMeasurementData() &&\n        isCurrentRouteForPrintHistory\n      "}],staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm.$t("warnings.dateOfreceivedIsDifferentMeasurement"))+" ")])],2):_vm._e(),(!_vm.isLoading && !_vm.areThereMeasurements)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t("warnings.noMeasurements")))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }