<template>
  <div class="has-background-grey-light p-1">
    <Loader v-if="this.loading" />
    <errorModal
      :errorMessage="error.message"
      :logoutOnClose="error.forceLogout"
    />
    <div v-if="this.noContent && !this.loading">
      {{ $t("warnings.noPatient") }}
    </div>
    <!-- tasti -->
    <div
      v-show="!this.loading"
      id="create-delete-btn"
      v-if="this.isAdminOrManager"
    >
      <router-link :to="{ path: '/patient-form' }">
        <button class="m-3 button is-primary">
          {{ $t("buttons.addPatient") }}
        </button>
      </router-link>
      <button
        v-show="!noContent"
        :class="[
          'm-3 button',
          !arePatientsToDelete ? 'is-primary' : 'is-danger',
        ]"
        @click="this.arePatientsToDeleteOrShowCheckboxes"
      >
        {{ this.getTextForDeleteButton() }}
      </button>
    </div>
    <!-- campi di ricerca / filtraggio -->
    <div v-if="!this.noContent" class="box columns m-2 p-5">
      <div style="width: 3%"></div>
      <div class="column has-border-right-grey">
        <div class="field">
          <label class="label custom-font-weight">{{
            $t("fieldsDescription.surname")
          }}</label>
          <div class="control has-icons-right">
            <input
              v-model.trim="surname"
              @input="filterPatientsBySearchedTerms"
              class="input"
              type="text"
              :placeholder="$t('fieldsDescription.surname')"
            />
            <span class="icon is-small is-right">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="column has-border-right-grey">
        <div class="field">
          <label class="label custom-font-weight">{{
            $t("fieldsDescription.name")
          }}</label>
          <div class="control has-icons-right">
            <input
              class="input"
              v-model.trim="name"
              @input="filterPatientsBySearchedTerms"
              type="text"
              :placeholder="$t('fieldsDescription.name')"
            />
            <span class="icon is-small is-right">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="column has-border-right-grey">
        <div class="field">
          <label class="label custom-font-weight">{{
            $t("fieldsDescription.birthdate")
          }}</label>
          <div class="control has-icons-right">
            <input
              class="input"
              v-model.trim="birthdate"
              @input="filterPatientsBySearchedTerms"
              type="text"
              :placeholder="$t('fieldsDescription.birthdate')"
            />
            <span class="icon is-small is-right">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="column has-border-right-grey">
        <div class="field">
          <label class="label custom-font-weight">{{
            $t("fieldsDescription.patientCode")
          }}</label>
          <div class="control has-icons-right">
            <input
              class="input"
              v-model.trim="PatientCode"
              @input="filterPatientsBySearchedTerms"
              type="text"
              :placeholder="$t('fieldsDescription.patientCode')"
            />
            <span class="icon is-small is-right">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="column has-border-right-grey">
        <div class="field">
          <label class="label custom-font-weight">{{
            $t("fieldsDescription.referringPhysician")
          }}</label>
          <div class="control has-icons-right">
            <input
              class="input"
              v-model.trim="physician"
              @input="filterPatientsBySearchedTerms"
              type="text"
              :placeholder="$t('fieldsDescription.referringPhysician')"
            />
            <span class="icon is-small is-right">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="column has-border-right-grey">
        <div class="field">
          <label class="label custom-font-weight">{{
            $t("stepsName.monitoringData")
          }}</label>
          <div class="control has-icons-right">
            <input
              class="input"
              v-model.trim="monitoring"
              @input="filterPatientsBySearchedTerms"
              type="text"
              :placeholder="$t('stepsName.monitoringData')"
            />
            <span class="icon is-small is-right">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div style="width: 5%"></div>
    </div>
    <!-- tabelle con contenuto -->
    <div
      v-show="!this.noContent && this.patientsToComplete.length > 0"
      class="box columns m-2 p-5"
    >
      <PatientsTable
        :patients="patientsToComplete"
        :profileComplete="false"
        :patientHaveBeenDeleted="patientHaveBeenDeleted"
        :arePatientsToDelete="arePatientsToDelete"
        @handlePatientsToDelete="patientsToCompleteToDelete = $event"
        @handleSetPatientToDeleteAsEmpty="patientHaveBeenDeleted = false"
      />
    </div>
    <div
      v-show="!this.noContent && this.completedPatients.length > 0"
      class="box columns m-2 p-5"
    >
      <PatientsTable
        :patients="completedPatients"
        :profileComplete="true"
        :patientHaveBeenDeleted="patientHaveBeenDeleted"
        :arePatientsToDelete="arePatientsToDelete"
        @handlePatientsToDelete="completedPatientsToDelete = $event"
        @handleSetPatientToDeleteAsEmpty="patientHaveBeenDeleted = false"
      />
    </div>
    <div
      v-show="!this.noContent && this.anonymousPatients.length > 0"
      class="box columns m-2 p-5"
    >
      <PatientsTable
        :patients="anonymousPatients"
        :patientHaveBeenDeleted="patientHaveBeenDeleted"
        :arePatientsToDelete="arePatientsToDelete"
        @handlePatientsToDelete="completedPatientsToDelete = $event"
        @handleSetPatientToDeleteAsEmpty="patientHaveBeenDeleted = false"
      />
    </div>
    <PatientsDeleteModal
      :showModal="openModalToDeletePatients"
      :patientsToDelete="patientsToDelete"
      :patients="patients"
      @handleCloseModal="openModalToDeletePatients = false"
      @handleDeletePatients="removeDeletedPetients($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PatientsTable from "./PatientsTable";
import PatientsDeleteModal from "./PatientsDeleteModal";
import api from "../API/api";
import errorMixin from "../Error/errorMixin";
import errorModal from "../Error/errorModal";
import Loader from "@/shared/Loader.vue";
import { format } from "date-fns";
import { it } from "date-fns/esm/locale"; // TODO importa il locale in base alla lingua selezionata
export default {
  components: {
    PatientsDeleteModal,
    PatientsTable,
    errorModal,
    Loader,
  },
  mixins: [errorMixin],
  data() {
    return {
      surname: "",
      name: "",
      PatientCode: "",
      birthdate: "",
      physician: "",
      monitoring: "",
      patients: [],
      patientsToComplete: [],
      completedPatients: [],
      anonymousPatients: [],
      //selectedPatientID:
      //  "" /* TODO spostare questa prop, serve per passare a patient-form il paziente selezionato in tabella */,
      loading: false,
      arePatientsToDelete: false,
      completedPatientsToDelete: [],
      patientsToCompleteToDelete: [],
      anonymousPatientsToDelete: [],
      openModalToDeletePatients: false,
      patientHaveBeenDeleted: false,
    };
  },
  async created() {
    try {
      this.loading = true;
      this.patients = await api.getPatients();

      if (!this.noContent) {
        this.patients.forEach((p) => {
          p.personalData.physician =
            p.doctorData && p.doctorData.surname
              ? `${p.doctorData.name} ${p.doctorData.surname}`
              : "-";
          if (p.isAnonymous && p.personalData && p.monitoringData)
            p.personalData.surname = p.monitoringData.device;
          if (!p?.monitoringData) {
            p.monitoringData = {};
            p.monitoringData.patientGroup = "-";
            p.monitoringData.monitoringProtocol = "-";
          }
        });
        this.setPatientsType();
      }
    } catch (e) {
      this.error.message = `[${new Date().toISOString()}] ` + e;
      this.error.forceLogout = true;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters(["hasRoleAdmin", "hasRoleMonitor", "hasRoleManager"]),
    isAdminOrManager() {
      return this.hasRoleAdmin || this.hasRoleManager;
    },
    noContent() {
      return (
        this.patients.length <= 0 ||
        this.patients.find((res) =>
          res.errorMessage?.includes("no allowed patients")
        )
      );
    },
    patientsToDelete() {
      return this.patientsToCompleteToDelete.concat(
        this.anonymousPatientsToDelete,
        this.completedPatientsToDelete
      );
    },
  },
  methods: {
    getTextForDeleteButton() {
      if (this.patientsToDelete.length > 0)
        return this.$t("deleteProfile", {
          count: this.patientsToDelete.length,
        });
      else if (this.patientsToDelete.length == 0 && this.arePatientsToDelete)
        return this.$t("buttons.cancel");
      else return this.$t("deleteProfile");
    },
    setPatientsType() {
      this.patientsToComplete = this.patients.filter(
        (p) => !this.isProfileComplete(p) && !p.isAnonymous
      );
      this.completedPatients = this.patients.filter((p) =>
        this.isProfileComplete(p)
      );
      this.anonymousPatients = this.patients.filter((p) => p.isAnonymous);
    },
    removeDeletedPetients(patients) {
      patients.forEach((pDeleteId) => {
        this.patients.forEach((p, idx) => {
          if (p.PatientID == pDeleteId) {
            this.patients.splice(idx, 1);
          }
        });
      });
      if (patients.length === this.patientsToDelete.length) {
        this.arePatientsToDelete = false;
      }
      this.completedPatientsToDelete = [];
      this.patientsToCompleteToDelete = [];
      this.anonymousPatientsToDelete = [];
      this.patientHaveBeenDeleted = true;
      this.setPatientsType();
    },
    arePatientsToDeleteOrShowCheckboxes() {
      if (this.patientsToDelete.length == 0 && this.arePatientsToDelete)
        this.arePatientsToDelete = false;
      else if (this.arePatientsToDelete) this.openModalToDeletePatients = true;
      else this.arePatientsToDelete = true;
    },
    isProfileComplete(profile) {
      return (
        !this.areDoctorDataIncompleted(profile) &&
        !this.areCaregiverDataIncompleted(profile) &&
        !this.areMonitoringDataIncompleted(profile) &&
        !this.areClinicalDataIncompleted(profile) &&
        !this.arePersonalDataIncompleted(profile)
      );
    },
    areDoctorDataIncompleted(profile) {
      return (
        !profile.doctorData &&
        !profile.doctorData?.name &&
        !profile.doctorData?.id &&
        !profile.doctorData?.phone &&
        !profile.doctorData?.surname &&
        !profile.doctorData?.email
      );
    },
    areCaregiverDataIncompleted(profile) {
      return (
        !profile.caregiverData &&
        (!profile.caregiverData?.disabled ||
          (!profile.caregiverData &&
            !profile.caregiverData?.phone &&
            !profile.caregiverData?.surname &&
            !profile.caregiverData?.email &&
            !profile.caregiverData?.relation))
      );
    },
    areMonitoringDataIncompleted(profile) {
      return (
        !profile?.monitoringData &&
        !profile?.monitoringData?.monitoringProtocol &&
        !profile?.monitoringData?.device &&
        !profile?.monitoringData?.patientGroup
      );
    },
    areClinicalDataIncompleted(profile) {
      return (
        !profile?.clinicalData &&
        !profile?.clinicalData?.spirometricTest &&
        !profile?.clinicalData?.spirometricTest.date &&
        !profile?.clinicalData?.spirometricTest.fev1Perc &&
        !profile?.clinicalData?.spirometricTest.fev1FvcPercPred &&
        !profile?.clinicalData?.spirometricTest.fvc &&
        !profile?.clinicalData?.spirometricTest.customComorbidities &&
        !profile?.clinicalData?.spirometricTest.comorbidities &&
        !profile?.clinicalData?.spirometricTest.fev1 &&
        !profile?.clinicalData?.spirometricTest.fvcPerc &&
        !profile?.clinicalData &&
        !profile?.clinicalData?.hospitalizations &&
        !profile?.clinicalData?.hospitalizations.forBPCO &&
        !profile?.clinicalData?.hospitalizations.totalLastYear &&
        !profile?.clinicalData?.hospitalizations.dateOfDischarge &&
        !profile?.clinicalData?.visit &&
        !profile?.clinicalData?.visit.weight &&
        !profile?.clinicalData?.visit.smoker &&
        !profile?.clinicalData?.visit.height &&
        !profile?.clinicalData?.visit.bmi
      );
    },
    arePersonalDataIncompleted(profile) {
      return (
        !profile?.personalData &&
        !profile?.personalData?.birthdate &&
        !profile?.personalData?.address &&
        !profile?.personalData?.birthplace &&
        !profile?.personalData?.PatientCode &&
        !profile?.personalData?.phone &&
        !profile?.personalData?.surname &&
        !profile?.personalData?.sex &&
        !profile?.personalData?.name &&
        !profile?.personalData?.domicile &&
        !profile?.personalData?.email &&
        !profile?.personalData?.physician
      );
    },
    filterPatientsBySearchedTerms() {
      try {
        let filteredPatientsToComplete = this.patients;
        let filteredCompletedPatients = this.patients;
        let filteredAnonymousPatients = this.patients;
        let searchFieldText;
        [
          "surname",
          "name",
          "birthdate",
          "PatientCode",
          "physician",
          "monitoring",
        ].forEach((field) => {
          searchFieldText = this.getSearchFieldText(field).toUpperCase();
          filteredPatientsToComplete = filteredPatientsToComplete.filter((p) =>
            this.isSearchedTextInField(
              field,
              p,
              !this.isProfileComplete(p),
              searchFieldText
            )
          );
          filteredCompletedPatients = filteredCompletedPatients.filter((p) =>
            this.isSearchedTextInField(
              field,
              p,
              this.isProfileComplete(p),
              searchFieldText
            )
          );
        });
        ["surname", "PatientCode", "monitoring"].forEach((field) => {
          searchFieldText = this.getSearchFieldText(field).toUpperCase();
          filteredAnonymousPatients = filteredAnonymousPatients.filter((p) => {
            if (field === "monitoring")
              return (
                p.isAnonymous &&
                this.doesMonitoringDataContainSearchedText(p, searchFieldText)
              );
            else
              return (
                p.isAnonymous &&
                p.personalData[field] &&
                p.personalData[field].toUpperCase().match(searchFieldText)
              );
          });
        });
        this.completedPatients = filteredCompletedPatients;
        this.patientsToComplete = filteredPatientsToComplete;
        this.anonymousPatients = filteredAnonymousPatients;
      } catch (e) {
        this.error.message =
          `[${new Date().toISOString()}] ` + `Invalid input: ${e}`;
        this.completedPatients = [];
        this.patientsToComplete = [];
        this.anonymousPatients = [];
      }
    },
    compareDates(field, searchedDate) {
      const date = format(new Date(field), "dd/MM/yyyy", {
        locale: it,
      });
      return date.includes(searchedDate);
    },
    isSearchedTextInField(field, p, isProfileComplete, searchFieldText) {
      if (field === "birthdate") {
        return this.compareDates(p.personalData[field], searchFieldText);
      } else if (field == "monitoring")
        return (
          isProfileComplete &&
          (searchFieldText.length == 0 ||
            this.doesMonitoringDataContainSearchedText(p, searchFieldText))
        );
      else
        return (
          isProfileComplete &&
          p.personalData[field] &&
          p.personalData[field].length > 0 &&
          p.personalData[field].toUpperCase().match(searchFieldText)
        );
    },
    doesMonitoringDataContainSearchedText(p, searchFieldText) {
      return (
        (p.monitoringData?.monitoringProtocol &&
          p.monitoringData.monitoringProtocol
            .toUpperCase()
            .match(searchFieldText)) ||
        (p.monitoringData?.patientGroup &&
          p.monitoringData.patientGroup.toUpperCase().match(searchFieldText))
      );
    },
    getSearchFieldText(type) {
      switch (type) {
        case "surname":
          return this.surname;
        case "name":
          return this.name;
        case "birthdate":
          return this.birthdate;
        case "PatientCode":
          return this.PatientCode;
        case "physician":
          return this.physician;
        case "monitoring":
          return this.monitoring;
        default:
          return this.surname;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.has-border-bottom {
  border-bottom: 1px solid #dbdbdb;
}
.has-border-right-grey {
  border-right: 1px solid #dbdbdb;
}
.custom-font-weight {
  font-weight: 100;
}
</style>