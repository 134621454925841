<template>
  <div class="buttons is-centered">
    <router-link to="/dashboard"
      ><NavButton targetView="dashboard" />
    </router-link>
    <router-link to="/patients"
      ><NavButton targetView="patients" />
    </router-link>
    <router-link to="/accounts"
      ><NavButton
        targetView="accounts"
        v-if="this.hasRoleAdmin || this.hasRoleManager"
      />
    </router-link>
    <router-link to="/devices"><NavButton targetView="devices" /> </router-link>
    <router-link to="/physicians"
      ><NavButton targetView="physicians" v-if="false" />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavButton from "./NavButton.vue";
export default {
  data() {
    return {
      isAdmin: true,
    };
  },
  components: {
    NavButton,
  },
  computed: {
    ...mapGetters(["hasRoleAdmin", "hasRoleMonitor", "hasRoleManager"]),
  },
};
</script>

<style lang="scss" scoped>
#nav {
  padding-bottom: 5px;
}
/* vue-router automatically gives a class of a.is-active to the active route*/
a.is-active {
  display: none;
}
</style>