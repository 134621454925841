<template>
  <div>
    <div class="table-container box mb-5">
      <table class="table is-striped is-hoverable is-fullwidth">
        <TableHeading v-if="showHeader" />
        <tr
          v-for="entry in this.summary"
          :key="entry.id"
          class="clickable"
          @click="$router.push(`/measurements/${entry.id}`)"
        >
          <!-- <td>{{ entry | formatPatientName }}</td> -->
          <td colspan="2">
            <p v-if="entry.patientName">
              {{ entry.patientName }} {{ entry.patientSurname }}
            </p>
            <p v-else-if="entry.device">{{ entry.device }}</p>
            <p v-if="entry.patientCode" class="is-size-7">
              {{ entry.patientCode }}
            </p>
          </td>
          <td
            :class="colorByStatus(entry.status)"
            class="is-capitalized has-text-weight-semibold"
          >
            <p>
              {{ wordByStatus(entry.status) }}
            </p>
            <p
              v-if="statusHasBeenSetByHuman(entry.status)"
              class="has-text-weight-normal is-size-7"
            >
              <span v-if="!statusChangedToday(entry.statusChangedAt)">
                {{ entry.statusChangedAt | formatTimestampAsDateTime }}
              </span>
              <span v-else>{{
                entry.statusChangedAt | formatTimestampAsTime
              }}</span>
            </p>
            <p
              v-if="entry.scheduledCall"
              class="has-text-weight-normal is-size-7"
            >
              <i class="fas fa-phone-volume lg pt-1"></i>
              <span v-if="isCallScheduledForToday(entry.scheduledCall)">
                {{ entry.scheduledCall | formatTimestampAsTime }}
              </span>
              <span v-else>{{
                entry.scheduledCall | formatTimestampAsDate
              }}</span>
            </p>
          </td>
          <td
            :class="{
              'to-manage':
                statusIsActive(entry.status) &&
                above_date_error_threshold(
                  entry.lastMeasurementTimestamp,
                  entry.patientMonitoringProtocol
                ),
              'orange-symptom':
                statusIsActive(entry.status) &&
                !above_date_error_threshold(
                  entry.lastMeasurementTimestamp,
                  entry.patientMonitoringProtocol
                ) &&
                above_date_warning_threshold(entry.lastMeasurementTimestamp),
            }"
          >
            {{ entry.lastMeasurementTimestamp | formatTimestampAsDistance }}

            <p
              class="is-size-7"
              v-if="receivedToday(entry.lastMeasurementTimestamp)"
            >
              {{ entry.localTimestamp | formatTimestampAsTime }}
              {{ entry.deviceTimezone }}
            </p>
            <p v-else class="is-size-7">
              {{ entry.localTimestamp | formatTimestampAsDate }}
            </p>
          </td>
          <td
            :class="{
              'to-manage':
                statusIsActive(entry.status) &&
                respiratoryIssue(entry.respiratoryAnomaly),
            }"
          >
            {{ entry.respiratoryAnomaly | formatRespiratoryIssue }}
          </td>
          <td>
            {{ entry.RR | formatValueToDecimal }}
          </td>
          <td>
            {{ entry.Vt | formatValueToDecimal }}
          </td>
          <td
            :class="{
              'to-manage':
                statusIsActive(entry.status) &&
                entry.SpO2Issue &&
                protocolConsidersSaturation(entry.patientMonitoringProtocol),
            }"
          >
            {{
              entry.SpO2Issue
                ? "&lt;90%"
                : entry.SpO2 == "stable"
                ? "&gt;90%"
                : "-"
            }}
          </td>
          <td
            :class="{
              'to-manage':
                statusIsActive(entry.status) &&
                entry.temperatureIssue &&
                protocolConsidersTemperature(entry.patientMonitoringProtocol),
            }"
          >
            {{
              entry.temperatureIssue
                ? "&gt;37.5"
                : entry.temperature == "stable"
                ? "&lt;37.5"
                : "-"
            }}
          </td>
          <td
            :class="{
              'to-manage':
                statusIsActive(entry.status) &&
                protocolSupportsSymptoms(entry.patientMonitoringProtocol) &&
                entry.symptoms &&
                entry.symptoms.length > 0,
            }"
          >
            {{
              entry.symptoms
                ? entry.symptoms.filter((s) =>
                    symptom_is_not_temperature_or_saturation(s)
                  ).length > 0
                  ? $t("present")
                  : $t("none")
                : "-"
            }}
          </td>
        </tr>
      </table>
    </div>
    <error-modal
      :errorMessage="this.error.message"
      :logoutOnClose="this.error.forceLogout"
    />
  </div>
</template>

<script>
import TableHeading from "./DashboardTableHeading.vue";
import errorMixin from "@/Error/errorMixin";
import ErrorModal from "@/Error/errorModal.vue";
import measurementStatusMixin from "../shared/measurementStatusMixin";
import i18next from "i18next";
export default {
  components: {
    TableHeading,
    ErrorModal,
  },
  filters: {
    formatPatientName: function (patient) {
      if (!patient.patientName || !patient.patientSurname)
        return `${i18next.t("anonymous")} (${patient.device || "N/A"})`;
      else return `${patient.patientName} ${patient.patientSurname}`;
    },
    formatRespiratoryIssue: function (anomaly) {
      if (typeof anomaly == "undefined" || anomaly === "N/A") return "N/A";
      else
        return anomaly === "true"
          ? i18next.t("irregular")
          : i18next.t("regular");
    },
    formatValueToDecimal: function (data) {
      if (data) return parseFloat(data).toFixed(2);
      else return "N/A";
    },
  },
  props: {
    summary: {
      type: Array,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [errorMixin, measurementStatusMixin],
  methods: {
    symptom_is_not_temperature_or_saturation(symptom) {
      if (!symptom || !symptom.name) return true;
      const thisSymptom = symptom.name.toLowerCase();
      return (
        !thisSymptom.includes("body temperature") &&
        !thisSymptom.includes("saturation")
      );
    },
    respiratoryIssue(respiratoryAnomaly) {
      // anomaly based on trend, can be "true" | "false" | "N/A" (could not compute)
      return respiratoryAnomaly === "true";
    },
    isCallScheduledForToday(scheduledCall) {
      return (
        scheduledCall &&
        new Date().toDateString() === new Date(scheduledCall).toDateString()
      );
    },
    receivedToday(measurementDate) {
      return (
        new Date().toDateString() === new Date(measurementDate).toDateString()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  table-layout: fixed;
  border-collapse: collapse;
}
td {
  white-space: nowrap;
  overflow: hidden;
  // text-overflow: ellipsis;
}

.orange-symptom {
  color: $restech-orange;
}
</style>