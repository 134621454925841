const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

const userPool = new AmazonCognitoIdentity.CognitoUserPool({
  UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
});

const getAuthenticatedUser = () => userPool.getCurrentUser();

const authSuccessful = (session) => {
  console.log(
    `Cognito auth.js: User authenticated successfully. ${JSON.stringify(session.idToken.payload)}\n${JSON.stringify(
      session.idToken.getJwtToken()
    )}`
  );
  const userData = {
    name: session.idToken.payload.name,
    surname: session.idToken.payload.family_name,
    email: session.idToken.payload.email,
    role: session.idToken.payload["custom:role"],
    client: session.idToken.payload["custom:client"],
    // "idToken": session.getIdToken().getJwtToken() // TODO decidere se salvare il token o no in localStorage
  };
  return userData;
};

export default {
  signIn: (username, password) =>
    new Promise((resolve, reject) => {
      const credentials = new AmazonCognitoIdentity.AuthenticationDetails({
        Username: username,
        Password: password,
      });
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: username,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(credentials, {
        onSuccess: (session) => resolve(authSuccessful(session)),
        onFailure: (err) => reject(err.message || err),
        newPasswordRequired: (userAttributes) => {
          console.log(
            `Cognito auth.js: A user has just signed in for the first time, need to set new password for ${JSON.stringify(
              userAttributes
            )}`
          );
          resolve({
            newPasswordRequired: true,
            userAttributes: userAttributes,
            cognitoUser: cognitoUser,
          });
        },
      });
    }),

  signOut: () => {
    getAuthenticatedUser()?.signOut();
  },

  setFirstPassword: (cognitoUser, newPassword, newRequiredUserAttributes) =>
    new Promise((resolve, reject) => {
      /* This method is used to complete the NEW_PASSWORD_REQUIRED challenge on
       * users who are in a FORCE_CHANGE_PASSWORD status.
       * Pass the new password with any new user attributes to be updated.
       * User attribute keys must be of format userAttributes.<attribute_name>.*/
      console.log("Cognito auth.js: Need to set new password. New attributes: " + newRequiredUserAttributes);
      if (!newPassword || !cognitoUser) {
        console.log("Cognito auth.js: fatal error, no user in session, please log in again");
        reject("fatal error, no user in session, please log in again");
      }

      if (newRequiredUserAttributes?.email_verified)
        delete newRequiredUserAttributes.email_verified; /* the api doesn't accept this field back */

      cognitoUser.completeNewPasswordChallenge(newPassword, newRequiredUserAttributes, {
        onSuccess: (session) => resolve(authSuccessful(session, "Password updated correctly: you are now signed in!")),
        onFailure: (err) => reject(`Cognito auth.js: completeNewPasswordChallenge: ${err.message || err}`),
      });
    }),

  isAuthenticated: () => {
    const currentUser = getAuthenticatedUser();
    if (!currentUser) {
      console.log(`Cognito auth.js: No user is signed in: currentUser is ${currentUser}`);
      return false;
    } else {
      console.log(
        `Cognito auth.js: [auth.isAuthenticated] A user is currently signed in. Verifying session validity...`
      );
      currentUser.getSession((err, session) => {
        if (err) {
          console.log("Cognito auth.js: isAuthenticated error: " + err.message || JSON.stringify(err));
          return false;
        } else {
          console.log(`Cognito auth.js: Session is ${session.isValid() ? "valid" : "invalid"}!`);
          console.log(`Cognito auth.js: isAuthenticated returning ${session.isValid()}`);
          return session.isValid();
        }
      });
    }
  },

  getToken: () =>
    new Promise((resolve, reject) => {
      const currentUser = getAuthenticatedUser();
      if (!currentUser) {
        console.log(`Cognito auth.js: No user is signed in: currentUser is ${currentUser}`);
        reject("no user");
      } else {
        currentUser.getSession((err, session) => {
          if (err) {
            console.log("Cognito auth.js: session error: " + err.message || JSON.stringify(err));
            reject("session error");
          } else {
            //const idToken = localStorage.getItem("user.idToken") // get from localStorage TODO refactor
            const idToken = session.getIdToken().getJwtToken(); // get from api
            // const refreshToken = session.getRefreshToken().getToken()
            // console.log(`Cognito auth.js: [auth.getToken] Session ${session.isValid() ? "is valid" : "is not valid"}, refresh token is ${JSON.stringify(refreshToken)}`)
            if (session.isValid()) resolve(idToken);
            else reject("session not valid");
          }
        });
      }
    }),

  getAuthenticatedUsername: () => {
    console.log(
      `Cognito auth.js: Returning username: session is ${
        this.isAuthenticated() ? "valid" : "expired"
      } and username is ${this.getAuthenticatedUser().getUsername()}`
    );
    this.getAuthenticatedUser().getUsername();
  },
};
