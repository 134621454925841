var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'columns',
    _vm.isLastMeasure || _vm.isCurrentRouteForPrintHistory ? '' : 'mb-5' ]},[_c('errorModal',{attrs:{"errorMessage":_vm.errorMessage}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCurrentRouteForPrintHistory || (_vm.notes && _vm.notes.length > 0)),expression:"!isCurrentRouteForPrintHistory || (notes && notes.length > 0)"}],staticClass:"column"},[(_vm.notes && _vm.notes.length > 0)?_c('div',_vm._l((_vm.notes),function(note,idx){return _c('div',{key:idx,staticClass:"columns"},[_c('div',{class:[
            'column pl-4 has-text-left is-normal ',
            _vm.getNoteBorderClass(idx),
            { 'is-three-quarters': _vm.showNoteInputField },
            { 'is-size-6	 pt-0 pb-1': _vm.isCurrentRouteForPrintHistory } ]},[_c('p',{class:{ 'is-size-7 pt-0 pb-1': _vm.isCurrentRouteForPrintHistory }},[_vm._v(" "+_vm._s(note.account)+" ("+_vm._s(_vm._f("formatTimestampAsDateTime")(note.createdAt))+"): "+_vm._s(note.text)+" ")])]),(idx == _vm.notes.length - 1)?_c('div',{staticClass:"column has-text-right is-one-fifth"},[_c('div',{staticClass:"has-text-centered pl-4 pr-4"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showNoteInputField && !_vm.isCurrentRouteForPrintHistory),expression:"!showNoteInputField && !isCurrentRouteForPrintHistory"}],staticClass:"button is-success is-small fixed-width",on:{"click":function($event){_vm.showNoteInputField = !_vm.showNoteInputField}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.addNote"))+" ")])])]):_vm._e()])}),0):(!_vm.isCurrentRouteForPrintHistory)?_c('div',[_c('div',{staticClass:"columns"},[_c('div',{class:[
            'column p-0 pl-4 has-text-left is-normal ',
            _vm.showNoteInputField ? 'is-three-quarters top-note-border' : '' ]}),_c('div',{staticClass:"column has-text-right is-one-fifth"},[_c('div',{staticClass:"has-text-centered pl-4 pr-4"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showNoteInputField),expression:"!showNoteInputField"}],staticClass:"button is-success is-small fixed-width",on:{"click":function($event){_vm.showNoteInputField = !_vm.showNoteInputField}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.addNote"))+" ")])])])])]):_vm._e(),(_vm.showNoteInputField)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-three-quarters input-note-border"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputNote),expression:"inputNote",modifiers:{"trim":true}}],staticClass:"input",attrs:{"type":"text","placeholder":"Text input"},domProps:{"value":(_vm.inputNote)},on:{"input":function($event){if($event.target.composing){ return; }_vm.inputNote=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])])]):_vm._e(),(_vm.showNoteInputField)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-three-quarters last-note-border"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-left"},[_c('div',[_c('button',{class:[
                  _vm.awaitingResponse ? 'is-loading' : '',
                  'button is-small',
                  _vm.inputNote ? ' is-success' : 'is-light' ],attrs:{"disabled":!_vm.inputNote},on:{"click":_vm.sendNewNoteToServer}},[_c('span',[_vm._v(_vm._s(_vm.$t("buttons.save")))])])])]),_c('div',{staticClass:"column has-text-right"},[_c('div',[_c('button',{staticClass:"button is-success is-small",on:{"click":function($event){_vm.showNoteInputField = !_vm.showNoteInputField}}},[_c('span',[_vm._v(_vm._s(_vm.$t("buttons.cancel")))])])])])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }