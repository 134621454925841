<template>
  <div class="has-background-grey-light p-5">
    <Loader v-if="loading" />
    <errorModal
      :errorMessage="error.message"
      :logoutOnClose="error.forceLogout"
    />
    <div v-if="this.summaryCopy.length > 0 && !loading" class="columns">
      <div class="box column chart m-3">
        <dashboard-chart-patients :patientsData="summaryCopy" />
      </div>
      <div class="box column chart m-3">
        <dashboard-chart-measurements :todaysData="summaryCopy" />
      </div>
    </div>
    <div v-if="this.summaryCopy.length > 0 && !loading" class="columns">
      <div class="column">
        <div class="control has-icons-right">
          <input
            v-on:input="filterDataBySearchedPatient($event.target.value)"
            class="input is-small"
            type="text"
            :placeholder="$t('patientSearch')"
          />
          <span class="icon is-small is-right">
            <i class="fas fa-search"></i>
          </span>
        </div>
      </div>
      <div class="column"></div>
      <div class="column"></div>
    </div>
    <div v-if="noContent && !loading">
      {{ $t("warnings.noPatientOrMeasure") }}
    </div>
    <DashboardTable
      v-if="patientsToManage.length > 0"
      :summary="patientsToManage"
      :show-header="headerLocation === 0"
    />
    <DashboardTable
      v-if="patientsNotToManage.length > 0"
      :summary="patientsNotToManage"
      :show-header="headerLocation === 1"
    />
    <DashboardTable
      v-if="patientsAlreadyManaged.length > 0"
      :summary="patientsAlreadyManaged"
      :show-header="headerLocation === 2"
    />
    <DashboardTable
      v-if="inactivePatients.length > 0"
      :summary="inactivePatients"
      :show-header="headerLocation === 3"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import api from "../API/api";
import errorModal from "@/Error/errorModal.vue";
import errorMixin from "@/Error/errorMixin";
import measurementStatusMixin from "@/shared/measurementStatusMixin";
import Loader from "@/shared/Loader.vue";
import DashboardTable from "./DashboardTable.vue";
import DashboardChartPatients from "./DashboardChartPatients.vue";
import DashboardChartMeasurements from "./DashboardChartMeasurements.vue";
export default {
  components: {
    errorModal,
    DashboardTable,
    DashboardChartPatients,
    DashboardChartMeasurements,
    Loader,
  },
  mixins: [errorMixin, measurementStatusMixin],
  data() {
    return {
      summary: [],
      loading: false,
      summaryCopy: [],
    };
  },
  async created() {
    try {
      this.loading = true;
      this.summary = await api.getSummary();
      this.summary = this.summary.map((m) => this.verifyNewPatients(m));
      this.summary = this.summary.map((m) => this.hideCallADoctor(m));
      this.summary = this.summary.map((m) =>
        this.verifyForPatientCompliance(m, m.patientMonitoringProtocol)
      );
      this.summary.sort(function (a, b) {
        return (
          new Date(b.lastMeasurementTimestamp) -
          new Date(a.lastMeasurementTimestamp)
        );
      });
      this.summary = this.summary.map((m) => this.verifyForScheduledCalls(m));
      this.summary = this.summary.map((m) => this.parseSymptoms(m));
      this.summaryCopy = cloneDeep(this.summary);
    } catch (e) {
      this.error.message = this.parseError(e);
      if (e && e.includes && e.includes("login")) {
        this.error.forceLogout = true;
        this.$store.dispatch("logout");
      }
    } finally {
      this.loading = false;
    }
  },
  computed: {
    noContent() {
      return this.headerLocation == -1;
    },
    patientsToManage() {
      return this.summary.filter(
        (entry) =>
          entry.status === "to-manage" || entry.status === "action-plan-review"
      );
    },
    patientsNotToManage() {
      return this.summary.filter(
        (entry) =>
          entry.status === "under-surveillance" || entry.status === "monitoring"
      );
    },
    patientsAlreadyManaged() {
      return this.summary.filter(
        (entry) => entry.status == "managed" || entry.status == "reported"
      );
    },
    inactivePatients() {
      return this.summary.filter((entry) =>
        this.statusIsInactive(entry.status)
      );
    },
    headerLocation() {
      if (this.patientsToManage.length > 0) return 0;
      else if (this.patientsNotToManage.length > 0) return 1;
      else if (this.patientsAlreadyManaged.length > 0) return 2;
      else if (this.inactivePatients.length > 0) return 3;
      else return -1;
    },
  },
  methods: {
    parseSymptoms(m) {
      // consider only significative symptoms
      if (m.patientMonitoringProtocol == "Pianoforte" && m.symptoms) {
        const isActionPlanLevel = (q) => q.name && q.name.includes("Level");
        const isBluePuffsNumber = (q) =>
          q.name && q.name.length && q.name.length == 1;
        const isSecondaryQuestion = (q) =>
          [
            "0 - Did not wake up because of my asthma symptoms",
            "1 - Woke up but did not need my blue puffer",
            "2 - Woke up and needed my blue puffer at least once",
          ].includes(q.name);
        if (m.symptoms)
          m.symptoms = m.symptoms
            .filter(
              (q) =>
                !isActionPlanLevel(q) &&
                !isBluePuffsNumber(q) &&
                !isSecondaryQuestion(q)
            )
            .filter(
              (q) => q.name && typeof q.name == "string" && +q.name[0] > 0
            );
      }
      return m;
    },
    verifyForScheduledCalls(m) {
      const callScheduledForToday =
        new Date(m.scheduledCall).toDateString() == new Date().toDateString();
      if (callScheduledForToday) m.status = "to-manage";
      return m;
    },
    filterDataBySearchedPatient(patientToSearch) {
      this.summary = this.summaryCopy.filter((patient) => {
        const patientSurname = patient.patientSurname
          ? patient.patientSurname
          : "";
        const patientName = patient.patientName ? patient.patientName : "";
        const deviceCode = patient.device ? patient.device : "";
        const patientCode = patient.patientCode ? patient.patientCode : "";

        let patientData = `${deviceCode}${patientName}${patientSurname}${patientCode}`;
        patientToSearch = patientToSearch.replace(/\s/g, "");
        patientData = patientData.replace(/\s/g, "");
        return patientData.toUpperCase().match(patientToSearch.toUpperCase());
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 170px;
}
</style>