<template>
  <form
    @submit.prevent="modifyPatient(patientId, doctorDataToSend)"
    :class="this.errors ? 'errors' : false"
  >
    <Loader v-if="!loadedPhysicians" />
    <div class="columns">
      <div class="column">
        <div id="doctorsList" v-if="false" class="field">
          <label v-if="loadedPhysicians" class="label has-text-left">{{
            $t("fieldsDescription.selectPhysician")
          }}</label>
          <div v-if="loadedPhysicians" class="select is-fullwidth">
            <select @invalid="invalidateForm" v-model="doctorDataCopy">
              <option default :value="{}">
                {{ $t("fieldsDescription.addPhysician") }}
              </option>
              <option
                v-for="doctor in doctorsToShowInSelect"
                :key="doctor.id"
                :value="{
                  id: doctor.id,
                  name: doctor.name,
                  surname: doctor.surname,
                  email: doctor.email,
                  phone: doctor.phone,
                }"
              >
                {{ doctor.surname }} {{ doctor.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label v-if="loadedPhysicians" class="label has-text-left">{{
                $t("fieldsDescription.physicianName")
              }}</label>
              <div v-if="loadedPhysicians" class="control">
                <input
                  @invalid="invalidateForm"
                  class="input"
                  type="text"
                  v-model.trim="doctorDataCopy.name"
                  :placeholder="$t('fieldsDescription.name')"
                  :disabled="doctorFormDisabled"
                  required
                />
              </div>
            </div>
            <div class="field">
              <label v-if="loadedPhysicians" class="label has-text-left">{{
                $t("fieldsDescription.physicianPhone")
              }}</label>
              <div v-if="loadedPhysicians" class="control">
                <input
                  @invalid="invalidateForm"
                  class="input"
                  type="text"
                  v-model.trim="doctorDataCopy.phone"
                  :placeholder="$t('fieldsDescription.number')"
                  :disabled="doctorFormDisabled"
                  required
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label v-if="loadedPhysicians" class="label has-text-left">{{
                $t("fieldsDescription.physicianSurname")
              }}</label>
              <div v-if="loadedPhysicians" class="control">
                <input
                  @invalid="invalidateForm"
                  class="input"
                  type="text"
                  v-model.trim="doctorDataCopy.surname"
                  :placeholder="$t('fieldsDescription.surname')"
                  :disabled="doctorFormDisabled"
                  required
                />
              </div>
            </div>
            <div class="field">
              <label v-if="loadedPhysicians" class="label has-text-left">{{
                $t("fieldsDescription.physicianEmail")
              }}</label>
              <div v-if="loadedPhysicians" class="control">
                <input
                  @invalid="invalidateForm"
                  class="input"
                  type="email"
                  v-model="doctorDataCopy.email"
                  :placeholder="$t('fieldsDescription.email')"
                  :disabled="doctorFormDisabled"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <PatientSaveButton
      :hasDataBeenDownloaded="loadedPhysicians"
      :awaitingResponse="awaitingResponse"
      :haveAllFieldsBeenFilledIn="this.haveAllFieldsBeenFilledIn()"
      :isDataEqualToTheSavedOnSubmit="this.isDataEqualToTheSavedOnSubmit()"
    />
  </form>
</template>
<script>
import Loader from "@/shared/Loader.vue";
import savePatientMixin from "./savePatientMixin.js";
import { cloneDeep } from "lodash";
import PatientSaveButton from "./PatientSaveButton";
// import api from "../API/api";

const emptyDoctorData = {
  id: "",
  name: "",
  surname: "",
  email: "",
  phone: "",
};

export default {
  props: ["patientToModify"],
  components: { Loader, PatientSaveButton },
  mixins: [savePatientMixin],
  data() {
    return {
      patientId: this.patientToModify.PatientID,
      errors: false,
      awaitingResponse: false,
      isDataSaved: false,
      doctorsToShowInSelect: [],
      errorMsg: null,
      doctorDataCopy: this.patientToModify.doctorData
        ? cloneDeep(this.patientToModify.doctorData)
        : emptyDoctorData,
      selectedDoctor: {},
      loadedPhysicians: false,
    };
  },
  computed: {
    doctorDataToSend() {
      return { doctorData: this.doctorDataCopy };
    },
    doctorFormDisabled() {
      return false; //doctorDataCopy.id
    },
  },
  methods: {
    invalidateForm() {
      this.errors = true;
    },
    haveAllFieldsBeenFilledIn() {
      return (
        this.doctorDataCopy.name &&
        this.doctorDataCopy.surname &&
        this.doctorDataCopy.phone &&
        this.doctorDataCopy.email
      );
    },
    isDataEqualToTheSavedOnSubmit() {
      return (
        JSON.stringify(this.patientToModify.doctorData) ===
        JSON.stringify(this.doctorDataCopy)
      );
    },
  },
  async created() {
    // this.doctorsToShowInSelect = await api.getPhysicians().catch((e) => {
    //   if (this.error) this.error.message = `[${new Date().toISOString()}] ` + e;
    //   else throw new Error(e);
    //   alert("error downloading doctors: " + e);
    // });
    this.loadedPhysicians = true;
  },
};
</script>
<style>
</style>