<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p
          v-if="!patientsHaveBeenDeleted"
          class="modal-card-title has-text-danger"
        >
          {{
            $t("confirmToDelteProfile", {
              count: this.patientsToDelete.length,
            })
          }}
        </p>
        <p
          v-else-if="this.arePatientsDeletetedCorrectly()"
          class="modal-card-title has-text-success"
        >
          {{ $t("deletePatientsSuccess") }}
        </p>
        <p v-else class="modal-card-title has-text-danger">
          {{ $t("error") }}
        </p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <div :key="patientId" v-for="(patientId, idx) in patientsToDelete">
          <div
            :class="[
              'columns',
              idx === patientsToDelete.length - 1 ? '' : 'has-border-bottom',
            ]"
          >
            <div class="column">
              {{ getPatientSurname(patientId) }}
              {{ getPatientName(patientId) }}
            </div>
            <div class="column">{{ getPatientCode(patientId) }}</div>
          </div>
        </div>
        <div v-if="this.impossibleToDeletePatients()">
          <h1>{{ $t("patientsWereNotDeleted") }}:</h1>
          <br />
          <div
            :key="patientId"
            v-for="(patientId, idx) in patientsImpossibleToDelete"
          >
            <div
              :class="[
                'columns',
                idx === patientsToDelete.length - 1 ? '' : 'has-border-bottom',
              ]"
            >
              <div class="column">
                {{ getPatientSurname(patientId) }}
                {{ getPatientName(patientId) }}
              </div>
              <div class="column">{{ getPatientCode(patientId) }}</div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot no-flex">
        <p v-if="!patientsHaveBeenDeleted">
          {{ $t("deletePatientsWarning") }}
        </p>
        <br />
        <div v-if="!patientsHaveBeenDeleted" class="columns">
          <div class="column">
            <button
              @click="closeModal"
              :class="['button is-success has-text-right']"
            >
              {{ $t("buttons.cancel") }}
            </button>
          </div>
          <div class="column">
            <button
              @click="deletePatients"
              :class="[
                'button is-danger has-text-right',
                { 'is-loading': awaitingResponse },
              ]"
            >
              {{ $t("buttons.delete") }}
            </button>
          </div>
        </div>
        <button
          v-else
          @click="closeModal"
          class="button is-success has-text-right"
        >
          {{ $t("buttons.close") }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from "@/API/api.js";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    patientsToDelete: {
      type: Array,
    },
    patients: {
      type: Array,
    },
  },
  data() {
    return {
      patientsHaveBeenDeleted: false,
      patientsImpossibleToDelete: [],
      awaitingResponse: false,
    };
  },
  methods: {
    arePatientsDeletetedCorrectly() {
      return (
        this.patientsHaveBeenDeleted &&
        this.patientsImpossibleToDelete.length == 0
      );
    },
    impossibleToDeletePatients() {
      return (
        this.patientsHaveBeenDeleted &&
        this.patientsImpossibleToDelete.length != 0
      );
    },
    closeModal() {
      this.$emit("handleCloseModal");
      this.patientsHaveBeenDeleted = false;
      this.awaitingResponse = false;
    },
    deletePatients() {
      let patientsDeleted = [];
      let patientsDeletedError = [];
      this.awaitingResponse = true;
      this.patientsToDelete.forEach(async (patient) => {
        await api
          .deletePatient(patient)
          .then((res) => {
            if (res.patientDeleted == patient) patientsDeleted.push(patient);
          })
          .catch(() => patientsDeletedError.push(patient))
          .finally(() => {
            const totalApiResponse =
              patientsDeleted.length + patientsDeletedError.length;
            if (totalApiResponse == this.patientsToDelete.length) {
              this.$emit("handleDeletePatients", patientsDeleted);
              this.showSecondStep(patientsDeletedError);
            }
          });
      });
    },
    showSecondStep(patientsDeletedError) {
      this.patientsImpossibleToDelete = patientsDeletedError;
      this.patientsHaveBeenDeleted = true;
      this.awaitingResponse = false;
    },
    getPatientName(patientId) {
      let patientName;
      this.patients.forEach((p) => {
        if (p.PatientID === patientId) patientName = p.personalData.name;
      });
      return patientName ? patientName.toUpperCase() : "";
    },
    getPatientSurname(patientId) {
      let patientSurname;
      this.patients.forEach((p) => {
        if (p.PatientID === patientId) patientSurname = p.personalData.surname;
      });
      return patientSurname ? patientSurname.toUpperCase() : "";
    },
    getPatientCode(patientId) {
      let patientCode;
      this.patients.forEach((p) => {
        if (p.PatientID === patientId) patientCode = p.personalData.PatientCode;
      });
      return patientCode ? patientCode.toUpperCase() : "";
    },
  },
};
</script>
<style style="scss" scoped>
.no-flex {
  display: block;
}
.has-border-bottom {
  border-bottom: 1px solid #dbdbdb;
}
</style>