<template>
  <thead>
    <tr>
      <th colspan="2">{{ $t("patient") }}</th>
      <th>{{ $t("columns.state") }}</th>
      <th>{{ $t("columns.measurementDate") }}</th>
      <th>{{ $t("columns.respiratoryFunction") }}</th>
      <th>{{ $t("columns.rrBpm") }}</th>
      <th>{{ $t("columns.vtL") }}</th>
      <th>SpO<sub>2</sub> (%)</th>
      <th>T (°C)</th>
      <th>{{ $t("columns.symptoms") }}</th>
    </tr>
  </thead>
</template>

<script>
export default {
  data() {
    return {
      patientToSearch: "",
    };
  },
  watch: {
    patientToSearch() {
      this.$emit("handleSearchedPatient", this.patientToSearch);
    },
  },
};
</script>

<style lang="scss" scoped>
th {
  border-right: 1px solid $restech-grey;
  color: red;
}
</style>