<template>
  <div>
    <!-- 
            validity: 
                classe is-danger applicata a #input-email
                icona da-exclamation-triangle in luogo di fa-check per #email-icon
                elimina classe hidden da #invalid-email-msg
        -->
    <form
      @submit.prevent="login"
      autocomplete="off"
      :class="this.formError ? 'errors' : false"
    >
      <div class="field" id="login-email">
        <div class="control has-icons-left has-icons-right">
          <input
            @invalid="invalidateForm"
            v-model.trim="credentials.email"
            id="input-email"
            class="input is-primary"
            type="email"
            :placeholder="$t('fieldsDescription.email')"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
          <span class="icon is-small is-right">
            <i id="email-icon" class="fas fa-check"></i>
          </span>
          <p id="invalid-email-msg" class="help is-danger hidden">
            This email is invalid
          </p>
        </div>
      </div>

      <div class="field">
        <div class="control has-icons-left">
          <input
            v-model.trim="credentials.password"
            class="input is-primary"
            type="password"
            :placeholder="$t('password')"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
          <p
            id="invalid-credentials-msg"
            class="help is-danger"
            v-show="formError"
          >
            {{ humanReadableError }}
          </p>
        </div>
      </div>
      <div class="field">
        <p class="control">
          <button
            @click="emailIsValid ? (loading = true) : false"
            id="login-btn"
            type="submit"
            class="button is-primary is-fullwidth"
            :class="{ 'is-loading': this.loading }"
            :disabled="!emailIsValid"
          >
            Log in
          </button>
        </p>
      </div>
    </form>
    <errorModal
      :errorMessage="error.message"
      :logoutOnClose="error.forceLogout"
    />
  </div>
</template>

<script>
import api from "@/API/api";
import errorMixin from "@/Error/errorMixin.js";
import errorModal from "@/Error/errorModal.vue";
export default {
  mixins: [errorMixin],
  components: {
    errorModal,
  },
  data() {
    return {
      credentials: {
        email: null,
        password: null,
      },
      formError: false,
      loading: false,
    };
  },
  computed: {
    emailIsValid() {
      //return /\S+@\S+\.\S+/.test(this.credentials.email);
      return /\S+@\S/.test(this.credentials.email);
    },
    getCredentials() {
      return {
        username: this.credentials.email,
        password: this.credentials.password,
      };
    },
    humanReadableError() {
      return this.formError && !this.emptyObject(this.formError)
        ? this.formError
        : "An unknown error occurred. Please try again.";
    },
  },
  methods: {
    invalidateForm() {
      this.formError = "Please enter a valid email address";
    },
    async login() {
      try {
        this.formError = undefined;
        const loginStatus = await this.$store.dispatch(
          "login",
          this.getCredentials
        );
        if (loginStatus == "role-is-undefined") {
          this.error.message =
            "Il tuo profilo non è completo. Per favore contattare un amministratore.";
          this.error.forceLogout = true;
        } else {
          if (loginStatus == "logged-in") {
            const loginResponse = await api.createLoginTrail({
              username: this.getCredentials.username,
              status: loginStatus,
            });
            if (loginResponse.preferences) {
              console.log(
                `User preferred language is: ${loginResponse.preferences.lang}. Saved to localStorage.`
              );
              localStorage.setItem("lang", loginResponse.preferences.lang);
            }
          }
          this.redirectTo(loginStatus);
        }
      } catch (e) {
        this.formError = e;
      } finally {
        this.loading = false;
      }
    },
    redirectTo(loginStatus) {
      let nextView = "login";
      switch (loginStatus) {
        case "new-password-required":
          nextView = "set-password";
          break;
        case "logged-in":
          // take user to where he was browsing, default to Dashboard
          nextView = this.$route.query.redirect || "dashboard";
          break;
      }

      this.$router.replace(nextView);
    },
    emptyObject(o) {
      return typeof o == "object" && Object.keys(o).length == 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
input {
  width: 100%;
}
#login-btn {
  margin-top: 70px;
}
</style>