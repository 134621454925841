<template>
  <table class="table is-fullwidth is-fixed">
    <thead>
      <th class="has-text-center">{{ $t("fieldsDescription.surname") }}</th>
      <th class="has-text-center">{{ $t("fieldsDescription.name") }}</th>
      <th class="has-text-center">{{ $t("fieldsDescription.email") }}</th>
      <th class="has-text-center">
        {{ $t("fieldsDescription.monitoringCenter") }}
      </th>
    </thead>
    <tbody>
      <tr v-for="(account, idxAccount) in this.accounts" :key="idxAccount">
        <td class="is-uppercase is-clipped">
          {{ account.surname }}
        </td>
        <td class="is-uppercase is-clipped">
          {{ account.name }}
        </td>
        <td class="is-lowercase is-clipped">
          {{ account.email }}
        </td>
        <td class="is-clipped">
          <ul
            v-for="(
              monitoringCenter, idxMonitoringCenter
            ) in account.monitoringCenters"
            class="is-clipped"
            :key="idxMonitoringCenter"
          >
            <li>{{ monitoringCenter }}</li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: ["accounts"],
};
</script>
