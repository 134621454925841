<template>
  <div>
    <ul class="steps my-step-style has-content-centered">
      <li
        v-for="(step, idx) in stepsName"
        :class="['steps-segment']"
        :key="idx"
      >
        <div>
          <span
            :class="['steps-marker-custom', getStepClass(idx), 'clickable']"
            @click="
              patient.PatientID ? $emit('handleStepCircleClick', idx) : ''
            "
          ></span>
        </div>
        <div
          class="steps-content-custom clickable"
          @click="patient.PatientID ? $emit('handleStepCircleClick', idx) : ''"
        >
          <p
            :class="['is-size-6', currentStep == idx ? getTextClass(idx) : '']"
          >
            {{ $t(step) }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  emits: ["handleStepCircleClick"],
  props: ["currentStep", "patient"],
  data() {
    return {
      stepsName: [
        "stepsName.personalData",
        "stepsName.caregiverData",
        "stepsName.physicianData",
        "stepsName.clinicalData",
        "stepsName.monitoringData",
        "stepsName.summary",
      ],
    };
  },
  methods: {
    getStepClass(idx) {
      if (!this.patient.PatientID && idx != 0) return "step-is-not-clickable";
      else if (this.isPatientStepSaved(idx)) return "step-is-saved";
      else return "step-is-to-complete";
    },
    getTextClass(idx) {
      if (this.isPatientStepSaved(idx)) return "current-step-is-complete";
      else return "current-step-is-to-complete";
    },
    //TODO: cambiare il controllo dei campi nel caso in
    //      non siano più tutti impostato come required
    isPatientStepSaved(idx) {
      switch (idx) {
        case 0:
          return this.patient.personalData && this.patient.personalData.name;
        case 1:
          return (
            this.patient.caregiverData &&
            (this.patient.caregiverData.name ||
              this.patient.caregiverData.disabled)
          );
        case 2:
          return this.patient.doctorData && this.patient.doctorData.name;
        case 3:
          return (
            this.patient.clinicalData &&
            this.patient.clinicalData.visit &&
            this.patient.clinicalData.visit.height > 0
          );
        case 4:
          return (
            this.patient.monitoringData && this.patient.monitoringData.device
          );
        case 5:
          return (
            this.patient.personalData &&
            this.patient.personalData.name &&
            this.patient.caregiverData &&
            (this.patient.caregiverData.name ||
              this.patient.caregiverData.disabled) &&
            this.patient.doctorData &&
            this.patient.doctorData.name &&
            this.patient.clinicalData &&
            this.patient.clinicalData.visit &&
            this.patient.clinicalData.visit.height > 0 &&
            this.patient.monitoringData &&
            this.patient.monitoringData.device
          );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.step-is-to-complete {
  background-color: $restech-orange;
  color: $restech-white;
}
.step-is-saved {
  background-color: $restech-green-website;
  color: $restech-white;
}
.step-is-not-clickable {
  background-color: $restech-grey;
  color: $restech-white;
}
.steps .steps-marker-custom {
  align-items: center;
  display: flex;
  border-radius: 50%;
  font-weight: 700;
  justify-content: center;
  position: relative;
  z-index: 10;
  height: 2rem;
  width: 2rem;
}

.steps-content-custom {
  margin-top: 35px;
}

.steps:not(.is-hollow)
  .steps-segment.is-active
  .steps-marker-custom:not(.is-hollow)
  .steps-segment.is-active:after,
.steps-segment:after {
  background-color: #dbdbdb;
}

.steps:not(.is-horizontal) .steps-segment:not(:last-child) {
  min-height: 4em;
}

@media screen and (min-width: 769px) {
  .steps:not(.is-vertical).has-content-centered .steps-marker-custom {
    position: absolute;
    left: calc(50% - 1rem);
  }
}
.current-step-is-complete {
  color: $restech-green-website;
  font-weight: bolder;
}
.current-step-is-to-complete {
  color: $restech-orange;
  font-weight: bolder;
}
</style>