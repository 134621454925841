export default {
  data() {
    return {
      comorbiditiesDictionary: {
        "heart failure": "comorbidities.heartFailure",
        "ischemic heart disease": "comorbidities.ischemicHeartDisease",
        "atrial fibrillation": "comorbidities.atrialFibrillation",
        "high blood pressure": "comorbidities.highBloodPressure",
        dementia: "comorbidities.dementia",
        COPD: "comorbidities.COPD",
        "chronic asthma": "comorbidities.chronicAsthma",
        "allergic asthma": "comorbidities.allergicAsthma",
        "sleep-related disorder": "comorbidities.sleepRelatedDisorder",
        osteoporosis: "comorbidities.sleepRelatedDisorder",
        hyperlipidemia: "comorbidities.hyperlipidemia",
        diabetes: "comorbidities.diabetes",
      },
    };
  },
  methods: {
    dataHaveComorbidities(data) {
      return (
        data.clinicalData?.spirometricTest?.comorbidities &&
        data.clinicalData?.spirometricTest?.comorbidities.length > 0
      );
    },
    dataHaveCustomComorbidities(data) {
      return (
        data.clinicalData?.spirometricTest?.customComorbidities &&
        data.clinicalData?.spirometricTest?.customComorbidities.length > 0
      );
    },
    getComordityDictionaryString(comorbidity) {
      return this.comorbiditiesDictionary[comorbidity]
        ? this.comorbiditiesDictionary[comorbidity]
        : comorbidity;
    },
    getAllComorbidities(data) {
      if (
        this.dataHaveComorbidities(data) &&
        this.dataHaveCustomComorbidities(data)
      ) {
        return data.clinicalData?.spirometricTest.comorbidities.concat(
          data.clinicalData.spirometricTest.customComorbidities
        );
      } else if (
        this.dataHaveComorbidities(data) &&
        !this.dataHaveCustomComorbidities(data)
      ) {
        return data.clinicalData?.spirometricTest?.comorbidities;
      } else if (
        !this.dataHaveComorbidities(data) &&
        this.dataHaveCustomComorbidities(data)
      )
        return data.clinicalData?.spirometricTest?.customComorbidities;
      return [];
    },
  },
};
