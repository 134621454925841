<template>
  <div class="has-background-grey-light p-1">
    <div>
      <errorModal
        :errorMessage="error.message"
        :logoutOnClose="error.forceLogout"
      />
      <div class="box columns p-1">
        <div class="column">
          <PatientRecap
            :patient="patient"
            :measurementsPoints="measurementsPointsToPlot"
            :measurements="measurements"
            :isCurrentRouteForPrintHistory="true"
          />
        </div>
      </div>
      <div class="box remove-box-shadow columns p-1">
        <div class="column">
          <PatientMeasurementChart
            :measurementsPoints="measurementsPointsToPlot"
            :isCurrentRouteForPrintHistory="true"
            :customImpedanceRange="customImpedanceRange"
            :customTimeSeriesRange="customTimeSeriesRange"
          />
        </div>
      </div>
      <div class="page-break"></div>
      <div class="box columns p-1">
        <div class="column">
          <PatientMeasurements
            :measurements="measurementsCopy"
            :patientID="patientID"
            :isCurrentRouteForPrintHistory="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PatientMeasurementChart from "./PatientMeasurementChart.vue";
import PatientMeasurements from "./PatientMeasurements.vue";
import PatientRecap from "./PatientRecap.vue";
import errorModal from "../Error/errorModal";
import errorMixin from "../Error/errorMixin";
import { cloneDeep } from "lodash";
export default {
  data() {
    return {
      measurementsCopy: cloneDeep(this.measurements),
    };
  },
  components: {
    errorModal,
    PatientMeasurementChart,
    PatientMeasurements,
    PatientRecap,
  },
  mixins: [errorMixin],
  props: [
    "measurements",
    "patient",
    "patientID",
    "measurementsPointsToPlot",
    "customImpedanceRange",
    "customTimeSeriesRange",
  ],
};
</script>


<style lang="scss" scoped>
.remove-box-shadow {
  box-shadow: none !important;
}
@page {
  margin: 0;
}
@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>