<template>
  <p class="control">
    <button class="button is-medium m-1">
      <span class="icon is-medium">
        <i :class="[selectedView.icon]"></i>
      </span>
      <span class="is-size-6">{{ $t(selectedView.text) }}</span>
    </button>
  </p>
</template>
<script>
export default {
  props: {
    targetView: {
      type: String,
      default: "dashboard",
    },
  },
  data() {
    return {
      views: [
        {
          name: "dashboard",
          text: "dashboard",
          icon: "fas fa-home",
        },
        {
          name: "patients",
          text: "patient_plural",
          icon: "far fa-address-book",
        },
        {
          name: "accounts",
          text: "operator_plural",
          icon: "fas fa-user",
        },
        {
          name: "devices",
          text: "device_plural",
          icon: "fas fa-laptop",
        },
        {
          name: "physicians",
          text: "physician_plural",
          icon: "fas fa-user-md",
        },
      ],
    };
  },
  computed: {
    selectedView() {
      return this.views.filter((v) => v.name == this.targetView)[0];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>