<template>
  <div>
    <errorModal
      :errorMessage="error.message"
      :logoutOnClose="error.forceLogout"
    />
  </div>
</template>

<script>
import errorModal from "@/Error/errorModal.vue";
import errorMixin from "@/Error/errorMixin";
export default {
  components: {
    errorModal,
  },
  mixins: [errorMixin],
  mounted() {
    this.error.forceLogout = true;
    this.error.message =
      new Date().toISOString() + this.$i18n.i18next.t("warnings.pageNotFound");
  },
};
</script>

<style lang="scss" scoped>
</style>
