<template>
  <div>
    <form
      @submit.prevent="modifyPatient(patientId, monitoringDataToSend)"
      :class="this.errors ? 'errors' : false"
    >
      <Loader v-if="!completedLoadingDataFromServer" />
      <div class="columns">
        <div class="column"></div>
        <div
          class="column is-three-quarters"
          v-show="completedLoadingDataFromServer"
        >
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.pairedDevice")
            }}</label>

            <div class="control has-icons-right">
              <input
                v-model="monitoringDataCopy.device"
                :class="[
                  'input',
                  { 'is-success': this.isDeviceIdValid() },
                  { 'is-danger': this.isDeviceIdNotAvailable() },
                ]"
                required
              />
              <span
                v-show="this.isDeviceIdValid()"
                class="icon is-small is-right"
              >
                <i class="fas fa-check"></i>
              </span>
            </div>

            <p
              v-show="!this.isDeviceIdValid() && !this.isDeviceIdNotAvailable()"
            >
              {{ $t("warnings.noDeviceWithId") }}
            </p>
            <p v-show="this.isDeviceIdNotAvailable()">
              {{ $t("warnings.deviceWithIdIsAlredyInUse") }}
            </p>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.monitoringCenter")
            }}</label>
            <div class="select is-fullwidth">
              <select
                v-model="monitoringDataCopy.patientGroup"
                @invalid="invalidateForm"
                required
              >
                <option
                  v-for="(group, index) in monitoringGroups"
                  :key="`center-${index}`"
                >
                  {{ group.GroupName }}
                </option>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.monitoringProtocol")
            }}</label>
            <div class="select is-fullwidth">
              <select
                v-model="monitoringDataCopy.monitoringProtocol"
                @invalid="invalidateForm"
                required
              >
                <option
                  v-for="monitoringProtocol in this.monitoringProtocols"
                  :key="monitoringProtocol.name"
                  :value="monitoringProtocol.name"
                >
                  {{ monitoringProtocol.name }}
                  ({{
                    $t(
                      `monitoringProtocols.${monitoringProtocol.description}`
                    ).trim()
                  }})
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column"></div>
      </div>
      <br />
      <br />
      <PatientSaveButton
        :hasDataBeenDownloaded="completedLoadingDataFromServer"
        :awaitingResponse="awaitingResponse"
        :haveAllFieldsBeenFilledIn="this.haveAllFieldsBeenFilledIn()"
        :isDataEqualToTheSavedOnSubmit="this.isDataEqualToTheSavedOnSubmit()"
      />
    </form>
  </div>
</template>
<script>
import Loader from "@/shared/Loader.vue";
import savePatientMixin from "./savePatientMixin.js";
import cloneDeep from "lodash/cloneDeep";
import PatientSaveButton from "./PatientSaveButton";
import api from "../API/api";

const emptyMonitoringData = {
  monitoringProtocol: "",
  patientGroup: "",
  device: "",
};

export default {
  props: ["patientToModify"],
  components: { Loader, PatientSaveButton },
  mixins: [savePatientMixin],
  data() {
    return {
      patientId: this.patientToModify.PatientID,
      awaitingResponse: false,
      showTextarea: false,
      errors: false,
      availableDevicesId: [],
      notAvailableDevicesId: [],
      deviceId: "",
      monitoringProtocols: [],
      monitoringGroups: [],
      completedLoadingDataFromServer: false,
      parameters: [
        [
          "Impedenza (trend)",
          "Impedenza (variabilità)",
          "Frequenza respiratoria",
        ],
        ["Saturazione", "Temperatura corporea", "Sintomi"],
      ],
      monitoringDataCopy: this.patientToModify.monitoringData
        ? cloneDeep(this.patientToModify.monitoringData)
        : emptyMonitoringData,
    };
  },
  computed: {
    monitoringDataToSend() {
      return { monitoringData: this.monitoringDataCopy };
    },
  },
  methods: {
    isDeviceIdEqualsToSaved() {
      return (
        this.patientToModify &&
        this.monitoringDataCopy &&
        this.patientToModify.monitoringData &&
        this.monitoringDataCopy.device &&
        this.patientToModify.monitoringData.device ===
          this.monitoringDataCopy.device
      );
    },
    isDeviceIdValid() {
      return (
        this.patientToModify &&
        this.monitoringDataCopy &&
        (this.availableDevicesId.includes(this.monitoringDataCopy.device) ||
          this.isDeviceIdEqualsToSaved())
      );
    },
    isDeviceIdNotAvailable() {
      return (
        this.notAvailableDevicesId.includes(this.monitoringDataCopy.device) &&
        !this.isDeviceIdEqualsToSaved()
      );
    },
    invalidateForm() {
      this.errors = true;
    },
    haveAllFieldsBeenFilledIn() {
      return (
        this.monitoringDataCopy.patientGroup &&
        this.monitoringDataCopy.device &&
        this.monitoringDataCopy.monitoringProtocol &&
        this.isDeviceIdValid()
      );
    },
    isDataEqualToTheSavedOnSubmit() {
      return (
        JSON.stringify(this.patientToModify.monitoringData) ===
        JSON.stringify(this.monitoringDataCopy)
      );
    },
    setDevicesId(devicesInformation) {
      if (
        devicesInformation &&
        devicesInformation.length &&
        devicesInformation.length > 0
      ) {
        devicesInformation.forEach((d) => {
          if (d.isAvailable) this.availableDevicesId.push(d.SerialNumber);
          else this.notAvailableDevicesId.push(d.SerialNumber);
        });
      }
    },
  },
  async created() {
    try {
      this.monitoringGroups = await api.getMonitoringCenters();
      const devicesInformation = await api.getAllDevices();
      this.setDevicesId(devicesInformation);
      this.monitoringProtocols = await api.getMonitoringProtocols();
      this.completedLoadingDataFromServer = true;
    } catch (e) {
      this.$emit("errorOnSaveHandler", e);
    }
  },
};
</script>
<style>
</style>