<template>
  <div>
    <div
      v-if="isCallScheduled && !isInputShowed"
      class="scheduled-call-container"
    >
      <div class="columns">
        <div class="column is-one-fifth">
          <i class="fas fa-phone-volume lg pt-5"></i>
        </div>
        <div class="column is-size-7 has-right-border has-text-left">
          <p>{{ $t("callScheduled") }}:</p>
          <p>
            {{ scheduledCall | formatTimestampAsReadableDate }}
          </p>
          <p>ore {{ scheduledCall | formatTimestampAsTime }}</p>
        </div>
        <div class="column is-one-fifth">
          <i class="fas fa-trash" @click="deleteScheduledCall"></i>
          <br />
          <div class="columns">
            <div class="column">
              <i
                class="fas fa-pencil-alt pt-5"
                @click="showInputToScheduleCall"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="!isInputShowed && !isCallScheduled"
      :class="[
        'button is-success is-small m-1',
        awaitResponseForScheduledCall ? 'is-loading' : '',
      ]"
      @click="showInputToScheduleCall"
    >
      {{ $t("fieldsDescription.scheduleCall") }}
    </button>
    <p v-if="isInputShowed" class="is-size-7">{{ $t("insertDateAndHour") }}:</p>
    <div v-if="isInputShowed" class="columns">
      <div class="column">
        <Datetime
          type="datetime"
          v-model="dateTimeScheduledCall"
          class="theme-green"
          minute-step="5"
          :min-datetime="
            hasBeenManagedToday ? tomorrowDateTime : currentDateTime
          "
        />
      </div>
    </div>
    <div v-if="isInputShowed" class="columns">
      <div class="column p-1">
        <button
          class="button is-success is-fullwidth is-small"
          @click="hideInputToScheduleCall"
        >
          {{ $t("buttons.cancel") }}
        </button>
      </div>
      <div class="column p-1">
        <button
          class="button is-success is-fullwidth is-small"
          :disabled="!dateTimeScheduledCall"
          @click="saveScheduledCall"
        >
          {{ $t("buttons.save") }}
        </button>
      </div>
    </div>
    <errorModal :errorMessage="errorMessage" />
  </div>
</template>

<script>
import api from "../API/api";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings, DateTime } from "luxon";
import i18next from "i18next";
import errorModal from "@/Error/errorModal.vue";
import errorMixin from "@/Error/errorMixin.js";
import measurementStatusMixin from "../shared/measurementStatusMixin";
export default {
  props: ["scheduledCall", "patientID", "patientStatus"],
  emits: ["handleScheduledCallChange"],
  data() {
    return {
      isInputShowed: false,
      awaitResponseForScheduledCall: false,
      dateTimeScheduledCall: "",
      isCallScheduled: false,
      errorMessage: "",
      currentDateTime: DateTime.now().toISO(),
      tomorrowDateTime: DateTime.now().plus({ days: 1 }).toISODate(),
    };
  },
  mixins: [measurementStatusMixin, errorMixin],
  computed: {
    hasBeenManagedToday() {
      return this.statusHasBeenSetByHuman(this.patientStatus);
    },
  },
  components: {
    Datetime,
    errorModal,
  },
  methods: {
    async saveScheduledCall() {
      this.showLoader();
      try {
        const dateTimeString = new Date(this.dateTimeScheduledCall).toString();
        const result = await api.putScheduledCall(
          this.patientID,
          dateTimeString
        );
        this.$emit("handleScheduledCallChange", {
          scheduledCall: result.scheduledCall,
        });
        this.isCallScheduled = true;
      } catch (e) {
        this.error.message =
          "Error in saving scheduled called to patient: " + e;
      } finally {
        this.resetInputField();
      }
    },

    async deleteScheduledCall() {
      this.showLoader();
      try {
        await api.deleteScheduledCall(this.patientID);
        this.$emit("handleScheduledCallChange", { scheduledCall: "" });
      } catch (e) {
        this.isCallScheduled = this.scheduledCall;
        this.showInputToScheduleCall();
        this.error.message =
          "Error in deleting scheduled call to patient: " + e;
      } finally {
        this.resetInputField();
      }
    },
    hideInputToScheduleCall() {
      this.isInputShowed = false;
    },
    showInputToScheduleCall() {
      this.isInputShowed = true;
    },
    resetInputField() {
      this.dateTimeScheduledCall = "";
      this.awaitResponseForScheduledCall = false;
    },
    showLoader() {
      this.hideInputToScheduleCall();
      this.awaitResponseForScheduledCall = true;
      this.isCallScheduled = false;
    },
  },
  watch: {
    scheduledCall() {
      this.isCallScheduled = this.scheduledCall;
    },
  },
  created() {
    this.isCallScheduled = this.scheduledCall;
    Settings.defaultLocale = i18next.language;
  },
};
</script>
<style lang="scss" scoped>
.fa-trash:hover,
.fa-pencil-alt:hover {
  color: $restech-green;
}
.has-right-border {
  border-right: 1px solid #dbdbdb;
}
.scheduled-call-container {
  border: 1px solid #616365;
  padding: 15px;
}
</style>