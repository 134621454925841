import Vue from "vue";
import i18next from "i18next";
import VueI18Next from "@panter/vue-i18next";
import App from "./Main/App.vue";
import router from "./VueRouter";
import store from "./VueX";
import enTranslation from "../locales/en/translation.json";
import itTranslation from "../locales/it/translation.json";
import frTranslation from "../locales/fr/translation.json";

Vue.config.productionTip = false;

// global filters and formatter
import { formatDistanceStrict, format, formatDistanceToNowStrict } from "date-fns";
import { it, enUS, fr } from "date-fns/esm/locale";

const getLocaleModule = (lang) => {
  switch (lang) {
    case "it":
      return it;
    case "en":
      return enUS;
    case "fr":
      return fr;
  }
};
Vue.filter("formatTimestampAsDateTime", function(timestamp) {
  const lng = getLocaleModule(i18next.language);
  try {
    return format(new Date(timestamp), "yyyy-MM-dd HH:mm", {
      locale: lng,
    });
  } catch (e) {
    console.log(`format timestamp as datetime ERR (${timestamp}): ${e}`);
    return "";
  }
});

Vue.filter("formatTimestampAsDate", function(timestamp) {
  const lng = getLocaleModule(i18next.language);
  if (timestamp == "Invalid Date") return "-";
  try {
    return format(new Date(timestamp), "dd/MM/yyyy", {
      locale: lng,
    });
  } catch (e) {
    console.log("format timestamp as datetime ERR: " + e);
    return "";
  }
});

Vue.filter("formatTimestampAsReadableDate", function(timestamp) {
  const lng = getLocaleModule(i18next.language);
  try {
    return format(new Date(timestamp), "d MMMM yyyy", {
      locale: lng,
    });
  } catch (e) {
    console.log("format timestamp as readable date ERR: " + e);
    return "";
  }
});

Vue.filter("formatTimestampAsTime", function(timestamp) {
  const lng = getLocaleModule(i18next.language);
  try {
    return format(new Date(timestamp), "HH:mm", {
      locale: lng,
    });
  } catch (e) {
    console.log("format timestamp as time ERR: " + e);
    return "";
  }
});

Vue.filter("formatTimestampAsDistance", function(timestamp) {
  const lng = getLocaleModule(i18next.language);
  try {
    return formatDistanceStrict(new Date(timestamp), new Date(), {
      addSuffix: true,
      locale: lng,
      roundingMethod: "floor", // avoids default rounding: this sets the behavior identical to differenceInDays
    });
  } catch (e) {
    console.log("format timestamp as distance ERR: " + e);
    return "";
  }
});

Vue.filter("filterNA", function(value) {
  if (typeof value != "number" || Number.isNaN(value) || !Number.isFinite(value)) return "-";
  else return value;
});

Vue.filter("formatDistanceInAge", function(timestamp) {
  const lng = getLocaleModule(i18next.language);
  try {
    return formatDistanceToNowStrict(new Date(timestamp), {
      addSuffix: false,
      locale: lng,
    });
  } catch (e) {
    console.log("format distance in age ERR: " + e);
    return "";
  }
});

// Vue.filter("translateCapitalized", (value) => {
//   if (!value) return "";
//   const t = i18n.t(value);
//   return t.charAt(0).toUpperCase() + t.slice(1);
// });

Vue.use(VueI18Next);
i18next.init({
  lng: localStorage.getItem("lang"),
  fallbackLng: "en",
  resources: {
    en: { translation: enTranslation },
    it: { translation: itTranslation },
    fr: { translation: frTranslation },
  },
});

const i18n = new VueI18Next(i18next);

new Vue({
  i18n: i18n,
  router, // inject router in all components as this.$router
  store, // inject store in all components as this.$store
  render: (h) => h(App),
}).$mount("#app");
