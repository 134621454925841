import * as axios from "axios";
import auth from "@/Auth/auth";
import i18next from "i18next";
// `baseURL` is prepended to URLs passed to axios
axios.defaults.baseURL = process.env.VUE_APP_API; // see .env

// POST with JSON content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";

if (axios.defaults.baseURL.includes("pstmn.io")) {
  axios.defaults.headers.get["x-api-key"] = "PMAK-602baacd410e0c00527efd58-749292d7baa7b39c0807cfac1dfae0d434"; // TODO process.env
  axios.defaults.headers.post["x-api-key"] = "PMAK-602baacd410e0c00527efd58-749292d7baa7b39c0807cfac1dfae0d434"; // TODO process.env
  axios.defaults.headers.patch["x-api-key"] = "PMAK-602baacd410e0c00527efd58-749292d7baa7b39c0807cfac1dfae0d434"; // TODO process.env
  axios.defaults.headers.get["x-mock-response-code"] = "200";
}

async function setHeadersWithToken() {
  try {
    const token = await auth.getToken();
    // console.log(`Api.js: setting HTTP Header Authorization for ${localStorage.getItem("user.email")} with JWT Token: ${token}`)
    return {
      headers: {
        Authorization: `${token}`,
      },
    };
  } catch (e) {
    console.log("API: Error in getting token from auth: " + e);
  }
}

// Intercept all 401 Unauthorized responses before they are handled by `then` or `catch`
// Always return data only
axios.interceptors.response.use(
  function(response) {
    /* 
    The response for a request contains the following information.
    {
    data: {}, // `data` is the response that was provided by the server
    status: 200, // `status` is the HTTP status code from the server response
    statusText: 'OK', // `statusText` is the HTTP status message from the server response
    headers: {}, // `headers` the HTTP headers that the server responded with. Example: `response.headers['content-type']`
    config: {}, // `config` is the config that was provided to `axios` for the request
    request: {} // `request` is the request that generated this response
    }*/
    try {
      if (response && response.headers && response.headers["content-type"].includes("json")) {
        JSON.parse(JSON.stringify(response.data));
      }
      return response?.data; // always return data only
    } catch (E) {
      console.log("API: Error parsing the following JSON\n" + JSON.stringify(response.data));
      return Promise.reject("Intercepted JSON parsing error: " + E);
    }
  },
  function(error) {
    const UnauthorizedMessage = i18next.t("warnings.unauthorizedMessage");
    if (!error.response) {
      // network error, e.g. CORS issue
      return Promise.reject(`${error}\n(${UnauthorizedMessage})`);
    } else if (error.response.status == 401 || error.response.status == 403) {
      error.response.data = UnauthorizedMessage;
    } else if (error.response.status == 404) {
      error.response.data = "NOT FOUND";
    } else if (error.response.status == 500) {
      console.error("Backend error! \n" + JSON.stringify(error.response));
    }
    return Promise.reject(error.response.data);
  }
);

const get = async function(endpoint) {
  console.log("API: get " + endpoint);
  const options = await setHeadersWithToken();
  return axios.get(endpoint, options);
};

const post = async function(endpoint, requestBody) {
  console.log("API: post " + endpoint + " with body " + JSON.stringify(requestBody));
  const options = await setHeadersWithToken();
  return axios.post(endpoint, requestBody, options);
};

const put = async function(endpoint, requestBody) {
  console.log("API: put " + endpoint);
  const options = await setHeadersWithToken();
  return axios.put(endpoint, requestBody, options);
};

const http_delete = async function(endpoint) {
  console.log("API: delete " + endpoint);
  const options = await setHeadersWithToken();
  return axios.delete(endpoint, options);
};

/*
HTTP GET: use "params" to safely format querystring as in /bacterial-filters?latest=true&stimulus=PSRN
HTTP POST: the javascript object will go into the request body as JSON by default
*/
export default {
  // HTTP GET
  getSummary: () => get("/dashboard"),
  getPatients: () => get(`/patients`),
  getPatient: (id) => get(`/patients/${id}`),
  getAccounts: () => get(`/accounts`),
  getAuditTrail: (months) => get(`/accounts/audit?months=${months}`),
  getMeasurementsForPatient: (patientID) => get(`/patients/${patientID}/measurements`),
  getTrendDataForPatient: (patientID) => get(`/patients/${patientID}/trends`),
  getPhysicians: () => get(`/physicians`),
  getDevices: () => get(`/devices`),
  getAllDevices: () => get(`/devices?all=true`),
  getMonitoringCenters: () => get(`/monitoring/centers`),
  getMonitoringProtocols: () => get(`/monitoring/protocols`),
  getLogsForDevice: (deviceSN) => get(`/devices/${deviceSN}/logs`),
  getDownloadUrlFor: (deviceSN, logName) => get(`devices/${deviceSN}/logs/${logName}`),
  downloadFromSignedUrl: (signedUrl) => axios.get(signedUrl),
  // downloadLogsForDevice: (deviceSN) =>
  //   get(`/devices/${deviceSN}/logs/download`),

  // HTTP POST
  createPatient: (patientData) => post(`/patients`, patientData),
  createLoginTrail: (loginResult) => post(`/accounts/login`, loginResult),
  createNote: (measurementID, notePayload) => post(`measurements/${measurementID}/notes`, notePayload),

  // HTTP PUT
  modifyPatient: (id, patientData) => put(`/patients/${id}`, patientData),
  modifyStatus: (id, newStatus) => put(`/measurements/${id}/status`, newStatus),
  putScheduledCall: (patient, callDatetime) =>
    put(`/patients/${patient}/scheduledCall`, {
      scheduledCall: callDatetime,
    }),
  deleteScheduledCall: (patient) =>
    put(`/patients/${patient}/scheduledCall`, {
      scheduledCall: "",
    }),
  changeLanguage: (newLang) => put(`/accounts/preferences?lang=${newLang}`),

  // HTTP DELETE
  deletePatient: (id) => http_delete(`/patients/${id}`),
};
