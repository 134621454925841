<template>
  <div class="has-background-grey-light p-1">
    <div class="box columns m-2 p-5">
      <div class="column"></div>
      <div class="column is-two-thirds">
        <div class="has-text-primary is-size-5">
          <p class="m-4">
            Hai effettuato il tuo primo accesso come<br />
            <span class="has-text-weight-semibold">{{ getUsername }}</span>
          </p>
          <p class="mb-6">Imposta una password per il tuo account:</p>
        </div>
        <ChangePasswordForm
          :username="this.getUsername"
          :mustEnterName="this.noName"
        ></ChangePasswordForm>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from "@/Auth/ChangePasswordForm.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ChangePasswordForm,
  },
  computed: {
    ...mapGetters(["getCompleteName", "getUsername"]),
    noName() {
      return (
        typeof this.getCompleteName != "string" ||
        !this.getCompleteName.trim() ||
        this.getCompleteName.includes("null")
      );
    },
    noUsername() {
      return (
        typeof this.getUsername != "string" ||
        !this.getUsername.trim() ||
        this.getUsername.includes("null")
      );
    },
  },
  created() {
    if (this.noUsername) {
      console.log(
        `ViewForceChangePassword: no user data detected in $store, force logout`
      );
      this.$router.push("/login");
    } else {
      console.log(
        `ViewForceChangePassword: showing UI to set password for ${this.getUsername}`
      );
    }
  },
};
</script>

<style lang="scss" scoped>
</style>