<template>
  <table class="table is-fullwidth is-fixed">
    <PatientMeasurementsTableHeading
      v-if="isLastMeasure"
      :isCurrentRouteForPrintHistory="isCurrentRouteForPrintHistory"
    />
    <tbody>
      <tr class="remove-border-bottom has-border-top">
        <td
          :class="[
            'is-capitalized has-text-weight-semibold right-border',
            { 'p-0': isCurrentRouteForPrintHistory },
          ]"
          colspan="2"
        >
          <p
            :class="[
              colorByStatus(measure.status),
              { 'is-size-7': isCurrentRouteForPrintHistory },
            ]"
          >
            {{ this.wordByStatus(measure.status)
            }}{{
              new Date(measure.date).toDateString() !==
                new Date(measure.receivedAt).toDateString() &&
              isCurrentRouteForPrintHistory
                ? "*"
                : ""
            }}
          </p>
          <p
            v-if="
              statusHasBeenSetByHuman(measure.status) &&
              !isCurrentRouteForPrintHistory
            "
            class="has-text-weight-normal is-size-7"
          >
            <span v-if="!statusChangedToday(measure.statusChangedAt)">
              {{ measure.statusChangedAt | formatTimestampAsDateTime }}
            </span>
            <span v-else>{{
              measure.statusChangedAt | formatTimestampAsTime
            }}</span>
          </p>
          <p
            class="is-size-7"
            v-show="
              new Date(measure.date).toDateString() !==
                new Date(measure.receivedAt).toDateString() &&
              !isCurrentRouteForPrintHistory
            "
          >
            {{ $t("received") }}:
            {{ measure.receivedAt | formatTimestampAsDate }}
          </p>
          <p
            id="scheduled_call_icon_and_time"
            :class="[
              'has-text-weight-normal',
              isCurrentRouteForPrintHistory ? 'is-size-7' : 'is-size-6',
              [colorByStatus(measure.status)],
            ]"
            v-if="showScheduledCall && isLastMeasure"
          >
            <i
              :class="[
                'fas fa-phone-volume lg pt-2',
                { 'is-size-7': isCurrentRouteForPrintHistory },
              ]"
            ></i>
            {{ this.scheduledCall | formatTimestampAsTime }}
          </p>
        </td>
        <td
          :class="['right-border', { 'p-0': isCurrentRouteForPrintHistory }]"
          colspan="2"
        >
          <p
            v-show="!isCurrentRouteForPrintHistory"
            :class="[
              {
                'has-anomaly':
                  isLastMeasure &&
                  above_date_error_threshold(
                    measure.date,
                    this.monitoringProtocol
                  ),
                'orange-symptom':
                  isLastMeasure &&
                  !above_date_error_threshold(
                    measure.date,
                    this.monitoringProtocol
                  ) &&
                  above_date_warning_threshold(measure.date),
              },
              { 'is-size-7': isCurrentRouteForPrintHistory },
            ]"
          >
            {{ measure.date | formatTimestampAsDistance }}
          </p>

          <p class="is-size-7">
            {{ measure.localTimestamp | formatTimestampAsDateTime }}
            {{ measure.deviceTimezone }}
          </p>
        </td>
        <td
          id="RrsCell"
          :class="[
            getStyleForWorseningParam('Rrs'),
            { 'is-size-8 p-0': isCurrentRouteForPrintHistory },
          ]"
        >
          <span :title="measure.respiratoryFunction.outputDescription">
            {{ this.getRrs }}<br />
            {{ this.trendSymbol("Rrs") }}</span
          >
        </td>
        <td
          id="XrsCell"
          :class="[
            getStyleForWorseningParam('Xrs'),
            { 'is-size-8 p-0': isCurrentRouteForPrintHistory },
          ]"
        >
          <span :title="measure.respiratoryFunction.outputDescription">
            {{ this.getXrs }}<br />
            {{ this.trendSymbol("Xrs") }}</span
          >
        </td>
        <td
          id="EflCell"
          :class="[
            getStyleForWorseningParam('efl'),
            { 'is-size-8 p-0': isCurrentRouteForPrintHistory },
            'right-border',
          ]"
        >
          <span :title="measure.respiratoryFunction.outputDescription">
            {{ this.getEfl }} <br />
            {{ this.trendSymbol("EFL") }}</span
          >
        </td>

        <td
          :class="[
            { 'has-anomaly': this.isRrAbnormal },
            { 'is-size-8 p-0': isCurrentRouteForPrintHistory },
          ]"
        >
          {{ this.getRr }}
        </td>
        <td
          :class="[
            { 'has-anomaly': this.isVtAbnormal },
            'right-border',
            { 'is-size-8 p-0': isCurrentRouteForPrintHistory },
          ]"
        >
          {{ this.getVt }}
        </td>
        <td
          :class="[
            {
              'has-anomaly':
                this.isSpO2Abnormal &&
                protocolConsidersSaturation(this.monitoringProtocol),
            },
            'right-border',
            { 'is-size-8 p-0': isCurrentRouteForPrintHistory },
          ]"
        >
          {{ this.getSpO2 || "-" }}
        </td>
        <td
          :class="[
            {
              'orange-symptom':
                this.isTemperatureAbnormal &&
                protocolConsidersTemperature(this.monitoringProtocol),
            },
            'right-border',
            { 'is-size-8 p-0': isCurrentRouteForPrintHistory },
          ]"
        >
          {{ this.getTemperature || "-" }}
        </td>
        <td colspan="3">
          <ul
            v-if="measure.symptoms && measure.symptoms.length > 0"
            class="is-clipped has-text-left set-new-text-line squared"
          >
            <li
              v-for="(symptom, idx) in measure.symptoms.filter((s) =>
                symptom_is_not_temperature_or_saturation(s)
              )"
              :key="idx"
              :class="colorBySeverity(symptom.severity, monitoringProtocol)"
              class="is-size-7 p-1"
            >
              {{
                !isNaN(symptom.name)
                  ? $t("symptoms.Puffs of blue reliever") + ": " + symptom.name
                  : $t("symptoms." + symptom.name)
              }}
            </li>
          </ul>
          <p v-else-if="measure.symptoms">{{ $t("none") }}</p>
          <p v-else>-</p>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import PatientMeasurementsTableHeading from "./PatientMeasurementsTableHeading.vue";
import measurementStatusMixin from "../shared/measurementStatusMixin";
export default {
  props: [
    "isLastMeasure",
    "isCurrentRouteForPrintHistory",
    "measure",
    "monitoringProtocol",
    "showScheduledCall",
    "scheduledCall",
  ],
  components: { PatientMeasurementsTableHeading },
  mixins: [measurementStatusMixin],
  computed: {
    RrsUnknown() {
      return this.measure.respiratoryFunction.rrs.anomaly == "unknown";
    },
    XrsUnknown() {
      return this.measure.respiratoryFunction.xrs.anomaly == "unknown";
    },
    EflUnknown() {
      return this.measure.respiratoryFunction.efl.anomaly == "unknown";
    },
    RrsWorsening() {
      return this.measure.respiratoryFunction.rrs.anomaly == "worsening";
    },
    XrsWorsening() {
      return this.measure.respiratoryFunction.xrs.anomaly == "worsening";
    },
    EflWorsening() {
      return this.measure.respiratoryFunction.efl.anomaly == "worsening";
    },
    RrsStable() {
      return this.measure.respiratoryFunction.rrs.anomaly == "stable";
    },
    XrsStable() {
      return this.measure.respiratoryFunction.xrs.anomaly == "stable";
    },
    EflStable() {
      return this.measure.respiratoryFunction.efl.anomaly == "stable";
    },
    isRrAbnormal() {
      return this.measure.respiratoryPattern.rr.anomaly;
    },
    isVtAbnormal() {
      return this.measure.respiratoryPattern.vt.anomaly;
    },
    isSpO2Abnormal() {
      return this.measure.spo2.anomaly;
    },
    isTemperatureAbnormal() {
      return this.measure.temperature.anomaly;
    },
    getRrs() {
      return (
        this.measure.respiratoryFunction &&
        this.measure.respiratoryFunction.rrs &&
        this.measure.respiratoryFunction.rrs.value &&
        this.measure.respiratoryFunction.rrs.value.toFixed(2)
      );
    },
    getXrs() {
      return (
        this.measure.respiratoryFunction &&
        this.measure.respiratoryFunction.xrs &&
        this.measure.respiratoryFunction.xrs.value &&
        this.measure.respiratoryFunction.xrs.value.toFixed(2)
      );
    },
    getEfl() {
      return (
        this.measure?.respiratoryFunction?.efl?.value &&
        this.measure?.respiratoryFunction?.efl?.value.toFixed(2)
      );
    },
    getRr() {
      return (
        this.measure?.respiratoryPattern?.rr?.value &&
        this.measure?.respiratoryPattern?.rr?.value.toFixed(2)
      );
    },
    getVt() {
      return (
        this.measure?.respiratoryPattern?.vt?.value &&
        this.measure?.respiratoryPattern?.vt?.value.toFixed(2)
      );
    },
    getSpO2() {
      return this.measure.spo2.anomaly
        ? "<90%"
        : this.measure.spo2.status == "stable"
        ? ">90%"
        : "-";
    },
    getTemperature() {
      return this.measure.temperature.anomaly
        ? ">37.5"
        : this.measure.temperature.status == "stable"
        ? "<37.5"
        : "-";
    },
  },
  methods: {
    symptom_is_not_temperature_or_saturation(symptom) {
      const thisSymptom = symptom.name.toLowerCase();
      return (
        !thisSymptom.includes("body temperature") &&
        !thisSymptom.includes("saturation")
      );
    },
    colorBySeverity(severity, monitoringProtocol) {
      if (monitoringProtocol !== "COPD+S") return "grey-symptom";

      return severity === 0
        ? "grey-symptom"
        : severity === 1
        ? "orange-symptom"
        : "red-symptom";
    },
    getStyleForWorseningParam(p) {
      if (this.monitoringProtocol === "COPD") {
        // COPD protocol w/o symptoms differentiates the action to take based on colors [orange|red]
        if (
          (p == "efl" && this.EflWorsening) ||
          (p == "Xrs" && this.XrsWorsening) ||
          (p == "Rrs" && this.RrsWorsening)
        ) {
          return this.measure.status == "call-a-doctor"
            ? "has-anomaly"
            : "orange-parameter";
        }
      } else {
        // All other protocols just color the worsening parameters in red
        if (
          (p == "efl" && this.EflWorsening) ||
          (p == "Xrs" && this.XrsWorsening) ||
          (p == "Rrs" && this.RrsWorsening)
        )
          return "has-anomaly";
      }
    },
    trendSymbol(param) {
      const p = param && param.toLowerCase();
      let res = "-";
      switch (p) {
        case "rrs":
          if (this.RrsWorsening) res = "↗";
          else if (this.RrsStable) res = " ⇄";
          else if (this.RrsUnknown) res = "N/A";
          else res = "↘";
          break;
        case "xrs":
          if (this.XrsWorsening) res = "↘";
          else if (this.XrsStable) res = " ⇄";
          else if (this.XrsUnknown) res = "N/A";
          else res = "↗";
          break;
        case "efl":
          if (this.EflWorsening) res = "↗";
          else if (this.EflStable) res = " ⇄";
          else if (this.EflUnknown) res = "N/A";
          else res = "↘";
          break;
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.has-anomaly,
.red-symptom {
  color: $danger;
  font-weight: bolder;
}
.grey-symptom {
  color: $restech-grey-dark;
}
.orange-symptom {
  color: $restech-orange;
}
.orange-parameter {
  color: $restech-orange;
  font-weight: bolder;
}

.has-border-top {
  border-top: 1px solid #e5e5e5;
}

.remove-border-bottom > td,
.remove-border-bottom {
  border-bottom: 0 !important;
}
.right-border > td,
.right-border {
  border-right: 1px solid #e5e5e5 !important;
}
.set-new-text-line {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>