<template>
  <div class="has-background-grey-light p-1">
    <div>
      <div class="box columns m-2 p-1">
        <div class="column">
          <PatientRecap
            :patient="patient"
            @handleScheduledCallChange="setScheduledCall($event)"
            :measurementsPoints="measurementsPointsToPlot"
            :measurements="measurements"
            :isCurrentRouteForPrintHistory="false"
            :customImpedanceRange="customImpedanceRange"
            :customTimeSeriesRange="customTimeSeriesRange"
          />
        </div>
      </div>
      <div class="box columns m-2 p-1" v-if="hasMeasurements">
        <div class="column">
          <PatientMeasurementChart
            :measurementsPoints="measurementsPointsToPlot"
            :isCurrentRouteForPrintHistory="false"
            @handleChangeImpedanceRange="setCustomImpedanceRange($event)"
            @handleChangeTimeSeriesRange="setCustomTimeSeriesRange($event)"
          />
        </div>
      </div>
      <div class="box columns m-2 p-1">
        <div class="column">
          <PatientMeasurements
            :measurements="measurements"
            :showScheduledCall="displayIconForScheduledCall"
            :scheduledCall="patient.scheduledCall"
            :patientId="patient_id"
            :monitoringProtocol="
              patient.monitoringData &&
              patient.monitoringData.monitoringProtocol
            "
            :isCurrentRouteForPrintHistory="false"
            :globalMostRecentMeasurementDatetime="mostRecentMeasurementDatetime"
            @handleMeasurementStatusChange="setLastMeasurementStatus($event)"
            @handleNewNote="addNoteToMeasurement($event)"
            @handleScheduledCallChange="setScheduledCall($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../API/api";
import errorMixin from "../Error/errorMixin";
import patientStatusMixin from "../shared/measurementStatusMixin";
import PatientRecap from "./PatientRecap.vue";
import PatientMeasurements from "./PatientMeasurements.vue";
import PatientMeasurementChart from "./PatientMeasurementChart.vue";
import { format } from "date-fns";
import { cloneDeep } from "lodash";
export default {
  props: ["patient_id"],
  components: {
    PatientRecap,
    PatientMeasurements,
    PatientMeasurementChart,
  },
  mixins: [errorMixin, patientStatusMixin],
  data() {
    return {
      measurementsPointsToPlot: null,
      measurements: null,
      allMeasurements: null,
      patient: null,
      displayIconForScheduledCall: false,
      savedPatientStatus: "",
      customImpedanceRange: null,
      customTimeSeriesRange: null,
    };
  },
  computed: {
    isCallScheduledForToday() {
      return (
        this.patient.scheduledCall &&
        new Date().toDateString() ===
          new Date(this.patient.scheduledCall).toDateString()
      );
    },
    hasMeasurements() {
      return this.measurements && this.measurements.length > 0;
    },
    mostRecentMeasurementDatetime() {
      return this.allMeasurements &&
        this.allMeasurements.length &&
        this.allMeasurements.length > 0
        ? this.allMeasurements[0].date
        : null;
    },
  },
  methods: {
    setCustomTimeSeriesRange(range) {
      this.customTimeSeriesRange = range;
      this.measurements = this.allMeasurements.filter((m) => {
        const dateString = new Date(m.date).toISOString().substring(0, 10);
        const mDate = new Date(dateString);
        if (range.newestDate)
          return (
            mDate <= new Date(range.newestDate) &&
            mDate >= new Date(range.oldestDate)
          );
        else return mDate >= new Date(range.oldestDate);
      });
      this.displayIconForScheduledCall =
        this.isCallScheduledForToday &&
        this.measurements[0].date === this.mostRecentMeasurementDatetime;
    },
    setCustomImpedanceRange(range) {
      this.customImpedanceRange = range;
    },

    orderMeasurementsByDate() {
      this.measurements.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    },
    orderMeasurementNotesByDate() {
      this.measurements.forEach((measurement) => {
        if (measurement.notes && measurement.notes.length > 1) {
          measurement.notes.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        } else if (!measurement.notes) measurement.notes = [];
      });
    },
    addNoteToMeasurement(data) {
      this.measurements.forEach((measurement, idx) => {
        if (measurement.measurementId == data.measurementId) {
          this.measurements[idx].notes.unshift(data.note);
        }
      });
    },
    setScheduledCall(data) {
      console.log(
        "Setting scheduled call. Measurements[0]: " +
          this.measurements[0].date +
          " compared to " +
          this.mostRecentMeasurementDatetime
      );
      this.patient.scheduledCall = data.scheduledCall;
      this.displayIconForScheduledCall =
        this.isCallScheduledForToday &&
        this.measurements[0].date === this.mostRecentMeasurementDatetime;
      this.setLastMeasurementStatus();
    },
    setLastMeasurementStatus(explicitNewStatus) {
      if (explicitNewStatus) {
        this.allMeasurements[0].status = explicitNewStatus;
        this.savedPatientStatus = this.allMeasurements[0].status;
      } else {
        this.allMeasurements[0].status = this.isCallScheduledForToday
          ? "to-manage"
          : this.savedPatientStatus;
      }
      if (this.measurements[0].date === this.allMeasurements[0].date) {
        // aggiorniamo lo stato della misura più recente mostrata solo se è la più recente globale
        this.measurements[0].status = this.allMeasurements[0].status;
      }
    },
    getDate(timestamp) {
      return format(new Date(timestamp), "dd/MM/yyyy");
    },
    transformAndOrderTrendDataByDate() {
      const arrOfmeasurementsPoints =
        this.combineMeasurementsPointsInArrayOfObjects();

      const arrOfmeasurementsPointsSorted =
        this.sortMeasurementsPointsArrByDate(arrOfmeasurementsPoints);
      this.measurementsPointsToPlot = this.convertMeasurementsPointsArrInObject(
        arrOfmeasurementsPointsSorted
      );
    },
    combineMeasurementsPointsInArrayOfObjects() {
      let measurementsPointsObject = [];
      const areSymptomsPresent =
        this.measurementsPointsToPlot.symptomsAnomalies &&
        this.measurementsPointsToPlot.symptomsAnomalies.length > 0;

      this.measurementsPointsToPlot["date"].forEach((e, idx) => {
        const symptomsAnomaliesValue = areSymptomsPresent
          ? this.measurementsPointsToPlot["symptomsAnomalies"][idx]
          : undefined;

        const saturationLessThan90Value = areSymptomsPresent
          ? this.measurementsPointsToPlot["saturationLessThan90"][idx]
          : undefined;

        const temperatureGreaterThan37Value = areSymptomsPresent
          ? this.measurementsPointsToPlot["temperatureGreaterThan37"][idx]
          : undefined;

        const increasedOxygenFlowValue = areSymptomsPresent
          ? this.measurementsPointsToPlot["increasedOxygenFlow"][idx]
          : undefined;

        if (!areSymptomsPresent) {
          measurementsPointsObject.push({
            date: this.measurementsPointsToPlot["date"][idx],
            rrs: parseFloat(this.measurementsPointsToPlot["rrs"][idx]),
            xrs: parseFloat(this.measurementsPointsToPlot["xrs"][idx]),
            efl: parseFloat(this.measurementsPointsToPlot["efl"][idx]),
            trend: this.measurementsPointsToPlot["trend"][idx],
          });
        } else {
          measurementsPointsObject.push({
            date: this.measurementsPointsToPlot["date"][idx],
            rrs: parseFloat(this.measurementsPointsToPlot["rrs"][idx]),
            xrs: parseFloat(this.measurementsPointsToPlot["xrs"][idx]),
            efl: parseFloat(this.measurementsPointsToPlot["efl"][idx]),
            trend: this.measurementsPointsToPlot["trend"][idx],
            symptomsAnomalies: symptomsAnomaliesValue,
            saturationLessThan90: saturationLessThan90Value,
            temperatureGreaterThan37: temperatureGreaterThan37Value,
            increasedOxygenFlow: increasedOxygenFlowValue,
          });
        }
      });

      return measurementsPointsObject;
    },
    sortMeasurementsPointsArrByDate(measurementsPointsArr) {
      measurementsPointsArr.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      return measurementsPointsArr;
    },
    convertMeasurementsPointsArrInObject(measurementsPointsArr) {
      let measurementsPoints = {
        date: [],
        rrs: [],
        xrs: [],
        efl: [],
        trend: [],
        symptomsAnomalies: [],
        saturationLessThan90: [],
        temperatureGreaterThan37: [],
        increasedOxygenFlow: [],
      };
      measurementsPointsArr.forEach((element) => {
        [
          "date",
          "rrs",
          "xrs",
          "efl",
          "trend",
          "symptomsAnomalies",
          "saturationLessThan90",
          "temperatureGreaterThan37",
          "increasedOxygenFlow",
        ].forEach((field) => {
          if (element[field] != undefined)
            measurementsPoints[field].push(element[field]);
        });
      });
      return measurementsPoints;
    },
  },
  async created() {
    this.measurements = undefined;
    this.patient = "";
    this.measurementsPointsToPlot = undefined;

    try {
      // let's get the patient first (we need to know his/her monitoring protocol)
      let serverData = await api.getPatient(this.patient_id);
      this.patient = serverData;

      // get the measurements
      serverData = await api.getMeasurementsForPatient(this.patient_id);
      this.measurements = serverData;
      this.orderMeasurementsByDate();
      this.orderMeasurementNotesByDate();
      if (this.hasMeasurements) {
        // we have measurements: verify for patient compliance
        // TODO: si potrebbe prendere lo stato come prop dalla dashboard anzichè ricalcolarlo?
        this.measurements[0] = this.verifyForPatientCompliance(
          this.measurements[0],
          this.patient.monitoringData?.monitoringProtocol
        );
        this.savedPatientStatus = this.measurements[0].status;
      }
      this.allMeasurements = cloneDeep(this.measurements);

      this.displayIconForScheduledCall = this.isCallScheduledForToday;
      if (this.hasMeasurements) {
        this.setLastMeasurementStatus(); // may depend on the scheduled call

        // we have measurements: download points data for chart
        serverData = await api.getTrendDataForPatient(this.patient_id);
        this.measurementsPointsToPlot = serverData;
        this.transformAndOrderTrendDataByDate();
      }
    } catch (E) {
      console.log(`Server error: ${JSON.stringify(E)}`);
      this.error.message =
        new Date().toISOString() + E.toString() || JSON.stringify(E);
    }
  },
};
</script>