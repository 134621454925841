/<template>
  <div>
    <table
      v-if="this.orderedDevices.length > 0"
      class="table is-fullwidth is-fixed"
    >
      <tr v-for="(device, idx) in this.orderedDevices" :key="idx">
        <td class="is-uppercase is-clipped">
          {{ device.SerialNumber }}
        </td>
        <td class="is-uppercase is-clipped">
          <p v-if="device.patient && device.patient.name">
            {{ device.patient.name }} {{ device.patient.surname }}
          </p>
          <p v-else-if="device.patient && device.patient.PatientCode">
            {{ $t("anonymous") }}
          </p>
          <p
            v-if="device.patient"
            :class="device.patient.name ? 'is-size-7' : 'is-size-6'"
          >
            {{ device.patient.PatientCode }}
          </p>
        </td>
        <td class="is-uppercase is-clipped">
          <p v-if="device.lastContact">
            {{ device.lastContact.timestamp | formatTimestampAsDistance }}
          </p>
          <p v-if="device.lastContact" class="is-size-7">
            {{ device.lastContact.timestamp | formatTimestampAsDateTime }}
          </p>
        </td>
        <td class="is-uppercase is-clipped has-left-border">
          <p v-if="device.lastContact">
            {{ device.lastContact && device.lastContact.powerLevel }}%
          </p>
        </td>
        <td class="is-uppercase is-clipped has-right-border">
          {{ device.lastContact && device.lastContact.apn }}
        </td>
        <td class="is-uppercase is-clipped">
          {{ device.lastContact && device.lastContact.softwareVersion }}
        </td>
        <td class="is-uppercase is-clipped">
          <button
            class="button is-success"
            @click="$emit('handleOpenLogsClick', device.SerialNumber)"
          >
            {{ $t("logs") }}
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  emits: ["handleOpenLogsClick"],
  props: {
    devices: Array,
  },
  computed: {
    noContent() {
      return this.devices.length == 0;
    },

    orderedDevices() {
      // order by last contact sent if available, for inactive devices order by S/N
      const copy = cloneDeep(this.devices);
      return copy.sort(function (a, b) {
        if (a.lastContact && b.lastContact)
          return (
            new Date(b.lastContact.timestamp) -
            new Date(a.lastContact.timestamp)
          );
        else if (a.SerialNumber && b.SerialNumber)
          return a.SerialNumber - b.SerialNumber;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
td.has-left-border {
  border-left: 1px solid #dbdbdb !important;
}
td.has-right-border {
  border-right: 1px solid #dbdbdb !important;
}
</style>