<template>
  <div v-show="isCurrentRouteNameDifferentFromPrint">
    <section id="green-top-bar" class="columns is-gapless is-vcentered m-0">
      <div
        v-if="isLoggedIn"
        class="column has-text-white is-size-4 has-text-left is-capitalized m-3"
      >
        <p class="m-1">{{ $t(currentRouteName) }}</p>
      </div>
      <div
        v-if="isLoggedIn && layout2"
        class="column is-narrow has-text-right has-text-black p-1"
      >
        <div class="has-text-weight-bold has-text-left">
          {{ $t("operator") }}:
        </div>
        <span class="has-text-right">{{
          `${getCompleteName || ""}, ${getUserRole || "(unknown role)"} at ${
            getUserClient || "(unknown client)"
          } `
        }}</span>
      </div>
      <div
        v-if="isLoggedIn && layout1"
        @click="$router.push('/about')"
        class="clickable"
      >
        <i class="fas fa-info-circle"></i>
      </div>
      <div v-if="isLoggedIn && layout1" class="column is-narrow m-2">
        <div class="control has-icons-left">
          <div class="dropdown">
            <button class="dropbtn">
              <img
                v-if="this.lang == 'it'"
                src="../assets/italy.png"
                width="20"
                height="15"
              />
              <img
                v-else-if="this.lang == 'en'"
                src="../assets/united-kingdom.png"
                width="20"
                height="15"
              />
              <img
                v-else-if="this.lang == 'fr'"
                src="../assets/france.png"
                width="20"
                height="15"
              />
            </button>
            <div class="dropdown-content">
              <a @click="setLanguage('it')"
                ><img src="../assets/italy.png" width="20" height="15" />
              </a>
              <a @click="setLanguage('en')"
                ><img src="../assets/united-kingdom.png" width="20" height="15"
              /></a>
              <a v-if="enableFr" @click="setLanguage('fr')"
                ><img src="../assets/france.png" width="20" height="15"
              /></a>
            </div>
          </div>
          <!-- <div class="icon is-small is-left">
            <i class="fas fa-globe"></i>
          </div> -->
        </div>
      </div>
      <div v-if="isLoggedIn && layout1" class="column is-narrow m-2">
        <button @click="logout" class="button is-small is-dark">
          <span class="icon">
            <i class="fas fa-sign-out-alt"></i>
          </span>
          <span>{{ $t("buttons.logout") }}</span>
        </button>
      </div>
    </section>
    <section class="hero is-bold hero-color">
      <div class="hero-body small-hero">
        <div class="container">
          <div class="columns is-vcentered">
            <div
              id="logo-container"
              class="p-3 m-3 column has-text-left is-one-fourth"
            >
              <!-- <img :src="resolveLogo" /> -->
            </div>
            <div class="column is-half">
              <NavBar v-if="isLoggedIn" />
            </div>
            <div class="column is-one-fourth" v-if="isLoggedIn && layout1">
              <div class="has-border-left has-text-right">
                <div class="has-text-weight-bold has-text-right">
                  {{ $t("operator") }}
                </div>
                <div class="has-text-right">{{ getCompleteName || "" }}</div>
                <div class="has-text-right">
                  {{ getUserRole + " at " + getUserClient || "" }}
                </div>
              </div>
            </div>
            <div class="column is-one-fourth" v-if="isLoggedIn && layout2">
              <button @click="logout" class="button is-dark">
                <span class="icon">
                  <i class="is-large fas fa-sign-out-alt"></i>
                </span>
                <span>{{ $t("buttons.logout") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <NavBar v-if="isLoggedIn && displayNavbar" /> -->
    </section>
    <errorModal :errorMessage="error.message" />
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import i18next from "i18next";
import api from "@/API/api";
import { mapGetters } from "vuex";
import errorMixin from "@/Error/errorMixin.js";
import errorModal from "@/Error/errorModal.vue";

export default {
  data() {
    return {
      resolveLogo: "img/logorestech.png",
      displayNavbar: false,
      layout1: 1,
      lang: localStorage.getItem("lang"),
      awaitingResponse: false,
      enableFr: false,
    };
  },
  props: {
    currentRouteName: {
      type: String,
      default: "",
    },
  },
  components: {
    NavBar,
    errorModal,
  },
  mixins: [errorMixin],
  methods: {
    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.$router.push("/login");
      } catch (e) {
        console.log("Logout error: " + e);
      }
    },
    setLanguage(lang) {
      api
        .changeLanguage(lang)
        .then(() => {
          i18next.changeLanguage(lang);
          localStorage.setItem("lang", lang);
          this.lang = lang;
        })
        .catch(
          (e) =>
            (this.error.message =
              `[${new Date().toISOString()}] ` + JSON.stringify(e))
        );
    },
  },
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "getUserRole",
      "getUserClient",
      "getUsername",
      "getCompleteName",
    ]),
    layout2() {
      return !this.layout1;
    },
    isCurrentRouteNameDifferentFromPrint() {
      return this.currentRouteName !== "printPatientHistory";
    },
    languageFlag() {
      if (this.lang == "it") return "../assets/italy.png";
      return "../assets/united-kingdom.png";
    },
  },
};
</script>
<style lang="scss" scoped>
/* Dropdown Button */
.dropbtn {
  background-color: #a8b400;
  color: white;
  padding: 6px 10px 10px 10px;
  font-size: 16px;
  border: none;
  height: 30px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #a8b400;
}

#green-top-bar {
  height: 40px;
  background-color: $primary;
}

.has-border-left {
  border-left: 2px solid $restech-grey;
  float: right;
  padding-left: 10px;
}

.small-hero {
  padding: 0.7em;
}

.hero-color {
  background-color: $restech-white;
}
#logo-container {
  background: url("../assets/logorestech.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 60px;
}
</style>