<template>
  <table style="width: 100%" class="table is-fullwidth is-fixed">
    <colgroup>
      <col span="1" style="width: 3%" />
      <col span="1" style="width: 18%" />
      <col span="1" style="width: 18%" />
      <col span="1" style="width: 18%" />
      <col span="1" style="width: 18%" />
      <col span="1" style="width: 18%" />
      <col span="1" style="width: 18%" />
      <col span="1" style="width: 5%" />
    </colgroup>
    <thead v-if="patients[0] && patients[0].isAnonymous">
      <th colspan="8" class="has-text-left">{{ $t("anonymousProfiles") }}:</th>
    </thead>
    <thead v-else-if="patients[0] && !this.profileComplete">
      <th colspan="8" class="has-text-left">{{ $t("incompleteProfiles") }}:</th>
    </thead>
    <thead v-else-if="patients[0] && this.profileComplete">
      <th colspan="8" class="has-text-left">{{ $t("completeProfiles") }}:</th>
    </thead>
    <tr
      v-for="patient in patients"
      :key="patient.id"
      class="clickable"
      @click="redirect(patient)"
    >
      <td
        style="width: 3%"
        class="is-uppercase is-clipped has-text-left"
        :class="patientColor"
      >
        <input
          v-if="arePatientsToDelete"
          type="checkbox"
          :value="patient.PatientID"
          v-model="patientsToDelete"
          style="width: auto; box-shadow: none"
        />
      </td>
      <td class="is-uppercase is-clipped" :class="patientColor">
        {{ patient.personalData && patient.personalData.surname }}
      </td>
      <td
        class="is-uppercase is-clipped"
        :class="!patient.isAnonymous && patientColor"
      >
        {{ patient.personalData && patient.personalData.name }}
      </td>
      <td class="is-uppercase is-clipped">
        {{ getBirthdate(patient) | formatTimestampAsDate }}
      </td>
      <td class="is-uppercase is-clipped">
        {{ patient.personalData && patient.personalData.PatientCode }}
      </td>
      <td class="is-uppercase is-clipped">
        {{ getPhysician(patient) }}
      </td>
      <td class="is-uppercase is-clipped">
        {{ getMonitoringProtocol(patient) }} <br />
        <span class="is-size-7" v-if="getMonitoringCenter(patient)"
          >({{ getMonitoringCenter(patient) }})</span
        ><span class="is-size-7" v-else>-</span>
      </td>
      <td
        @click.stop
        @click="$router.push(`/measurements/${patient.PatientID}`)"
        class="is-uppercase is-clipped"
      >
        <button
          class="button is-success"
          @click="$emit('handleOpenLogsClick', device.SerialNumber)"
        >
          <span class="icon">
            <i class="fas fa-chart-line"></i>
          </span>
        </button>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      patientsToDelete: [],
    };
  },
  props: {
    patients: Array,
    profileComplete: Boolean,
    arePatientsToDelete: Boolean,
    patientHaveBeenDeleted: Boolean,
  },
  computed: {
    patientColor() {
      return this.profileComplete ? "has-text-primary" : "patient-to-complete";
    },
  },
  methods: {
    getPhysician(patient) {
      return patient && patient.personalData && patient.personalData.physician;
    },
    getBirthdate(patient) {
      return patient.personalData && patient.personalData.birthdate;
    },
    getMonitoringProtocol(patient) {
      return (
        patient &&
        patient.monitoringData &&
        patient.monitoringData.monitoringProtocol
      );
    },
    getMonitoringCenter(patient) {
      return (
        patient && patient.monitoringData && patient.monitoringData.patientGroup
      );
    },
    redirect(patient) {
      if (!this.arePatientsToDelete) {
        this.$router.push({
          path: "/patient-form",
          query: { patient: patient.PatientID },
        });
      } else this.addPatientToSelected(patient.PatientID);
    },
    addPatientToSelected(patientID) {
      if (this.patientsToDelete.includes(patientID)) {
        const idx = this.patientsToDelete.indexOf(patientID);
        this.patientsToDelete.splice(idx, 1);
      } else this.patientsToDelete.push(patientID);
    },
  },
  watch: {
    patientHaveBeenDeleted() {
      if (this.patientHaveBeenDeleted) {
        this.patientsToDelete = [];
        this.$emit("handleSetPatientToDeleteAsEmpty");
      }
    },
    patientsToDelete() {
      this.$emit("handlePatientsToDelete", this.patientsToDelete);
    },
  },
};
</script>

<style lang="scss" scoped>
.gray-patient {
  color: $restech-grey-pantone;
}
.patient-to-complete {
  color: $restech-orange;
}
</style>