<template>
  <div class="container">
    <div class="columns login-container">
      <div class="column login-column">
        <p class="welcome-text">{{ welcomeText }}</p>
        <LoginForm></LoginForm>
      </div>
      <div class="column is-two-thirds img-column"></div>
    </div>
  </div>
</template>
<script>
import LoginForm from "../Auth/LoginForm.vue";
export default {
  components: { LoginForm },
  data() {
    return {
      welcomeText: ""//`Benvenuti in Resolve`
    }
  },
  async created () {
    await this.$store.dispatch("logout");
  },
};
</script>
<style lang="scss">
.login-container {
  height: 600px;
}
.login-column {
  padding: 1em;
  margin-left: 1em;
}
.img-column {
  background-image: url("../assets/clouds.jpg");
  background-position: right;
  background-repeat: no-repeat;
  background-clip: content-box;
  background-size: cover;
}
.welcome-text {
  margin: 50px;
  min-height: 60px;
}
</style>