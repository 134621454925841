<template>
  <div id="chart-component">
    <Loader v-if="isLoading" />
    <div v-if="!isLoading && !this.arePointsToPlotPresent()">
      {{ $t("warnings.patientWithNoMeasurements") }}
    </div>
    <div class="columns" v-show="!isLoading && this.arePointsToPlotPresent()">
      <div
        :class="['column', { 'is-four-fifths': isCurrentRouteForPrintHistory }]"
      >
        <div
          id="canvas-container"
          :style="isCurrentRouteForPrintHistory ? 'width: 75%' : 'width:92%'"
        >
          <canvas id="measurements-chart" v-show="showCharts"></canvas>
        </div>
      </div>
      <div class="column is-one-fifth hide-in-print-page">
        <div class="has-text-centered">
          <button
            v-if="!isCurrentRouteForPrintHistory"
            class="button is-success is-small fixed-width"
            @click="showCharts = !showCharts"
          >
            <span v-if="showCharts">{{ $t("charts.hide") }} </span>
            <span v-else>{{ $t("charts.show") }}</span>
          </button>
          <div id="buttons-in-report-view" class="p-5" v-else>
            <button
              class="hide-in-print-page button is-success is-small is-fullwidth m-2"
              @click="this.printReport"
            >
              {{ $t("buttons.print") }}
            </button>
            <button
              class="hide-in-print-page button is-success is-small is-fullwidth m-2"
              @click="this.goBack"
            >
              {{ $t("buttons.back") }}
            </button>
          </div>
        </div>
        <br v-show="showCharts" />
        <div
          id="selectRange_Z"
          v-show="showCharts && !isCurrentRouteForPrintHistory"
          class="m-3"
        >
          {{ $t("charts.impedancesRange") }}:
          <div class="hide-in-print-page select is-small m-2" v-if="showCharts">
            <select @change="setDefaultOrShowCustomImpedanceRange($event)">
              <option selected="selected" value="default">Default</option>
              <option value="custom">{{ $t("custom") }}</option>
            </select>
          </div>
          <form
            v-show="showCharts"
            id="ZrangeSelector"
            v-if="showCharts && showImpedancesCustomRange"
            v-on:submit.prevent="setImpedancesChartRange"
          >
            <label class="hide-in-print-page">{{ $t("charts.max") }}:</label>
            <div class="hide-in-print-page" v-if="showCharts">
              <input v-model="maxMeasurementChartY" type="number" />
            </div>
            <label class="hide-in-print-page">{{ $t("charts.min") }}:</label>
            <div class="hide-in-print-page">
              <input v-model="minMeasurementChartY" type="number" />
            </div>
            <br />
            <div>
              <button class="button is-success is-small fixed-width">
                {{ $t("buttons.apply") }}
              </button>
            </div>
          </form>
        </div>
        <div
          id="selectRange_Dates"
          v-show="showCharts && !isCurrentRouteForPrintHistory"
          class="m-4"
        >
          {{ $t("charts.timeSeriesRange") }}:
          <div class="hide-in-print-page select is-small m-2" v-if="showCharts">
            <select id="chartType" @change="setTimeSeriesRangeInCharts($event)">
              <option value="30">1 {{ $t("month") }}</option>
              <option value="91">3 {{ $t("month_plural") }}</option>
              <option value="182">6 {{ $t("month_plural") }}</option>
              <option value="364">12 {{ $t("month_plural") }}</option>
              <option value="30000" selected="selected">{{ $t("all") }}</option>
              <option value="custom">{{ $t("custom") }}</option>
            </select>
          </div>
        </div>
        <div class="hide-in-print-page" v-if="showDatePicker && showCharts">
          <label>{{ $t("charts.startDate") }}:</label>
          <Datetime
            type="date"
            class="theme-green"
            input-class="fixed-width"
            v-model="customStartDate"
            :min-datetime="oldestMeasurementDate"
            :max-datetime="lastMeasurementDate"
          />
        </div>
        <br v-show="showCharts" />
        <div class="hide-in-print-page" v-if="showDatePicker && showCharts">
          <label>{{ $t("charts.endDate") }}:</label>
          <Datetime
            type="date"
            class="theme-green"
            input-class="fixed-width"
            v-model="customEndDate"
            :disabled="!customStartDate"
            :min-datetime="customStartDate"
            :max-datetime="lastMeasurementDate"
          />
        </div>
        <br v-show="showCharts" />
        <div
          class="hide-in-print-page has-text-centered pl-4 pr-4"
          v-if="showDatePicker && showCharts"
        >
          <button
            class="button is-success is-small fixed-width"
            @click="setCustomTimeSeriesRange"
            :disabled="!customStartDate"
          >
            {{ $t("buttons.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/shared/Loader.vue";
import Chart from "chart.js";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";
Settings.defaultLocale = "it";
import cloneDeep from "lodash/cloneDeep";

export default {
  props: [
    "measurementsPoints",
    "isCurrentRouteForPrintHistory",
    "customImpedanceRange",
    "customTimeSeriesRange",
  ],
  components: {
    Datetime,
    Loader,
  },
  emits: ["handleChangeTimeSeriesRange", "handleChangeImpedanceRange"],
  data() {
    return {
      fieldPositionInChartData: [
        "trend",
        "movingAverageRrs",
        "movingAverageXrs",
        "movingAverageEfl",
        "rrs",
        "xrs",
        "efl",
      ],
      Yaxes: {
        symptomsAnomalies: 1,
        saturationLessThan90: 1,
        temperatureGreaterThan37: 1,
        increasedOxygenFlow: 1,
      },
      symptomsFields: [
        "symptomsAnomalies",
        "saturationLessThan90",
        "temperatureGreaterThan37",
        "increasedOxygenFlow",
      ],
      measurementsFields: [
        "rrs",
        "xrs",
        "efl",
        "movingAverageRrs",
        "movingAverageXrs",
        "movingAverageEfl",
        "trend",
      ],
      colors: {
        rrs: "#F5CD8D",
        xrs: "#83C5E1",
        efl: "#D6D6D6",
        symptomsAnomalies: "#C2BF7A",
        saturationLessThan90: "#0086F0",
        temperatureGreaterThan37: "#1BC29B",
        increasedOxygenFlow: "#992600",
      },
      countMeasurementsInSameDay: 0,
      measurementsMadeSameDay: [],
      pointsToPlotInSameDay: [],
      customEndDate: "",
      customStartDate: "",
      showDatePicker: false,
      showCharts: true,
      pointsToPlot: {
        date: [],
        rrs: [],
        xrs: [],
        efl: [],
        movingAverageRrs: [],
        movingAverageXrs: [],
        movingAverageEfl: [],
        trend: [],
        trendAnomalies: [],
        symptomsAnomalies: [],
        saturationLessThan90: [],
        temperatureGreaterThan37: [],
        increasedOxygenFlow: [],
        symptomsAnomaliesLine: [],
        saturationLessThan90Line: [],
        temperatureGreaterThan37Line: [],
        increasedOxygenFlowLine: [],
      },
      measurementsChart: {},
      symptomsChart: {},
      minMeasurementChartY: 0,
      maxMeasurementChartY: 0,
      fixedMinMeasurementChartY: 0,
      fixedMaxMeasurementChartY: 0,
      showImpedancesCustomRange: false,
      roundValue: 1.05,
    };
  },
  methods: {
    displayCharts() {
      if (this.arePointsToPlotPresent()) {
        this.fillPointsToPlotWithMissingDateAndSetMeasurementsMadeSameDay();
        this.setAndDisplayMeasurementsChart();
        if (this.measurementsMadeSameDay)
          this.fillPointsToPlotInSameDayAndSetCharts();
        this.setYaxesInputAndChart();
        if (this.areSymptomsPresent()) this.setSymptomsInMeasurementsChart();
        this.setRangeToShowInChart();
      }
    },

    setRangeToShowInChart() {
      this.set90DaysRangeInChart();
      if (this.isCustomTimeSeriesSet())
        this.setCustomTimeSeriesForReportInChart();
      if (this.isCustomImpedanceSet())
        this.setCustomImpedaneceForReportInChart();
    },

    setCustomTimeSeriesForReportInChart() {
      const startDateIdx = this.customTimeSeriesRange.startDateIdx;
      const endDateIdx = this.customTimeSeriesRange.endDateIdx;
      this.setChartTimeSeriesRange(startDateIdx, endDateIdx);
    },

    setCustomImpedaneceForReportInChart() {
      const minY = this.customImpedanceRange.minY;
      const maxY = this.customImpedanceRange.maxY;
      this.setMinAndMaxYinMeasurementsChart(minY, maxY);
      this.measurementsChart.update();
    },

    isCustomTimeSeriesSet() {
      return (
        this.customTimeSeriesRange?.startDateIdx ||
        this.customTimeSeriesRange?.endDateIdx
      );
    },

    isCustomImpedanceSet() {
      return this.customImpedanceRange?.minY || this.customImpedanceRange?.maxY;
    },

    setYaxesInputAndChart() {
      const min = this.getMinAndMaxInPointsToPlot()[0];
      let max = 0;
      if (this.areSymptomsPresent()) max = this.Yaxes.increasedOxygenFlow;
      else max = this.getMinAndMaxInPointsToPlot()[1];

      this.setInputMinAndMaxY(min, max);
      this.setMinAndMaxYinMeasurementsChart(min, max);
    },
    set90DaysRangeInChart() {
      if (this.arePointsToPlotPresent() || this.areSymptomsPresent()) {
        const startDateIdx = this.pointsToPlot["date"].length - 1;
        const endDateIdx = startDateIdx - 91;
        this.setChartTimeSeriesRange(startDateIdx, endDateIdx);
      }
    },
    setDefaultOrShowCustomImpedanceRange(event) {
      if (event.target.value === "custom")
        this.showImpedancesCustomRange = true;
      else {
        this.resetYinputValue();
        this.setImpedancesChartRange();
        this.showImpedancesCustomRange = false;
      }
    },
    resetYinputValue() {
      this.minMeasurementChartY = this.fixedMinMeasurementChartY;
      this.maxMeasurementChartY = this.fixedMaxMeasurementChartY;
    },
    setImpedancesChartRange() {
      this.setMinAndMaxYinMeasurementsChart(
        this.minMeasurementChartY,
        this.maxMeasurementChartY
      );
      this.$emit("handleChangeImpedanceRange", {
        minY: this.minMeasurementChartY,
        maxY: this.maxMeasurementChartY,
      });
      this.measurementsChart.update();
    },
    parseDateItToISO(date) {
      const splittedDate = date.split("/");
      return new Date(
        parseInt(splittedDate[2]),
        parseInt(splittedDate[1]) - 1,
        parseInt(splittedDate[0]) + 1
      )
        .toISOString()
        .substring(0, 10);
    },
    setTimeSeriesRangeInCharts(event) {
      if (event.target.value === "custom") this.showDatePicker = true;
      else {
        const startDateIdx = this.pointsToPlot["date"].length - 1;
        let endDateIdx = startDateIdx - parseInt(event.target.value);
        endDateIdx = endDateIdx > 0 ? endDateIdx : 0;
        this.showDatePicker = false;
        this.setChartTimeSeriesRange(startDateIdx, endDateIdx);

        this.$emit("handleChangeTimeSeriesRange", {
          startDateIdx: startDateIdx,
          endDateIdx: endDateIdx,
          oldestDate: this.parseDateItToISO(this.pointsToPlot.date[endDateIdx]),
        });
      }
    },
    setCustomTimeSeriesRange() {
      const dateIdx = (date) =>
        this.getIdxOfDateInPointsToPlot(this.formatDate(date));
      const startDateIdx = dateIdx(this.customEndDate);
      const endDateIdx = dateIdx(this.customStartDate);
      this.setChartTimeSeriesRange(startDateIdx, endDateIdx);
      this.$emit("handleChangeTimeSeriesRange", {
        startDateIdx: startDateIdx,
        endDateIdx: endDateIdx,
        newestDate: this.parseDateItToISO(this.pointsToPlot.date[startDateIdx]),
        oldestDate: this.parseDateItToISO(this.pointsToPlot.date[endDateIdx]),
      });
    },
    getIdxOfDateInPointsToPlot(date) {
      let dateIdx = undefined;
      this.pointsToPlot["date"].filter((d, idx) => {
        if (d == date) dateIdx = idx;
      });
      if (dateIdx) return dateIdx;
      else console.error("Error: date not found");
    },
    setChartTimeSeriesRange(startDateIdx, endDateIdx) {
      if (endDateIdx < 0) endDateIdx = 0;
      const getDataInRange = (data) =>
        this.pointsToPlot[data].slice(endDateIdx, startDateIdx + 1);

      const dateRange = getDataInRange("date");
      this.measurementsChart.data.labels = dateRange;
      //E' stato necessario creare questo oggetto perchè
      //l'ordine in cui vengono dichiarati i grafici all'interno di
      //chart data, modifica la loro "priorità di visualizzazione"
      //Il trend è stato posizionato per primo poichè dev'essere
      //l'elemento in primo piano ma ha bisogno degli altri valori
      //per essere calcolato

      let min = 0;
      let max = 0;
      let trendRange = 0;

      this.measurementsFields.forEach((field) => {
        const fieldRange = getDataInRange(field);
        if (field != "trend") {
          const localMax = Math.max(...fieldRange);
          max = localMax > max ? localMax : max;
          const localMin = Math.min(...fieldRange);
          min = localMin < min ? localMin : min;

          const fieldIdx = this.fieldPositionInChartData.indexOf(field);
          this.measurementsChart.data.datasets[fieldIdx].data = fieldRange;
        } else trendRange = fieldRange;
      });
      if (this.pointsToPlotInSameDay) {
        this.pointsToPlotInSameDay.forEach((f) => {
          ["rrs", "xrs", "efl"].forEach((d) => {
            const fieldRange = f[d].slice(endDateIdx, startDateIdx + 1);

            if (d != "trend") {
              const localMax = Math.max(...fieldRange);
              max = localMax > max ? localMax : max;
              const localMin = Math.min(...fieldRange);
              min = localMin < min ? localMin : min;
            }
          });
        });
      }

      const minMax = this.getMinAndMaxInPointsToPlotRange(
        endDateIdx,
        startDateIdx + 1
      );
      const trendAnomalies = this.getTrendAnomaliesWithMinMax(
        trendRange,
        minMax
      );

      this.measurementsChart.data.datasets[0].data = trendAnomalies;
      this.updateSymptomsValue(max, endDateIdx, startDateIdx + 1);
      if (this.areSymptomsPresent()) max = this.Yaxes.increasedOxygenFlow;
      this.setMinAndMaxYinMeasurementsChart(min, max);
      this.measurementsChart.update();
    },

    updateMeasurementsFieldsRangeInChart() {},

    updateSymptomsValue(max, endDateIdx, startDateIdx) {
      this.setSymptomsYaxesValue(max);
      this.setPointsToPlotSymptomsWithYAxesValue();
      this.updateSymptomsChartDataWithPointsToPlot(endDateIdx, startDateIdx);
    },
    setPointsToPlotSymptomsWithYAxesValue() {
      this.symptomsFields.forEach((f) => {
        let points = [];
        let pointsLine = [];
        this.pointsToPlot[f].forEach((d) => {
          points.push(d ? this.Yaxes[f] : null);
        });
        this.pointsToPlot[f + "Line"].forEach((d) => {
          pointsLine.push(d ? this.Yaxes[f] : null);
        });
        this.pointsToPlot[f + "Line"] = pointsLine;
        this.pointsToPlot[f] = points;
      });
    },
    updateSymptomsChartDataWithPointsToPlot(eD, sD) {
      this.measurementsChart.data.datasets.forEach((e, idx) => {
        if (e.pointBackgroundColor == "#992600")
          this.measurementsChart.data.datasets[
            idx
          ].data = this.pointsToPlot.increasedOxygenFlow.slice(eD, sD);
        else if (e.pointBackgroundColor == "#1BC29B")
          this.measurementsChart.data.datasets[
            idx
          ].data = this.pointsToPlot.temperatureGreaterThan37.slice(eD, sD);
        else if (e.pointBackgroundColor == "#0086F0")
          this.measurementsChart.data.datasets[
            idx
          ].data = this.pointsToPlot.saturationLessThan90.slice(eD, sD);
        else if (e.pointBackgroundColor == "#C2BF7A")
          this.measurementsChart.data.datasets[
            idx
          ].data = this.pointsToPlot.symptomsAnomalies.slice(eD, sD);
        else if (e.borderColor == "#1BC29C") {
          this.measurementsChart.data.datasets[
            idx
          ].data = this.pointsToPlot.temperatureGreaterThan37Line.slice(eD, sD);
        } else if (e.borderColor == "#0086F1") {
          this.measurementsChart.data.datasets[
            idx
          ].data = this.pointsToPlot.saturationLessThan90Line.slice(eD, sD);
        } else if (e.borderColor == "#C2BF7B") {
          this.measurementsChart.data.datasets[
            idx
          ].data = this.pointsToPlot.symptomsAnomaliesLine.slice(eD, sD);
        } else if (e.borderColor == "#992601") {
          this.measurementsChart.data.datasets[
            idx
          ].data = this.pointsToPlot.increasedOxygenFlowLine.slice(eD, sD);
        }
      });
    },
    setTrendAnomaliesInMeasurementsChart(measurementsValues) {
      this.measurementsChart.data.datasets[0].data = this.getTrendAnomaliesWithMinMax(
        measurementsValues
      );
    },
    getMovingAverage(pointsToPlot, data) {
      let movingAveragePoints = [];
      const numOfMeasurements = pointsToPlot[data].length - 1;
      for (let idx = numOfMeasurements; idx > -1; idx--) {
        if (!pointsToPlot[data][idx]) movingAveragePoints[idx] = null;
        else {
          let sum = 0;
          let counterValidMeasurements = 0;
          const timeWindow = idx - 7;
          for (let idxInt = idx; idxInt !== timeWindow; idxInt--) {
            if (pointsToPlot[data][idxInt]) {
              counterValidMeasurements++;
              sum += pointsToPlot[data][idxInt];
            }
          }
          if (counterValidMeasurements > 4) {
            const average = sum / counterValidMeasurements;
            movingAveragePoints[idx] = average.toFixed(2);
          } else movingAveragePoints[idx] = null;
        }
      }
      return movingAveragePoints;
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("it-IT").format(new Date(date));
    },
    fillPointsToPlotWithMissingDateAndSetMeasurementsMadeSameDay() {
      const startDate = this.formatDate(this.measurementsPoints["date"][0]);
      let daysToTakeOff = 1;
      let labelDate = startDate;
      let idx = 0;
      let measurementIdx = 0;

      while (this.measurementsPoints["date"].length !== idx) {
        const measurementDate = this.getMeasurementDate(idx);
        if (labelDate != measurementDate && measurementIdx) {
          labelDate = this.getDateOfXDaysAgo(startDate, daysToTakeOff++);
          this.countMeasurementsInSameDay = 0;
        }
        if (!this.countMeasurementsInSameDay)
          this.addDateAndValuesToPointsToPlot(labelDate, measurementDate, idx);
        else this.addDateAndValuesToMeasurementsMadeSameDay(measurementIdx);
        idx = labelDate == measurementDate ? idx + 1 : idx;
        this.countMeasurementsInSameDay++;
        measurementIdx++;
      }
      const pointsToPlotCopy = cloneDeep(this.pointsToPlot);
      this.setMovingAveragesInPointsToPlot(pointsToPlotCopy);
    },
    getMeasurementDate(idx) {
      return this.formatDate(this.measurementsPoints["date"][idx]);
    },

    addDateAndValuesToPointsToPlot(labelDate, measurementDate, idx) {
      this.pointsToPlot.date.unshift(labelDate);
      this.addMeasurementValueToPointsToPlot(labelDate == measurementDate, idx);
      if (this.areSymptomsPresent())
        this.addSymptomsToPointsToPlot(labelDate == measurementDate, idx);
    },
    addDateAndValuesToMeasurementsMadeSameDay(measurementIdx) {
      const measurementData = this.getMeasurementMadeInSameDay(measurementIdx);
      this.measurementsMadeSameDay.push(measurementData);
    },

    getMeasurementMadeInSameDay(idx) {
      let symptoms;
      if (this.areSymptomsPresent()) {
        const getSymptomValue = (field, idx) =>
          this.measurementsPoints[field][idx] == "anomaly"
            ? this.Yaxes[field]
            : null;

        const getSymptomLineValue = (field, idx) =>
          this.measurementsPoints[field][idx] != "unknown"
            ? this.Yaxes[field]
            : null;

        symptoms = {
          symptomsAnomalies: getSymptomValue("symptomsAnomalies", idx),
          saturationLessThan90: getSymptomValue("saturationLessThan90", idx),
          temperatureGreaterThan37: getSymptomValue(
            "temperatureGreaterThan37",
            idx
          ),
          increasedOxygenFlow: getSymptomValue("increasedOxygenFlow", idx),
          symptomsAnomaliesLine: getSymptomLineValue("symptomsAnomalies", idx),
          saturationLessThan90Line: getSymptomLineValue(
            "saturationLessThan90",
            idx
          ),
          temperatureGreaterThan37Line: getSymptomLineValue(
            "temperatureGreaterThan37",
            idx
          ),
          increasedOxygenFlowLine: getSymptomLineValue(
            "increasedOxygenFlow",
            idx
          ),
        };
      }
      const measurements = {
        chart: this.countMeasurementsInSameDay - 1,
        measurmentIndex: this.pointsToPlot.date.length - 1,
        rrs: this.measurementsPoints.rrs[idx],
        xrs: this.measurementsPoints.xrs[idx],
        efl: this.measurementsPoints.efl[idx],
        trend: this.measurementsPoints.trend[idx],
      };
      return Object.assign({}, symptoms, measurements);
    },

    fillPointsToPlotInSameDayAndSetCharts() {
      const totalMeasurements = this.pointsToPlot["date"].length - 1;
      this.initializePointsToPlotInSameDay(totalMeasurements);
      this.fillPointsToPlotInSameDay(totalMeasurements);
      this.addPointsToPlotInSameDayToCharts();
      if (this.areSymptomsPresent()) this.mergeSymptomsWithExisting();
    },

    initializePointsToPlotInSameDay(totalMeasurements) {
      const chartsToCreate = this.getChartsToCreateForPointsToPlotInSameDay();
      let arr = new Array(totalMeasurements).fill(null);

      chartsToCreate.forEach(() => {
        const measurementsValue = this.getCloneOfMeasurementsObject(arr);
        let symptoms = {};
        if (this.areSymptomsPresent())
          symptoms = this.getCloneOfSymptomsObject(arr);
        this.pointsToPlotInSameDay.unshift(
          cloneDeep(Object.assign({}, measurementsValue, symptoms))
        );
      });
    },

    getCloneOfMeasurementsObject(arr) {
      return {
        rrs: cloneDeep(arr),
        xrs: cloneDeep(arr),
        efl: cloneDeep(arr),
        trend: cloneDeep(arr),
      };
    },

    getCloneOfSymptomsObject(arr) {
      return {
        symptomsAnomalies: cloneDeep(arr),
        saturationLessThan90: cloneDeep(arr),
        temperatureGreaterThan37: cloneDeep(arr),
        increasedOxygenFlow: cloneDeep(arr),
        symptomsAnomaliesLine: cloneDeep(arr),
        saturationLessThan90Line: cloneDeep(arr),
        temperatureGreaterThan37Line: cloneDeep(arr),
        increasedOxygenFlowLine: cloneDeep(arr),
      };
    },

    getChartsToCreateForPointsToPlotInSameDay() {
      let chartsToCreate = [];
      this.measurementsMadeSameDay.forEach((m) => {
        if (m.chart >= chartsToCreate.length - 1) chartsToCreate.push(m.chart);
      });
      return chartsToCreate;
    },

    fillPointsToPlotInSameDay(totalMeasurements) {
      this.measurementsMadeSameDay.forEach((m) => {
        const idx = totalMeasurements - m.measurmentIndex;
        ["rrs", "xrs", "efl", "trend"].forEach((field) => {
          this.pointsToPlotInSameDay[m.chart][field][idx] = m[field];
        });
        if (this.areSymptomsPresent()) {
          this.symptomsFields.forEach((field) => {
            this.pointsToPlotInSameDay[m.chart][field][idx] = m[field];
          });
        }
      });
    },

    addPointsToPlotInSameDayToCharts() {
      this.pointsToPlotInSameDay.forEach((p) => {
        this.addPointsToPlotInSameDayToMeasurementsChart(p);
      });
    },

    addPointsToPlotInSameDayToMeasurementsChart(pointsToPlotInSameDay) {
      ["rrs", "xrs", "efl"].forEach((field) => {
        this.measurementsChart.data.datasets.push(
          this.getMeasurementDataset(field, pointsToPlotInSameDay[field])
        );
      });
      this.mergeTrendAnomaliesWithExisting(pointsToPlotInSameDay);
    },

    mergeTrendAnomaliesWithExisting(pointsToPlot) {
      let trend = [];
      pointsToPlot.trend.forEach((p, idx) => {
        trend.push(this.pointsToPlot.trend[idx] || p);
      });
      this.setTrendAnomaliesInMeasurementsChart(trend);
    },

    setSymptomsYaxesValue(max) {
      if (typeof max == "string") max = parseFloat(max);
      this.Yaxes.symptomsAnomalies = (max + (max / 10) * 0.5).toFixed(2);
      this.Yaxes.saturationLessThan90 = (max + (max / 10) * 1).toFixed(2);
      this.Yaxes.temperatureGreaterThan37 = (max + (max / 10) * 1.5).toFixed(2);
      this.Yaxes.increasedOxygenFlow = (max + (max / 10) * 2).toFixed(2);
    },

    mergeSymptomsWithExisting() {
      const max = this.getMinAndMaxInPointsToPlot()[1];
      this.setSymptomsYaxesValue(max);
      this.updatePointsToPlotSymptomsWithYaxesValues();
      this.fillSymptomsLineInPreviousDayToDrawLine24h();
    },

    fillSymptomsLineInPreviousDayToDrawLine24h() {
      this.symptomsFields.forEach((field) => {
        if (this.pointsToPlot[field + "Line"].length > 0) {
          this.pointsToPlot[field + "Line"].forEach((e, idx) => {
            if (e)
              this.pointsToPlot[field + "Line"][idx - 1] = this.Yaxes[field];
          });
        }
      });
    },

    updatePointsToPlotSymptomsWithYaxesValues() {
      this.pointsToPlotInSameDay.forEach((p) => {
        this.symptomsFields.forEach((field) => {
          let symtpoms = [];
          let symptomsLine = [];
          this.pointsToPlot[field].forEach((e, idx) => {
            symtpoms.push(p[field][idx] || e ? this.Yaxes[field] : null);
            symptomsLine.push(
              p[field + "Line"][idx] || e ? this.Yaxes[field] : null
            );
          });

          this.pointsToPlot[field] = symtpoms;
          this.pointsToPlot[field + "Line"] = symptomsLine;
        });
      });
    },

    getMeasurementDataset(field, values) {
      return {
        label: field == "efl" ? "Δxrs *" : field + " insp *",
        data: values,
        borderColor: this.colors[field],
        borderWidth: 1,
        pointBackgroundColor: this.colors[field],
        showLine: false,
        fill: false,
      };
    },

    getSymptomsDataset(field, values) {
      return {
        label: field + " *",
        data: values,
        borderColor: this.colors[field],
        borderWidth: 2,
        pointBackgroundColor: this.colors[field],
        showLine: false,
        fill: false,
        pointStyle: "rect",
      };
    },

    addMeasurementValueToPointsToPlot(isDataToBeCopied, idx) {
      const getValue = (data) =>
        isDataToBeCopied ? this.measurementsPoints[data][idx] : null;

      ["rrs", "xrs", "efl", "trend"].forEach((field) => {
        this.pointsToPlot[field].unshift(getValue(field));
      });
    },

    addSymptomsToPointsToPlot(isDataToBeCopied, idx) {
      const getValue = (data) =>
        isDataToBeCopied ? this.measurementsPoints[data][idx] : null;

      this.symptomsFields.forEach((field) => {
        const f = getValue(field);

        this.pointsToPlot[field].unshift(
          f && f == "anomaly" ? this.Yaxes[field] : null
        );

        this.pointsToPlot[field + "Line"].unshift(
          f && f !== "unknown" ? this.Yaxes[field] : null
        );
      });
    },

    setMovingAveragesInPointsToPlot(measurmentsValue) {
      const movingAverage = (data) =>
        this.getMovingAverage(measurmentsValue, data);

      this.pointsToPlot.movingAverageRrs = movingAverage("rrs");
      this.pointsToPlot.movingAverageXrs = movingAverage("xrs");
      this.pointsToPlot.movingAverageEfl = movingAverage("efl");
    },

    getTrendAnomaliesWithMinMax(trendRange, minMax) {
      let trendAnomalies = [];
      trendRange.forEach((trend) => trendAnomalies.push(trend ? minMax : null));
      return trendAnomalies;
    },

    setMinAndMaxYinMeasurementsChart(min, max) {
      this.measurementsChart.options.scales.yAxes[0].ticks.min =
        min * this.roundValue;
      this.measurementsChart.options.scales.yAxes[0].ticks.max = Math.ceil(
        max * this.roundValue
      );
    },

    getMinAndMaxInPointsToPlot() {
      let max = 0;
      let min = 0;

      ["rrs", "xrs", "efl"].forEach((f) => {
        const data = this.pointsToPlot[f];
        const localMax = Math.max(...data);
        max = localMax > max ? localMax : max;
        const localMin = Math.min(...data);
        min = localMin < min ? localMin : min;
      });

      if (this.pointsToPlotInSameDay) {
        this.pointsToPlotInSameDay.forEach((p) => {
          ["rrs", "xrs", "efl"].forEach((f) => {
            const localMax = Math.max(...p[f]);
            max = localMax > max ? localMax : max;
            const localMin = Math.min(...p[f]);
            min = localMin < min ? localMin : min;
          });
        });
      }
      return [min, max];
    },

    getMinAndMaxInPointsToPlotRange(endDateIdx, startDateIdx) {
      let max = 0;
      let min = 0;
      ["rrs", "xrs", "efl"].forEach((f) => {
        const data = this.pointsToPlot[f].slice(endDateIdx, startDateIdx);
        const localMax = Math.max(...data);
        max = localMax > max ? localMax : max;
        const localMin = Math.min(...data);
        min = localMin < min ? localMin : min;
      });

      if (this.pointsToPlotInSameDay) {
        this.pointsToPlotInSameDay.forEach((p) => {
          ["rrs", "xrs", "efl"].forEach((f) => {
            const localMax = Math.max(...p[f].slice(endDateIdx, startDateIdx));
            max = localMax > max ? localMax : max;
            const localMin = Math.min(...p[f].slice(endDateIdx, startDateIdx));
            min = localMin < min ? localMin : min;
          });
        });
      }
      return [min, max];
    },

    getDateOfXDaysAgo(date, days) {
      const split = date.split("/");
      const dateSplitted = split[1] + "/" + split[0] + "/" + split[2];
      return new Intl.DateTimeFormat("it-IT").format(
        new Date(dateSplitted).setDate(new Date(dateSplitted).getDate() - days)
      );
    },
    printReport() {
      window.print();
    },
    goBack() {
      window.history.back();
    },
    measurementsChartSettings() {
      return {
        type: "line",
        data: {
          labels: this.pointsToPlot["date"],
          datasets: [
            {
              type: "bar",
              label: this.$t("charts.trendAnomalies"),
              data: this.pointsToPlot["anomalies"],
              borderColor: "rgba(255, 0, 0, 0.0)",
              backgroundColor: "rgba(255, 0, 0, 0.4)",
              borderWidth: 2,
              radius: 0,
              barPercentage: 1.3,
              pointStyle: "rect",
            },
            {
              label: this.$t("charts.averageRrs"),
              data: this.pointsToPlot["movingAverageRrs"],
              borderColor: "#FF8A00",
              borderWidth: 2,
              radius: 0,
              fill: false,
              pointStyle: "line",
            },
            {
              label: this.$t("charts.averageXrs"),
              data: this.pointsToPlot["movingAverageXrs"],
              borderColor: "#00B2FF",
              borderWidth: 2,
              radius: 0,
              fill: false,
              pointStyle: "line",
            },
            {
              label: this.$t("charts.averageDxrs"),
              data: this.pointsToPlot["movingAverageEfl"],
              borderColor: "#949494",
              borderWidth: 2,
              radius: 0,
              fill: false,
              pointStyle: "line",
            },
            {
              label: "Rrs insp",
              data: this.pointsToPlot["rrs"],
              borderColor: "#F5CD8D",
              borderWidth: 1,
              pointBackgroundColor: "#F5CD8D",
              showLine: false,
              fill: false,
              pointStyle: "circle",
            },
            {
              label: "Xrs insp",
              data: this.pointsToPlot["xrs"],
              borderColor: "#83C5E1",
              borderWidth: 1,
              pointBackgroundColor: "#83C5E1",
              showLine: false,
              fill: false,
              pointStyle: "circle",
            },
            {
              label: "Δxrs",
              data: this.pointsToPlot["efl"],
              borderColor: "#D6D6D6",
              borderWidth: 1,
              pointBackgroundColor: "#D6D6D6",
              showLine: false,
              fill: false,
              pointStyle: "circle",
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                //Remove first and last y labels
                afterTickToLabelConversion: (scaleInstance) => {
                  // set the first and last tick to null so it does not display
                  // note, ticks[0] is the last tick and ticks[length - 1] is the first
                  scaleInstance.ticks[0] = null;
                  scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;

                  // need to do the same thing for this similiar array which is used internally
                  scaleInstance.ticksAsNumbers[0] = null;
                  scaleInstance.ticksAsNumbers[
                    scaleInstance.ticksAsNumbers.length - 1
                  ] = null;
                },
                scaleLabel: {
                  display: true,
                  labelString: this.$t("charts.scaleLabel"),
                },
                //If there are symptoms, hide last tick
                ticks: {
                  callback: function (value, index) {
                    if (index === 1) {
                      if (this.chart?.data?.datasets) {
                        //Search symptoms by color becouse labels contains dictionary
                        this.chart.data.datasets.forEach((e, idx) => {
                          if (
                            [
                              "#0086F0",
                              "#1BC29B",
                              "#992600",
                              "#C2BF7A",
                            ].includes(e.borderColor) &&
                            !this.chart.getDatasetMeta(idx).hidden
                          ) {
                            value = "";
                          }
                        });
                      }
                    }
                    return value;
                  },
                },
              },
            ],
            xAxes: [
              {
                afterFit: (axis) => {
                  axis.paddingTop = 0;
                },
                ticks: {
                  maxTicksLimit: 30,
                  stepSize: 1,
                  max: 30,
                  callback: function (value) {
                    const splitDate = value.split("/");
                    const last2DigitOfYear = splitDate[2].substr(2, 4);
                    return (
                      splitDate[0] + "/" + splitDate[1] + "/" + last2DigitOfYear
                    );
                  },
                },
              },
            ],
          },
          tooltips: {
            custom: function (tooltipModel) {
              const trendAnomalies = "Trend anomalies";
              if (
                tooltipModel.body &&
                tooltipModel.body[0].lines &&
                (tooltipModel.body[0].lines[0].includes(trendAnomalies) ||
                  tooltipModel.body[0].lines[0].includes("Symptoms anomalies"))
              ) {
                tooltipModel.opacity = 0;
              }
            },
          },
          legend: {
            position: "right",
            labels: {
              filter: function (item) {
                return !item.text.includes("*");
              },
              usePointStyle: true,
            },
            onClick: function (e, legendItem) {
              // const index = legendItem.datasetIndex;
              const hideChartPoints = (idx) => {
                this.chart.getDatasetMeta(
                  idx
                ).hidden = !this.chart.getDatasetMeta(idx).hidden;
              };

              const calculateAndSetMinAndMaxY = () => {
                let min = 0,
                  max = 0;
                if (this.chart.data.datasets) {
                  this.chart.data.datasets.forEach((e, idx) => {
                    const values = this.chart.data.datasets[idx].data;
                    if (
                      e.data &&
                      !e.type &&
                      this.chart.getDatasetMeta(idx) &&
                      !this.chart.getDatasetMeta(idx).hidden
                    ) {
                      const localMax = Math.max(...values);
                      max = localMax > max ? localMax : max;
                      const localMin = Math.min(...values);
                      min = localMin < min ? localMin : min;
                    }
                  });
                }
                if (min == 0 && max == 0) {
                  min = -1;
                  max = 1;
                }
                this.chart.options.scales.yAxes[0].ticks.min = min;
                this.chart.options.scales.yAxes[0].ticks.max = Math.ceil(max);
              };

              const excludeAverageChart = (c) =>
                c.label.toLowerCase().trim().length < legend.length + 8;

              const legend = legendItem.text.toLowerCase().trim();
              if (this.chart.data.datasets) {
                this.chart.data.datasets.forEach((c, idx) => {
                  if (c.label.trim() == "Δxrs *" && legend == "δxrs")
                    hideChartPoints(idx);
                  else if (
                    c.label.toLowerCase().trim().includes(legend) &&
                    excludeAverageChart(c)
                  )
                    hideChartPoints(idx);
                });
              }

              calculateAndSetMinAndMaxY();
              this.chart.update();
            },
          },
        },
      };
    },

    areSymptomsPresent() {
      return (
        this.measurementsPoints &&
        this.measurementsPoints.symptomsAnomalies &&
        (this.measurementsPoints.symptomsAnomalies.includes("stable") ||
          this.measurementsPoints.symptomsAnomalies.includes("anomaly"))
      );
    },

    arePointsToPlotPresent() {
      return (
        this.measurementsPoints &&
        this.measurementsPoints.rrs &&
        this.measurementsPoints?.rrs.length > 0
      );
    },

    setAndDisplayMeasurementsChart() {
      const ctx = document
        .getElementById("measurements-chart")
        .getContext("2d");
      this.measurementsChart = new Chart(ctx, this.measurementsChartSettings());
    },

    setSymptomsInMeasurementsChart() {
      this.measurementChartDatasetFirstSymptomField = this.measurementsChart.data.datasets.length;

      this.measurementsChart.data.datasets.push(
        this.symptomsAnomaliesDatasets()
      );
      this.measurementsChart.data.datasets.push(
        this.saturationLessThan90Datasets()
      );
      this.measurementsChart.data.datasets.push(
        this.temperatureGreaterThan37Datasets()
      );
      this.measurementsChart.data.datasets.push(
        this.increasedOxygenFlowDatasets()
      );

      this.measurementsChart.data.datasets.push(
        this.symptomsAnomaliesLineDatasets()
      );
      this.measurementsChart.data.datasets.push(
        this.saturationLessThan90LineDatasets()
      );

      this.measurementsChart.data.datasets.push(
        this.temperatureGreaterThan37LineDatasets()
      );

      this.measurementsChart.data.datasets.push(
        this.increasedOxygenFlowLineDatasets()
      );
    },

    symptomsAnomaliesDatasets() {
      return {
        id: "symptomsAnomalies",
        label: this.$t("charts.symptomsAnomalies"),
        data: this.pointsToPlot["symptomsAnomalies"],
        borderColor: "#C2BF7A",
        borderWidth: 2,
        pointBackgroundColor: "#C2BF7A",
        fill: false,
        showLine: false,
        pointStyle: "rect",
      };
    },

    symptomsAnomaliesLineDatasets() {
      return {
        id: "symptomsAnomaliesLine",
        label: this.$t("charts.symptomsAnomalies") + " *",
        data: this.pointsToPlot["symptomsAnomaliesLine"],
        borderColor: "#C2BF7B",
        borderWidth: 2,
        radius: 0,
        fill: false,
        pointStyle: "line",
      };
    },

    saturationLessThan90Datasets() {
      return {
        id: "saturationLessThan90",
        label: this.$t("charts.saturationLessThan90"),
        data: this.pointsToPlot["saturationLessThan90"],
        borderColor: "#0086F0",
        borderWidth: 2,
        pointBackgroundColor: "#0086F0",
        fill: false,
        showLine: false,
        pointStyle: "rect",
      };
    },

    saturationLessThan90LineDatasets() {
      return {
        id: "saturationLessThan90Line",
        label: this.$t("charts.saturationLessThan90") + " *",
        data: this.pointsToPlot["saturationLessThan90Line"],
        borderColor: "#0086F1",
        borderWidth: 2,
        radius: 0,
        fill: false,
        pointStyle: "line",
      };
    },

    temperatureGreaterThan37Datasets() {
      return {
        id: "temperatureGreaterThan37",
        label: this.$t("charts.temperatureGreaterThan37"),
        data: this.pointsToPlot["temperatureGreaterThan37"],
        borderColor: "#1BC29B",
        borderWidth: 2,
        pointBackgroundColor: "#1BC29B",
        fill: false,
        showLine: false,
        pointStyle: "rect",
      };
    },

    temperatureGreaterThan37LineDatasets() {
      return {
        id: "temperatureGreaterThan37Line",
        label: this.$t("charts.temperatureGreaterThan37") + " *",
        data: this.pointsToPlot["temperatureGreaterThan37Line"],
        borderColor: "#1BC29C",
        borderWidth: 2,
        radius: 0,
        fill: false,
        pointStyle: "line",
      };
    },

    increasedOxygenFlowDatasets() {
      return {
        id: "increasedOxygenFlow",
        label: this.$t("charts.increasedOxygenFlow"),
        data: this.pointsToPlot["increasedOxygenFlow"],
        borderColor: "#992600",
        borderWidth: 2,
        pointBackgroundColor: "#992600",
        fill: false,
        showLine: false,
        pointStyle: "rect",
      };
    },

    increasedOxygenFlowLineDatasets() {
      return {
        id: "increasedOxygenFlowLine",
        label: this.$t("charts.increasedOxygenFlow") + " *",
        data: this.pointsToPlot["increasedOxygenFlowLine"],
        borderColor: "#992601",
        borderWidth: 2,
        radius: 0,
        fill: false,
        pointStyle: "line",
      };
    },

    setInputMinAndMaxY(min, max) {
      this.fixedMinMeasurementChartY = Math.round(min);
      this.fixedMaxMeasurementChartY = Math.ceil(max);
      this.minMeasurementChartY = Math.round(min);
      this.maxMeasurementChartY = Math.ceil(max);
    },
  },
  computed: {
    isLoading() {
      return !this.measurementsPoints;
    },
    lastMeasurementDate() {
      return new Date(this.measurementsPoints["date"][0]).toISOString();
    },
    oldestMeasurementDate() {
      const endDateIdx = this.measurementsPoints["date"].length - 1;
      return new Date(
        this.measurementsPoints["date"][endDateIdx]
      ).toISOString();
    },
    trendAnomalies() {
      return this.$t("charts.trendAnomalies");
    },
    averageRrs() {
      return this.$t("charts.averageRrs");
    },
    averageXrs() {
      return this.$t("charts.averageXrs");
    },
    averageDxrs() {
      return this.$t("charts.averageDxrs");
    },
    symptomsAnomalies() {
      return this.$t("charts.symptomsAnomalies");
    },
    saturationLessThan90() {
      return this.$t("charts.saturationLessThan90");
    },
    temperatureGreaterThan37() {
      return this.$t("charts.temperatureGreaterThan37");
    },
    increasedOxygenFlow() {
      return this.$t("charts.increasedOxygenFlow");
    },
  },
  watch: {
    measurementsPoints() {
      this.displayCharts();
    },
    trendAnomalies() {
      this.measurementsChart.data.datasets[0].label = this.trendAnomalies;
      this.measurementsChart.update();
    },
    averageRrs() {
      this.measurementsChart.data.datasets[1].label = this.averageRrs;
      this.measurementsChart.update();
    },
    averageXrs() {
      this.measurementsChart.data.datasets[2].label = this.averageXrs;
      this.measurementsChart.update();
    },
    averageDxrs() {
      this.measurementsChart.data.datasets[3].label = this.averageDxrs;
      this.measurementsChart.update();
    },
    symptomsAnomalies() {
      const idx = this.measurementChartDatasetFirstSymptomField;
      if (this.areSymptomsPresent()) {
        this.measurementsChart.data.datasets[
          idx
        ].label = this.symptomsAnomalies;
        this.measurementsChart.data.datasets[idx + 4].label =
          this.symptomsAnomalies + " *";
        this.measurementsChart.update();
      }
    },
    saturationLessThan90() {
      const idx = this.measurementChartDatasetFirstSymptomField;
      if (this.areSymptomsPresent()) {
        this.measurementsChart.data.datasets[
          idx + 1
        ].label = this.saturationLessThan90;
        this.measurementsChart.data.datasets[idx + 5].label =
          this.saturationLessThan90 + " *";
        this.measurementsChart.update();
      }
    },
    temperatureGreaterThan37() {
      const idx = this.measurementChartDatasetFirstSymptomField;
      if (this.areSymptomsPresent()) {
        this.measurementsChart.data.datasets[
          idx + 2
        ].label = this.temperatureGreaterThan37;
        this.measurementsChart.data.datasets[idx + 6].label =
          this.temperatureGreaterThan37 + " *";
        this.measurementsChart.update();
      }
    },
    increasedOxygenFlow() {
      const idx = this.measurementChartDatasetFirstSymptomField;
      if (this.areSymptomsPresent()) {
        this.measurementsChart.data.datasets[
          idx + 3
        ].label = this.increasedOxygenFlow;
        this.measurementsChart.data.datasets[idx + 7].label =
          this.increasedOxygenFlow + " *";
        this.measurementsChart.update();
      }
    },
  },
  mounted() {
    this.displayCharts();
  },
};
</script>
<style scoped>
input {
  max-width: 100px;
}

#canvas-container {
  position: relative;
}
</style>