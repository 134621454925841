<template>
  <div>
    <button
      v-if="hasDataBeenDownloaded == undefined ? true : hasDataBeenDownloaded"
      :class="[
        awaitingResponse ? 'is-loading' : '',
        'button',
        haveAllFieldsBeenFilledIn ? ' is-success' : 'is-light',
      ]"
      :disabled="!haveAllFieldsBeenFilledIn"
    >
      <div v-if="isDataEqualToTheSavedOnSubmit && haveAllFieldsBeenFilledIn">
        <span>{{ $t("buttons.saved") }}</span>
        <span class="icon is-small">
          <i class="fas fa-check"></i>
        </span>
      </div>
      <span v-else>{{ $t("buttons.save") }}</span>
    </button>
  </div>
</template>
<script>
export default {
  props: [
    "hasDataBeenDownloaded",
    "haveAllFieldsBeenFilledIn",
    "isDataEqualToTheSavedOnSubmit",
    "awaitingResponse",
  ],
};
</script>