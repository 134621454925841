import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/Auth/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username: localStorage.getItem("user.email") || null,
    name: localStorage.getItem("user.name") || null,
    surname: localStorage.getItem("user.surname") || null,
    role: localStorage.getItem("user.role") || null,
    client: localStorage.getItem("user.client") || null,
    cognitoUser: null
  },
  mutations: {
    MUT_saveUser(state, user) {
      state.username = user.email;
      state.name = user.name;
      state.surname = user.surname;
      state.idToken = user.idToken;
      state.role = user.role;
      state.client = user.client;
      // cache the user for when the page is reloaded
      localStorage.setItem("user.email", user.email);
      localStorage.setItem("user.name", user.name);
      localStorage.setItem("user.surname", user.surname);
      localStorage.setItem("user.role", user.role);
      localStorage.setItem("user.client", user.client);
      // localStorage.setItem("user.idToken", user.idToken); 
    },
    MUT_saveUserAttributes(state, userAttributes) {
      sessionStorage.setItem("userAttributesForNewPasswordSet", JSON.stringify(userAttributes))// will be used when the user sets a password
      console.log(`VueX store: saved temporary attributes for user in session storage: \
      ${sessionStorage.getItem("userAttributesForNewPasswordSet")}`)
      state.name = userAttributes.name;
      state.surname = userAttributes.family_name;
      state.username = userAttributes.email;
    },
    MUT_saveCognitoUser(state, cognitoUser) {
      console.log(`Vuex store: need to save CognitoUser in session to change the account password`);
      state.cognitoUser = cognitoUser;
    },
    MUT_clearUser(state) {
      state.username = null;
      state.name = null;
      state.surname = null;
      state.idToken = null;
      localStorage.removeItem("user.email");
      localStorage.removeItem("user.name");
      localStorage.removeItem("user.surname");
      localStorage.removeItem("user.role");
      localStorage.removeItem("user.client");
      sessionStorage.removeItem("userAttributesForNewPasswordSet");
    }
  },
  actions: {
    login({ commit }, { username, password }) {
      console.log(`Logging in as ${username}`)
      return new Promise((resolve, reject) => {
        auth.signIn(username, password)
          .then(userData => {
            if (userData.newPasswordRequired) {
              console.log("VueX store: Authorization Failed. New password is required for user " + JSON.stringify(userData))
              commit("MUT_saveUserAttributes", userData.userAttributes);
              commit("MUT_saveCognitoUser", userData.cognitoUser);
              resolve("new-password-required");
            } else {
              console.log("VueX store: Login operation with data: " + JSON.stringify(userData))
              if (userData.role) {
                console.log("VueX store: Authorization OK.");
                commit("MUT_saveUser", userData);
                resolve("logged-in");
              } else {
                console.log(`VueX store: Authorization forbidden, no role for user ${userData.email}.`);
                resolve("role-is-undefined")
              }
            }
          })
          .catch(error => {
            commit("MUT_clearUser");
            console.log("VueX store: Caught error during Cognito Sign-in: " + JSON.stringify(error))
            reject(JSON.stringify(error));
          });
      });
    },
    async logout({ commit }) {
      auth.signOut();
      // console.log("VueX store: Logged in status: " + this.isLoggedIn())
      console.log(`VueX store: User logged out. Cognito isAuthenticated: ${auth.isAuthenticated()};`)
      commit("MUT_clearUser");
      Promise.resolve();
    },
    async setFirstPassword({ commit }, { newPassword, newRequiredAttributes }) {
      console.log(`VueX store: dispatching operation of password change with new password equal to ${newPassword}`);
      return new Promise((resolve, reject) => {
        auth.setFirstPassword(this.state.cognitoUser, newPassword, newRequiredAttributes)
          .then(userData => {
            console.log(`VueX store: password changed successfully for ${JSON.stringify(userData)}`)
            commit("MUT_saveUser", userData);
            resolve("logged-in");
          })
          .catch(error => {
            commit("MUT_clearUser");
            console.log("VueX store: Caught error during Cognito Change Password: " + JSON.stringify(error))
            reject(JSON.stringify(error));
          })
      })
    }
  },
  getters: {
    getCompleteName: state => `${state.name} ${state.surname}`,
    getUsername: state => `${state.username}`,
    getUserRole: state => `${state.role}`,
    getUserClient: state => `${state.client}`,
    isLoggedIn: state => !!state.username,
    hasRoleMonitor: state => state.role === 'Monitor',
    hasRoleManager: state => state.role === 'Manager',
    hasRoleAdmin: state => state.role === 'Admin',
  },
})
