<template>
  <div id="app" class="container">
    <Header :currentRouteName="this.$router.currentRoute.name" />
    <main>
      <router-view />
    </main>
    <Footer v-if="displayFooter" />
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";

export default {
  data() {
    return {
      displayFooter: false,
    };
  },
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">
#app {
  // font-family: Monda, Helvetica, Arial, sans-serif; /* $family-sans-serif in bulma-custom.scss
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary-font-color;
  background-color: $restech-white; /* inside App component, in case router-view and other components have no specified backgrounds */
  margin-top: 5px;
  border: 1px solid $restech-grey-dark;
  border-radius: $radius-large;
  /* Prevent background color leak outs */
  overflow: hidden;
}
main {
  padding: 0;
  /*2em 1em;*/
}
</style>
