<template>
  <div class="columns">
    <div class="column"></div>
    <div class="column is-half">
      <form @submit.prevent="changePassword" autocomplete="off">
        <div v-if="this.mustEnterName" class="field" id="name-field">
          <label for="" class="label">Nome</label>
          <input
            v-model.trim="attributes.name"
            id="input-new-name"
            class="input is-primary"
            type="text"
            :placeholder="$t('fieldsDescription.me')"
          />
        </div>
        <div v-if="this.mustEnterName" class="field" id="surname-field">
          <label for="" class="label">Cognome</label>
          <input
            v-model.trim="attributes.family_name"
            id="input-new-surname"
            class="input is-primary"
            type="text"
            :placeholder="$t('fieldsDescription.surname')"
          />
        </div>

        <div class="field" id="password-field">
          <label for="" class="label">Imposta la tua password</label>
          <div class="control has-icons-left has-icons-right">
            <input
              v-model.trim="credentials.password"
              id="input-new-password"
              class="input is-primary"
              type="password"
              :placeholder="$t('newPassword')"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-lock"></i>
            </span>
            <span class="icon is-small is-right">
              <i id="check-icon-pwd" class="fas fa-check"></i>
            </span>
          </div>
        </div>

        <div class="field">
          <label for="" class="label">Ripeti la tua password</label>
          <div class="control has-icons-left has-icons-right">
            <input
              v-model.trim="credentials.confirmedPassword"
              class="input is-primary"
              type="password"
              :placeholder="$t('confirmNewPassword')"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-lock"></i>
            </span>
            <span class="icon is-small is-right">
              <i id="check-icon-confirm" class="fas fa-check"></i>
            </span>
          </div>
        </div>

        <p id="error-msg" class="help is-danger m-5" v-show="error">
          {{ humanReadableError }}
        </p>

        <div id="passwordComplianceChecks" class="m-5">
          <label
            v-if="mustEnterName"
            class="checkbox"
            :class="{ completed: this.nameAndSurnameProvided }"
          >
            <input
              type="checkbox"
              v-bind:checked="nameAndSurnameProvided"
              disabled
            />
            Nome e cognome inseriti </label
          ><br />
          <label
            class="checkbox"
            :class="{ completed: this.isPasswordLongEnough }"
          >
            <input
              type="checkbox"
              v-bind:checked="isPasswordLongEnough"
              disabled
            />
            Almeno 12 caratteri </label
          ><br />
          <label
            class="checkbox"
            :class="{ completed: this.isPasswordContainingNumbers }"
          >
            <input
              type="checkbox"
              v-bind:checked="isPasswordContainingNumbers"
              disabled
            />
            Usa numeri </label
          ><br />
          <label
            class="checkbox"
            :class="{ completed: this.isPasswordContainingUppercase }"
          >
            <input
              type="checkbox"
              v-bind:checked="isPasswordContainingUppercase"
              disabled
            />
            Usa caratteri maiuscoli </label
          ><br />
          <label
            class="checkbox"
            :class="{ completed: this.isPasswordContainingLowercase }"
          >
            <input
              type="checkbox"
              v-bind:checked="isPasswordContainingLowercase"
              disabled
            />
            Usa caratteri minuscoli </label
          ><br />
          <label
            class="checkbox"
            :class="{ completed: this.isPasswordContainingSpecialCharacters }"
          >
            <input
              type="checkbox"
              v-bind:checked="isPasswordContainingSpecialCharacters"
              disabled
            />
            Usa caratteri speciali
          </label>
          <label
            class="checkbox"
            :class="{ completed: this.passwordsAreEqual }"
          >
            <input
              type="checkbox"
              v-bind:checked="passwordsAreEqual"
              disabled
            />
            Le password sono uguali
          </label>
        </div>

        <div class="field mgt-large pt-6 mt-6">
          <p class="control">
            <button
              @click="loading = true"
              id="change-password-btn"
              type="submit"
              class="button is-fullwidth"
              :class="{
                'is-loading': this.loading,
                'is-primary': this.fieldsAreValid,
                greyedOut: !this.fieldsAreValid,
              }"
              v-bind:disabled="!fieldsAreValid"
            >
              Imposta password
            </button>
          </p>
        </div>
      </form>
    </div>
    <div class="column"></div>
  </div>
</template>

<script>
import api from "@/API/api";
export default {
  props: {
    username: {
      type: String,
      default: "",
    },
    mustEnterName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      credentials: {
        username: this.username, // take it from the props
        password: "",
        confirmedPassword: "",
      },
      attributes: {
        name: "",
        family_name: "", // Don't change: MUST be the same name of Cognito's required attribute
      },
      loading: false,
      error: "",
    };
  },
  computed: {
    humanReadableError() {
      return this.error && !this.emptyObject(this.error)
        ? this.error
        : "An unknown error occurred. Please try again.";
    },
    fieldsAreValid() {
      return this.passwordsAreEqual && this.isPasswordCompliant;
    },
    nameAndSurnameProvided() {
      return (
        !this.mustEnterName ||
        (this.attributes.name && this.attributes.family_name)
      );
    },
    passwordsAreEqual() {
      return (
        this.credentials.password.length > 0 &&
        this.credentials.password === this.credentials.confirmedPassword
      );
    },
    isPasswordLongEnough() {
      return this.credentials.password.length > 12;
    },
    isPasswordContainingNumbers() {
      return /\d/.test(this.credentials.password);
    },
    isPasswordContainingUppercase() {
      return /[A-Z]/.test(this.credentials.password);
    },
    isPasswordContainingLowercase() {
      return /[a-z]/.test(this.credentials.password);
    },
    isPasswordContainingSpecialCharacters() {
      return /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
        this.credentials.password
      );
    },
    isPasswordCompliant() {
      return (
        this.isPasswordLongEnough &&
        this.isPasswordContainingNumbers &&
        this.isPasswordContainingUppercase &&
        this.isPasswordContainingLowercase &&
        this.isPasswordContainingSpecialCharacters
      );
    },
  },
  methods: {
    async changePassword() {
      try {
        console.log(
          `ChangePasswordForm.vue: dispatching changePassword action for ${JSON.stringify(
            this.credentials
          )}`
        );
        this.error = undefined;
        const loginStatus = await this.$store.dispatch("setFirstPassword", {
          newPassword: this.credentials.password,
          newRequiredAttributes: this.mustEnterName ? this.attributes : null,
        });

        if (loginStatus == "logged-in") {
          const loginResponse = await api.createLoginTrail({
            username: this.credentials.username,
            status: loginStatus,
          });
          if (loginResponse.preferences) {
            localStorage.setItem("lang", loginResponse.preferences.lang);
          }
        }
        this.redirectTo(loginStatus);
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    redirectTo(loginStatus) {
      let nextView = "login";
      switch (loginStatus) {
        case "new-password-required":
          nextView = "set-password";
          break;
        case "logged-in":
          // password changed successfully
          // TODO: if we have tokens now we can proceed to dashboard, otherwise force logout
          nextView = "dashboard";
          break;
      }
      this.$router.replace(nextView);
    },
    emptyObject(o) {
      return typeof o == "object" && Object.keys(o).length == 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  text-align: left;
  color: $grey-dark;
}
</style>
<style lang="scss">
button.greyedOut {
  background-color: $grey-light;
}
#passwordComplianceChecks {
  text-align: left;
  padding-left: 20px;
}
label.completed {
  color: $primary;
}
</style>