<template>
  <div class="columns">
    <div class="column">
      <p class="is-size-5 has-text-left">
        {{ $t("totalPatients") }}: {{ this.allDashboardPatients }}
      </p>
    </div>
    <div class="column">
      <div style="max-width: 300px; max-height: 150px">
        <canvas id="patients-status-chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import measurementStatusMixin from "../shared/measurementStatusMixin";
export default {
  props: {
    patientsData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      chart: "",
    };
  },
  mixins: [measurementStatusMixin],
  methods: {
    chartData() {
      return {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [
                this.numberOfPatientsInMonitoringState,
                this.numberOfPatientsUnderSurveillance,
                this.numberOfPatientsManaged,
                this.numberOfPatientsToManage,
                this.numberOfInactivePatients,
                // this.numberOfPatientsWithNoMeasurements,
              ],
              backgroundColor: [
                "#a8b400",
                "#DEA513",
                "#5B9BD5",
                "#FF1B1B",
                "#616365",
                // "#C4C4C4",
              ],
            },
          ],
          labels: [
            this.monitoring,
            this.underSurveillance,
            this.managedOrReported,
            this.toManage,
            this.inactive,
            // `Senza misure`,
          ],
        },
        options: {
          responsive: true,
          legend: {
            position: "left",
          },
        },
      };
    },
  },
  computed: {
    monitoring() {
      return this.$t("states.monitoring");
    },
    underSurveillance() {
      return this.$t("states.underSurveillance");
    },
    managedOrReported() {
      return `${this.$t("states.managed_plural")}/${this.$t(
        "states.reported_plural"
      )}`;
    },
    toManage() {
      return this.$t("states.toManage");
    },
    inactive() {
      return this.$t("states.inactive");
    },

    allDashboardPatients() {
      return (
        this.numberOfPatientsInMonitoringState +
        this.numberOfPatientsUnderSurveillance +
        this.numberOfPatientsManaged +
        this.numberOfPatientsToManage +
        this.numberOfInactivePatients
      );
    },
    numberOfPatientsToManage() {
      return this.patientsData.filter((entry) => entry.status === "to-manage")
        .length;
    },
    numberOfPatientsUnderSurveillance() {
      return this.patientsData.filter(
        (entry) => entry.status === "under-surveillance"
      ).length;
    },
    numberOfPatientsInMonitoringState() {
      return this.patientsData.filter((entry) => entry.status === "monitoring")
        .length;
    },
    numberOfPatientsManaged() {
      return this.patientsData.filter((entry) =>
        ["managed", "reported"].includes(entry.status)
      ).length;
    },
    numberOfInactivePatients() {
      return this.patientsData.filter(
        (entry) =>
          this.statusIsInactive(entry.status) &&
          this.patientHasMeasurements(entry.status)
      ).length;
    },
    numberOfPatientsWithNoMeasurements() {
      return this.patientsData.filter(
        (entry) => !this.patientHasMeasurements(entry.status)
      ).length;
    },
  },
  watch: {
    monitoring() {
      this.chart.data.labels[0] = this.$t("states.monitoring");
      this.chart.update();
    },
    underSurveillance() {
      this.chart.data.labels[1] = this.$t("states.underSurveillance");
      this.chart.update();
    },
    managedOrReported() {
      this.chart.data.labels[2] = `${this.$t(
        "states.managed_plural"
      )}/${this.$t("states.reported_plural")}`;
      this.chart.update();
    },
    toManage() {
      this.chart.data.labels[3] = this.$t("states.toManage");
      this.chart.update();
    },
    inactive() {
      this.chart.data.labels[4] = this.$t("states.inactive");
      this.chart.update();
    },
  },
  mounted() {
    const ctx = document
      .getElementById("patients-status-chart")
      .getContext("2d");
    this.chart = new Chart(ctx, this.chartData());
  },
};
</script>

<style lang="scss" scoped>
</style>