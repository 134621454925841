<template>
  <div class="has-background-grey-light p-5">
    <Loader v-if="loading" />
    <errorModal
      :errorMessage="error.message"
      :logoutOnClose="error.forceLogout"
    />
    <div
      v-if="this.noContent && !this.loading"
      class="m-6 has-text-weight-bold"
    >
      {{ $t("warnings.noDevices") }}
    </div>
    <!-- header tabella -->
    <div v-if="!this.noContent" class="box columns m-2 p-5">
      <table class="table is-fullwidth is-fixed">
        <thead>
          <tr>
            <th rowspan="2" class="is-clipped right-border">
              <div class="field">
                <label class="label custom-font-weight">{{
                  $t("serialDevice")
                }}</label>
                <div class="control has-icons-right">
                  <input
                    v-model.trim="serialNumber"
                    @input="filterDevicesBySearchedText"
                    class="input"
                    type="text"
                    :placeholder="$t('device')"
                  />
                  <span class="icon is-small is-right">
                    <i class="fas fa-search"></i>
                  </span>
                </div>
              </div>
            </th>
            <th rowspan="2" class="is-clipped right-border">
              <div class="field">
                <label class="label custom-font-weight">{{
                  $t("associatedPatient")
                }}</label>
                <div class="control has-icons-right">
                  <input
                    v-model.trim="lastPatient"
                    @input="filterDevicesBySearchedText"
                    class="input"
                    type="text"
                    :placeholder="$t('patient')"
                  />
                  <span class="icon is-small is-right">
                    <i class="fas fa-search"></i>
                  </span>
                </div>
              </div>
            </th>
            <th rowspan="2" class="is-clipped right-border">
              <div class="field">
                <label class="label custom-font-weight">{{
                  $t("lastContat")
                }}</label>
              </div>
            </th>
            <th colspan="2" class="is-clipped right-border">
              {{ $t("connection") }}
            </th>
            <th rowspan="2" class="is-clipped right-border">
              <div class="field">
                <label class="label custom-font-weight">{{
                  $t("columns.swVersionInstalled")
                }}</label>
              </div>
            </th>
            <th rowspan="2" class="is-clipped is-borderless">
              <div class="field">
                <label class="label custom-font-weight">{{ $t("logs") }}</label>
              </div>
            </th>
          </tr>
          <tr>
            <th class="right-border">{{ $t("columns.signalStrengthPerc") }}</th>
            <th class="right-border">{{ $t("columns.apn") }}</th>
          </tr>
        </thead>
      </table>
    </div>
    <div v-show="activeDevices_filtered.length > 0" class="box columns m-2 p-5">
      <DevicesTable
        :devices="activeDevices_filtered"
        @handleOpenLogsClick="openLogs($event)"
      />
    </div>
    <div
      v-show="devicesWithSomeContact_filtered.length > 0"
      class="box columns m-2 p-5"
    >
      <DevicesTable
        :devices="devicesWithSomeContact_filtered"
        @handleOpenLogsClick="openLogs($event)"
      />
    </div>
    <div
      v-show="associatedDevices_filtered.length > 0"
      class="box columns m-2 p-5"
    >
      <DevicesTable
        :devices="associatedDevices_filtered"
        @handleOpenLogsClick="openLogs($event)"
      />
    </div>
    <div
      v-show="inactiveDevices_filtered.length > 0"
      class="box columns m-2 p-5"
    >
      <DevicesTable
        :devices="inactiveDevices_filtered"
        @handleOpenLogsClick="openLogs($event)"
      />
    </div>

    <DevicesLogsModal
      :showLogs="showLogs"
      :selectedDevice="deviceIdToShowLogs"
      @handleCloseLogsClick="closeLogs"
    />
  </div>
</template>

<script>
import api from "../API/api";
import errorMixin from "../Error/errorMixin";
import errorModal from "../Error/errorModal";
import DevicesLogsModal from "./DeviceLogsModal";
import Loader from "@/shared/Loader.vue";
import DevicesTable from "./DevicesTable";
import cloneDeep from "lodash/cloneDeep";

export default {
  data() {
    return {
      loading: false,
      allDevices: [],
      activeDevices_filtered: [],
      associatedDevices_filtered: [],
      devicesWithSomeContact_filtered: [],
      inactiveDevices_filtered: [],
      showLogs: false,
      deviceIdToShowLogs: "",
      serialNumber: "",
      lastPatient: "",
    };
  },
  components: { errorModal, Loader, DevicesTable, DevicesLogsModal },
  mixins: [errorMixin],
  methods: {
    filterDevicesBySearchedText() {
      try {
        const areDataEqualsToSearchedText = (d) => {
          let isPatientEqualToSearched;
          const isSerialNumberEqualToSearched = d["SerialNumber"]
            .toUpperCase()
            .match(this.serialNumber);

          const isPatientAnonymus = !d?.patient && !d?.patient?.PatientCode;

          if (!isPatientAnonymus && this.lastPatient.length > 0) {
            const patientCode = d.patient.PatientCode
              ? d.patient.PatientCode
              : "";
            const patientSurname = d.patient.surname ? d.patient.surname : "";
            const patientName = d.patient.name ? d.patient.name : "";
            let stringToSearch = `${patientCode}${patientName}${patientSurname}`;
            const textSearched = this.lastPatient.replace(/\s/g, "");
            stringToSearch = stringToSearch.replace(/\s/g, "");
            isPatientEqualToSearched = stringToSearch
              .toUpperCase()
              .match(textSearched.toUpperCase());
          } else if (isPatientAnonymus && this.lastPatient.length > 0)
            isPatientEqualToSearched = false;
          else isPatientEqualToSearched = true;

          return isSerialNumberEqualToSearched && isPatientEqualToSearched;
        };

        this.activeDevices_filtered = this.activeDevices.filter((d) =>
          areDataEqualsToSearchedText(d)
        );

        this.associatedDevices_filtered = this.associatedDevices.filter((d) =>
          areDataEqualsToSearchedText(d)
        );

        this.devicesWithSomeContact_filtered = this.devicesWithSomeContact.filter(
          (d) => areDataEqualsToSearchedText(d)
        );
        this.inactiveDevices_filtered = this.inactiveDevices.filter((d) =>
          areDataEqualsToSearchedText(d)
        );
      } catch (e) {
        console.log(`Invalid input: ${e}`);
        this.devices = [];
      }
    },
    openLogs(deviceId) {
      this.showLogs = true;
      this.deviceIdToShowLogs = deviceId;
    },
    closeLogs() {
      this.showLogs = false;
    },
  },
  async created() {
    try {
      this.loading = true;
      this.allDevices = await api.getDevices();

      this.activeDevices_filtered = cloneDeep(this.activeDevices);
      this.associatedDevices_filtered = cloneDeep(this.associatedDevices);
      this.devicesWithSomeContact_filtered = cloneDeep(
        this.devicesWithSomeContact
      );
      this.inactiveDevices_filtered = cloneDeep(this.inactiveDevices);
    } catch (e) {
      this.error.message = this.parseError(e);
      this.error.forceLogout = true;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    // sono associati a un paziente e spediscono
    activeDevices() {
      return this.allDevices.filter(
        (d) =>
          d.patient && d.patient.id && d.lastContact && d.lastContact.timestamp
      );
    },
    // sono associati a un paziente ma non hanno mai spedito
    associatedDevices() {
      return this.allDevices.filter(
        (d) => d.patient && d.patient.id && !d.lastContact
      );
    },
    // hanno spedito ma non sono associati a un paziente (e.g. è stato fatto un connection test)
    devicesWithSomeContact() {
      return this.allDevices.filter(
        (d) =>
          (!d.patient || !d.patient.id) &&
          d.lastContact &&
          d.lastContact.timestamp
      );
    },
    // tutti gli altri...
    inactiveDevices() {
      return this.allDevices.filter((d) => {
        return (
          !this.activeDevices.includes(d) &&
          !this.associatedDevices.includes(d) &&
          !this.devicesWithSomeContact.includes(d)
        );
      });
    },
    noContent() {
      return this.allDevices.length <= 0;
    },
  },
};
</script>

<style lang="scss" scoped>
th.is-borderless {
  border: 0 !important;
}
th.right-border {
  border: 0 !important;
  border-right: 1px solid #dbdbdb !important;
}
</style>